import iziToast from 'izitoast'
import 'izitoast/dist/css/iziToast.min.css'

const defaults = {
	position: 'topRight',
	animateInside: false,
	transitionOut: 'fadeOut',
	transitionInMobile: 'fadeIn',
	transitionOutMobile: 'fadeOut',
	closeOnClick: true,
}

export const toast = ({ type = 'default', title, message, icon, timeout = 1200 }) => {
	if (!title || !message) return
	switch (type) {
		case 'success':
			iziToast.success({
				...defaults,
				type,
				title,
				message,
				icon,
				timeout,
			})
			break
		case 'info':
			iziToast.info({
				...defaults,
				type,
				title,
				message,
				icon,
				timeout,
			})
			break
		case 'error':
			iziToast.error({
				...defaults,
				type,
				title,
				message,
				icon,
				timeout,
			})
			break
		case 'warning':
			iziToast.warning({
				...defaults,
				type,
				title,
				message,
				icon,
				timeout,
			})
			break
		default:
			iziToast.show({
				...defaults,
				type,
				title,
				message,
				icon,
				timeout,
			})
	}
}
