import { mapState } from 'pinia'

import { useSessionStore } from '@stores'

export default {
	computed: {
		...mapState(useSessionStore, {
			sessionCurrencyCode: 'currencyCode',
			sessionWarehouseId: 'warehouseId',
			sessionBillingWarehouseId: 'billingWarehouseId',
			sessionLanguageCode: 'languageCode',
			sessionLanguageId: 'languageId',
		}),
	},
}
