<template>
	<div class="site-wrapper h-full min-h-screen flex flex-col justify-between">
		<slot name="logout-form" />
		<header class="lg:sticky w-full top-0 z-10 h-11 lg:h-20 bg-white border-b-swatch border-zinc-400">
			<div class="mx-auto grid w-full max-w-screen-lg grid-cols-nav h-full">
				<div class="!ps-4 self-center flex justify-start gap-4 items-center">
					<button type="button" @click="toggleMobileMenu" class="lg:hidden" tabindex="0" aria-label="Menu Toggle">
						<svg-vue v-show="!mobileMenuActive" icon="hamburger" class="stroke-black w-5" />
						<svg-vue v-show="mobileMenuActive" icon="close" class="stroke-black w-5" />
					</button>
					<meili-product-search btn-class="lg:hidden" />
					<template class="hidden lg:block">
						<slot name="site-logo" />
					</template>
				</div>
				<div class="self-center justify-self-center">
					<slot name="home-link" />
					<template class="hidden lg:block">
						<nav class="flex w-full justify-center items-center gap-5" v-click-outside="onClickOutside">
							<div>
								<button type="button" @click="toggleShopMenu" class="flex items-center gap-0" aria-label="Shop">
									<span class="uppercase text-xs/[12px] tracking-wider font-medium">Shop</span>
									<svg-vue icon="chevron-down" :class="['stroke-black w-5', shopMenuOpen && 'rotate-180']" />
								</button>
								<transition
									enter-active-class="transition transition-all duration-100 ease-out"
									enter-from-class="transform transition-all opacity-0"
									enter-to-class="transform transition-all scale-y-100 opacity-100"
									leave-active-class="transition transition-all duration-75 ease-out"
									leave-from-class="transform transition-all scale-y-100 opacity-100"
									leave-to-class="transform transition-all scale-y-0 opacity-0"
								>
									<slot v-if="shopMenuOpen" name="menu-links" />
								</transition>
							</div>
							<slot name="menu-other-links" />
						</nav>
					</template>
				</div>
				<div class="self-center flex items-center gap-4 !pe-4 justify-end">
					<button
						v-if="!searchOpen && !isEnrollContext"
						type="button"
						@click="openProfilePanel"
						class=""
						aria-label="Account"
					>
						<svg-vue icon="account" class="stroke-black w-5" />
					</button>
					<meili-product-search btn-class="hidden lg:block" />
					<template v-if="!hideCart">
						<button
							v-if="!searchOpen && !isEnrollContext"
							type="button"
							@click="openBagPanel"
							class="relative"
							aria-label="Shopping Bag"
						>
							<svg-vue icon="bag" class="stroke-black w-6" />
							<span
								v-if="quantityTotal > 0"
								class="absolute mt-0.5 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-xs/[8px]"
							>
								{{ quantityTotal }}
							</span>
						</button>
					</template>
				</div>
			</div>
		</header>
		<transition
			enter-active-class="transition transition-all duration-100 ease-out"
			enter-from-class="transform transition-all opacity-0"
			enter-to-class="transform transition-all scale-y-100 opacity-100"
			leave-active-class="transition transition-all duration-75 ease-out"
			leave-from-class="transform transition-all scale-y-100 opacity-100"
			leave-to-class="transform transition-all scale-y-0 opacity-0"
		>
			<nav
				v-if="mobileMenuActive"
				class="lg:hidden flex flex-col fixed z-50 top-11 left-0 bottom-0 right-0 w-screen h-screen bg-white"
			>
				<div class="border-b-swatch !p-3 border-zinc-400">
					<span class="tracking-wider font-medium">Shop</span>
				</div>
				<slot name="menu-links" />
				<slot name="menu-other-links" />
			</nav>
		</transition>
		<div v-if="profilePanelOpen" @click="closeProfilePanel" class="fixed inset-0 z-20 bg-black/30" />
		<div
			:class="[
				'opacity-0 fixed top-0 right-0 bottom-0 overflow-hidden w-full max-w-xs bg-white z-30 translate-x-full transition-all duration-100 ease-linear',
				'!pt-14 !px-6 !pb-6 flex flex-col gap-6',
				profilePanelOpen && '!translate-x-0 !opacity-100',
			]"
		>
			<slot name="profile-contents" />
		</div>
		<template v-if="!hideCart">
			<div v-if="bagPanelOpen" @click="closeBagPanel" class="fixed inset-0 z-20 bg-black/30" />
			<div
				:class="[
					'opacity-0 fixed top-0 right-0 bottom-0 overflow-hidden w-[87vw] max-w-[400px] bg-white z-30 translate-x-full transition-all duration-100 ease-linear',
					bagPanelOpen && '!translate-x-0 !opacity-100',
				]"
			>
				<slot name="cart-list" />
			</div>
		</template>
		<slot name="main" />
		<slot name="cookie-banner" />
		<slot name="footer" />
	</div>
</template>
<script>
import { mapState, mapActions } from 'pinia'

import { useAppStore, useCartStore, useSessionStore, useRewardsStore } from '@stores'

export default {
	props: {
		hideCart: {
			type: Boolean,
			default: false,
		},
	},
	created() {
		this.commitRewards()
		this.commitCart()
		this.commitSession()
	},
	computed: {
		...mapState(useAppStore, ['shopMenuOpen', 'mobileMenuActive', 'profilePanelOpen', 'bagPanelOpen', 'searchOpen']),
		...mapState(useCartStore, ['rewardModalOpen', 'quantityTotal']),
		...mapState(useSessionStore, ['shoppingContext', 'isEnrollContext']),
	},
	methods: {
		...mapActions(useAppStore, [
			'toggleShopMenu',
			'toggleMobileMenu',
			'openProfilePanel',
			'closeProfilePanel',
			'openBagPanel',
			'closeBagPanel',
		]),
		...mapActions(useRewardsStore, ['setRewards']),
		...mapActions(useCartStore, [
			'setCartItems',
			'setSubtotal',
			'setSubtotalBeforeOrderDiscounts',
			'setOrderDiscountAmount',
		]),
		...mapActions(useSessionStore, [
			'setLoggedIn',
			'setCurrencyCode',
			'setCountryCode',
			'setWarehouseId',
			'setBillingWarehouseId',
			'setLanguageCode',
			'setLanguageId',
			'setPriceTypeId',
			'setShoppingContext',
			'setIsEnrollContext',
			'setIsRetailContext',
			'setCheckoutUrl',
			'setIsWholesaleCustomer',
			'setWebAlias',
			'setWebAliasArtistName',
			'setWebAliasArtistId',
			'setTrackingWebAlias',
			'setTrackingArtistName',
			'setTrackingWebUrl',
			'setClearTrackingWebUrl',
			'setItemSearchKey',
			'setModalsFired',
		]),
		commitRewards() {
			const ui_data = window.ui_data()

			if (!ui_data.store.modules.rewards) {
				return
			}
			const rewards = ui_data.store.modules.rewards
			this.setRewards(rewards)
		},
		commitCart() {
			const ui_data = window.ui_data()
			if (!ui_data.store.modules.cart) {
				return
			}
			const cart = ui_data.store.modules.cart

			const items = cart.items || []
			const subtotal = cart.subtotal || 0
			const orderDiscountAmount = cart.rewards.orderDiscountAmount || 0

			this.setCartItems(items)
			this.setSubtotal(subtotal)
			this.setOrderDiscountAmount(orderDiscountAmount)
		},
		commitSession() {
			const ui_data = window.ui_data()

			if (!ui_data.store.modules.session) {
				return
			}

			const session = ui_data.store.modules.session
			this.setLoggedIn(session.logged_in || false)
			this.setCurrencyCode(session.currency_code || null)
			this.setCountryCode(session.country_code || null)
			this.setWarehouseId(session.warehouse_id || null)
			this.setBillingWarehouseId(session.billing_warehouse_id || null)
			this.setLanguageCode(session.language_code || null)
			this.setLanguageId(session.language_id || null)
			this.setPriceTypeId(session.price_type_id || null)
			this.setShoppingContext(session.shopping_context || null)
			this.setIsEnrollContext(!!session.is_enroll_context || false)
			this.setIsRetailContext(!!session.is_retail_context || false)
			this.setCheckoutUrl(session.checkout_url || [])
			this.setIsWholesaleCustomer(!!session.is_wholesale_customer || false)
			this.setWebAlias(session.web_alias || null)
			this.setWebAliasArtistName(session.web_alias_artist_name || null)
			this.setWebAliasArtistId(session.web_alias_artist_id || null)
			this.setTrackingWebAlias(session.tracking_web_alias || null)
			this.setTrackingArtistName(session.tracking_artist_name || null)
			this.setTrackingWebUrl(session.tracking_web_url || null)
			this.setClearTrackingWebUrl(session.clear_tracking_web_url || null)
			this.setItemSearchKey(session.item_search_index || null)
			const storagedIds = JSON.parse(localStorage.getItem('modal_ids'))
			this.setModalsFired(storagedIds ?? [])
		},
		onClickOutside() {
			if (this.shopMenuOpen) {
				this.toggleShopMenu()
			}
		},
	},
}
</script>
