/* global axios */
const getClient = () => {
	const options = {
		baseUrl: '/',
	}

	const client = axios.create(options)

	return client
}

export default {
	get(url, conf = {}) {
		return getClient()
			.get(url, conf)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error))
	},

	delete(url, conf = {}) {
		return getClient()
			.delete(url, conf)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error))
	},

	head(url, conf = {}) {
		return getClient()
			.head(url, conf)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error))
	},

	options(url, conf = {}) {
		return getClient()
			.options(url, conf)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error))
	},

	post(url, data = {}, conf = {}) {
		return getClient()
			.post(url, data, conf)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error))
	},

	put(url, data = {}, conf = {}) {
		return getClient()
			.put(url, data, conf)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error))
	},

	patch(url, data = {}, conf = {}) {
		return getClient()
			.patch(url, data, conf)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error))
	},
}
