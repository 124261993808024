import { defineStore } from 'pinia'

import { isObject } from '@components/utils/helpers_v2'

export const useProductStore = defineStore('product', {
	state: () => ({
		variantItems: [],
		selectedVariantId: null,
	}),

	getters: {
		hasVariantCategories: (state) => isObject(state.variantItems),
		variantOptions(state) {
			if (this.hasVariantCategories) {
				return Object.values(state.variantItems)
			}
			return state.variantItems
		},
		variantCategories(state) {
			if (this.hasVariantCategories) {
				return Object.keys(state.variantItems)
			}
			return []
		},
		variantImages() {
			return this.variantOptions
				.flat()
				.map((item) => item.images)
				.flat()
		},
		selectedVariant(state) {
			return this.variantOptions.flat().find((item) => item.id === state.selectedVariantId)
		},
		variantLabel() {
			return this.variantOptions.flat()
		},
	},

	actions: {
		setVariants(variants) {
			this.variantItems = variants
		},
		setSelectedVariantId(id, router, path, params) {
			this.selectedVariantId = id
			if (router && path && params) {
				// edit route to reflex selected variant params
				const { itemId, itemSlug } = params
				let updatedPath = path.replace(itemId, id)
				if (itemSlug) {
					const { slug } = this.selectedVariant
					updatedPath = updatedPath.replace(itemSlug, slug)
				}
				router.replace({ path: updatedPath })
			}
		},
	},
})
