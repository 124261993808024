import { defineStore } from 'pinia'

// TODO: populate and control rest of modals via here
export const useModalStore = defineStore('modals', {
	state: () => ({
		firedModalIds: JSON.parse(localStorage.getItem('modal_ids')) ?? [],
		modals: {},
	}),

	getters: {
		isModalOpen: (state) => (id) => state.modals[id] ?? false,
		hasModalFired: (state) => (id) => state.firedModalIds.includes(id),
	},

	actions: {
		setModal(id, autoShow = false) {
			!(id in this.modals) && (this.modals[id] = autoShow)
		},
		openModal(id) {
			this.modals[id] = true
		},
		closeModal(id) {
			this.modals[id] = false
		},
		toggleModal(id, bool) {
			this.modals[id] = bool
		},
		addToModalsFired(id) {
			if (this.firedModalIds.includes(id)) return
			const storagedIds = JSON.parse(localStorage.getItem('modal_ids'))
			const currentModalIds = [...new Set(storagedIds ?? [])]
			currentModalIds.push(id)
			localStorage.setItem('modal_ids', JSON.stringify(currentModalIds))
			this.firedModalIds = currentModalIds
		},
	},
})
