import '@/js/plugins/datatables.min.js'

var $downlineTable
var dlFilters = { status: '', period: '', level: '' }
var isPaginated
var dlOffset
var dlLevel
var dlInc

function initDataTable() {
	$('[data-data-table]').each(function () {
		var $this = $(this)
		var $table = $this

		if (!$this.is('table')) {
			$table = $('table', $this)
		}

		var $dataTable
		var options = {
			dom: '<"html5buttons text-right"B>lTfgitp',
			buttons: ['csv'],
			order: [],
		}

		var rows = $(this).data('rows')

		if (rows) {
			var data = []

			_.each(rows, function (row) {
				data.push(_.values(row))
			})

			options.data = data
			var columns = []
			var keys = []

			keys = _.keys(rows[0])

			_.each(keys, function (columnTitle) {
				columns.push({
					title: columnTitle,
				})
			})

			options.columns = columns
			options.deferRender = true
		}

		options.lengthMenu = [
			[10, 25, 50, 100, -1],
			[10, 25, 50, 100, 'All'],
		]

		if (document.getElementById('downline-list')) {
			$downlineTable = $table.DataTable(options)
			dlOffset = document.getElementById('init-offset').value
			dlLevel = document.getElementById('level-inc').value
			dlInc = document.getElementById('level-inc').value
			isPaginated = document.getElementById('is-paginated').value
			// initialize the event listeners
			var addDl = document.getElementById('add-downline')
			var resetFl = document.getElementById('reset-filters')
			var statusFl = document.getElementById('status-filter')
			var levelFl = document.getElementById('level-filter')
			var periodFl = document.getElementById('period-filter')

			if (isPaginated) {
				addDl.addEventListener('click', addDownline)
			} else {
				document.getElementById('add-downline').disabled = true
			}

			resetFl.addEventListener('click', resetDownline)
			statusFl.addEventListener('change', function () {
				filterDownline('status', this.value)
			})
			levelFl.addEventListener('change', function () {
				filterDownline('level', this.value)
			})
			periodFl.addEventListener('change', function () {
				filterDownline('period', this.value)
			})
		} else {
			$dataTable = $table.DataTable(options)
		}

		// Column filter
		$('tfoot th', $table).each(function (i) {
			$(this).html('<input type="text" placeholder="Filter" data-index="' + i + '" />')
		})

		// Filter event handler
		if (document.getElementById('downline-list')) {
			$($downlineTable.table().container()).on('keyup', 'tfoot input', function () {
				$downlineTable.column($(this).data('index')).search(this.value).draw()
			})
		} else {
			$($dataTable.table().container()).on('keyup', 'tfoot input', function () {
				$dataTable.column($(this).data('index')).search(this.value).draw()
			})
		}
	})
}

function initSSDataTable() {
	if (document.getElementById('tableDiv')) {
		$.ajax({
			url: window.location.pathname + '?cmd=dataInit',
			dataType: 'json',
			success: function (json) {
				var tableHeaders = ''
				// get the columns
				_.each(json.columns, function (val) {
					tableHeaders += '<th>' + val + '</th>'
				})

				document.getElementById('tableDiv').innerHTML = ''
				document.getElementById('tableDiv').innerHTML =
					'<table id="displayTable" class="table table-striped table-bordered table-hover"><thead><tr>' +
					tableHeaders +
					'</tr></thead></table>'

				var ssTable = $('#displayTable').DataTable({
					lengthMenu: [
						[10, 25, 50, 100, 250],
						[10, 25, 50, 100, 250],
					],
					processing: true,
					serverSide: true,
					data: json.aaData,
					order: [[0, 'desc']],
					dom: '<"html5buttons text-right"B>lTfgitpr',
					buttons: ['csv'],
					deferLoading: json.iTotalRecords,
					ajax: window.location.pathname,
				})
				var debounce = new $.fn.dataTable.Debounce(ssTable)
			},
			error: function (xhr, status, error) {
				var errorMessage = xhr.status + ': ' + xhr.statusText
				alert('Error: your data could not be loaded.')
			},
		})
	}
}

function addDownline() {
	document.getElementById('cover-spin').style.display = 'block'
	$.ajax({
		url: window.location.pathname + '?lev=' + dlLevel + '&off=' + dlOffset + '&per=' + dlFilters['period'],
		dataType: 'json',
		success: function (json) {
			var nextOffset = json.splice(-1, 1)
			updateParams(nextOffset[0])

			if (Object.keys(json).length > 0) {
				updateLevelsDD()

				for (var key of Object.keys(json)) {
					json[key] = _.values(json[key])
				}
				$downlineTable.rows.add(json).draw(false)
			}
			document.getElementById('cover-spin').style.display = 'none'
		},
		error: function (xhr, status, error) {
			var errorMessage = xhr.status + ': ' + xhr.statusText
			alert('Error: your data could not be loaded.')
			document.getElementById('cover-spin').style.display = 'none'
		},
	})
}

function resetDownline() {
	clearDownline('all')
	setPaginated()
	addDownline()
}

function setPaginated() {
	if (!isPaginated) {
		document.getElementById('add-downline').disabled = true
	}
}

function filterDownline(mode, filter) {
	dlFilters[mode] = filter

	if (mode === 'status') {
		$downlineTable.columns(1).search(filter).draw()
	} else if (mode === 'period') {
		clearDownline('period')
		setPaginated()
		addDownline()
	} else if (mode === 'level') {
		if (filter < 10) {
			$downlineTable
				.columns(5)
				.search('^[0-' + filter + ']$', true, false)
				.draw()
		} else {
			var regEx = String(filter)
			$downlineTable
				.columns(5)
				.search('^([0-9]|1[0-' + regEx.substring(regEx.length - 1, regEx.length) + '])$', true, false)
				.draw()
		}
	}
}

function clearDownline(mode) {
	$downlineTable.clear().draw()

	if (mode === 'all') {
		document.getElementById('period-filter').value = ''
		document.getElementById('status-filter').value = ''
		dlFilters = { status: '', period: '', level: '' }

		$downlineTable.search('').draw()
		$downlineTable.page.len(10).draw()
		$downlineTable.order([5, 'asc']).draw()

		for (i = 0; i < $downlineTable.init().columns.length; i++) {
			$downlineTable.columns(i).search('').draw()
		}
		$('#downline-list :input[type=text]').val('')
	} else {
		dlFilters['status'] = ''
		dlFilters['level'] = ''
	}

	dlLevel = 0
	dlOffset = 0
	document.getElementById('add-downline').disabled = false
}

function updateParams(offset) {
	var nextOffset = offset['nextOffset']

	if (nextOffset > 0) {
		dlOffset = Number(nextOffset) + Number(dlOffset)
		dlLevel = Number(dlLevel) + Number(dlInc)
	} else {
		document.getElementById('add-downline').disabled = true
	}
}

function updateLevelsDD() {
	var levSelect = document.getElementById('level-filter')

	if (dlOffset > 0 && dlLevel >= levSelect.length) {
		for (let i = levSelect.length; i <= dlLevel; i++) {
			var option = document.createElement('option')
			option.text = option.value = i
			levSelect.add(option, levSelect[i])
		}
	}

	levSelect.value = dlLevel
	filterDownline('level', dlLevel)
}

// delay the server call for a search query
$.fn.dataTable.Debounce = function (table, options) {
	var tableId = table.settings()[0].sTableId
	$('.dataTables_filter input[aria-controls="' + tableId + '"]') // select the correct input field
		.unbind() // Unbind previous default bindings
		.bind(
			'input',
			delay(function (e) {
				// Bind our desired behavior
				table.search($(this).val()).draw()
				return
			}, 1500)
		) // Set delay in milliseconds
}

function delay(callback, ms) {
	var timer = 0
	return function () {
		var context = this,
			args = arguments
		clearTimeout(timer)
		timer = setTimeout(function () {
			callback.apply(context, args)
		}, ms || 0)
	}
}

setTimeout(function () {
	initDataTable()
	initSSDataTable()
}, 100)

// SCROLL HORIZONTAL LIST TO ACTIVE ITEM ON SCROLL
const scrollEl = document.querySelector('.in-page-nav') || null

const activeLink = document.querySelector('.in-page-nav--link.active') || null

if (scrollEl && activeLink) {
	scrollEl.scrollTo({
		left: activeLink.getBoundingClientRect().left,
		behavior: 'smooth',
	})
}

// Modify product names - Strip text after dash
const productNames = [
	'tank',
	'bra',
	'tee',
	'crewneck',
	'shirt',
	'hoodie',
	'jacket',
	'shorts',
	'pants',
	'holiday-lip-cheek',
	'collection-no',
]

productNames.map((name) => {
	const productEls =
		document.querySelectorAll(
			`.horizontal-scroll-list [class*="${name}-"] .title, .products-container [class*="${name}-"] .product--title`
		) || null
	if (productEls) {
		for (const el of productEls) {
			el.innerHTML = el.innerHTML.replace(/\s-\s*(.*)/gm, '')
		}
	}
})
