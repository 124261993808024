import CurrencyFormatter from '../lib/CurrencyFormatter'

export default {
	methods: {
		/**
		 * Convert raw price into a local currency
		 *
		 * @param price
		 * @param currencyCode the currency code (i.e. usd,cad)
		 * @returns {String}
		 */
		currencyFormat(price, currencyCode) {
			return new CurrencyFormatter(price, currencyCode).toString()
		},
	},
}
