import client from '@/js/api/client.js'
import { useSessionStore } from '@stores'

export default {
	async calculate({
		discountCode,
		shipMethodId,
		shippingAddressId,
		country,
		warehouse,
		currencyCode,
		priceType,
		donationAmount,
	}) {
		try {
			const sessionStore = useSessionStore()
			const { data } = await client.post(sessionStore.checkoutShopRoute('order.calculations.store'), {
				discountCode,
				shipMethodId,
				shippingAddressId,
				country,
				warehouse,
				currencyCode,
				priceType,
				donationAmount,
			})
			return data
		} catch (error) {
			throw error
		}
	},
}
