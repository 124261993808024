import client from '@/js/api/client.js'
import { useSessionStore } from '@stores'

export default {
	addItem(itemId, quantity = 1, isKit = 0, discountType = null) {
		const payload = {
			quantity,
			is_kit: isKit,
			item_id: itemId,
		}
		if (discountType) {
			payload.discount_type = discountType
		}
		const sessionStore = useSessionStore()
		return client
			.post(sessionStore.shopRoute('cart.items.store'), payload)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error))
	},

	updateItem(lineItemId, quantity) {
		const sessionStore = useSessionStore()
		return client
			.patch(sessionStore.shopRoute('cart.items.update', { lineItemId }), { quantity })
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error))
	},

	removeItem(lineItemId) {
		const sessionStore = useSessionStore()
		return client
			.delete(sessionStore.shopRoute('cart.items.destroy', { lineItemId }))
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error))
	},
}
