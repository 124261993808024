import { defineStore } from 'pinia'

export const useMarketStore = defineStore('market', {
	state: () => ({
		name: null,
		currency_code: null,
		warehouse_id: null,
		billing_warehouse_id: null,
		telephone: null,
	}),

	getters: {
		name: (state) => state.name,
		currencyCode: (state) => state.currency_code,
		warehouseId: (state) => state.warehouse_id,
		billingWarehouseId: (state) => state.billing_warehouse_id,
		telephone: (state) => state.telephone,
	},

	actions: {
		setName(name) {
			this.name = name
		},
		setCurrencyCode(code) {
			this.currency_code = code
		},
		setWarehouseId(id) {
			this.warehouse_id = id
		},
		setBillingWarehouseId(id) {
			this.billing_warehouse_id = id
		},
		setTelephone(phoneNumber) {
			this.telephone = phoneNumber
		},
	},
})
