import { defineStore } from 'pinia'

export const useAppStore = defineStore('app', {
	state: () => ({
		isScrollLocked: false,
		profilePanelOpen: false,
		shopMenuOpen: false,
		bagPanelOpen: false,
		searchOpen: false,
		// below is old
		isLargeMediaQuery: false,
		searchActive: false,
		pageScrollLock: false,
		mobileMenuActive: false,
		profilePanelActive: false,
		bagPanelActive: false,
		shopMenuItemActive: true,
		accountHeaderItemActive: false,
	}),

	getters: {},

	actions: {
		setLargeMediaQuery() {
			if (this.mobileMenuActive) {
				this.toggleMobileMenu()
			}
			this.isLargeMediaQuery = true
		},
		unsetLargeMediaQuery() {
			this.isLargeMediaQuery = false
		},
		toggleScrollLock() {
			if (this.isScrollLocked) {
				this.unlockPageScroll()
			} else {
				this.lockPageScroll()
			}
		},
		showInnerCategoryNav() {
			const innerNavId = 'inner-category-nav'
			const el = document.getElementById(innerNavId)
			if (el) {
				el.style.opacity = '100'
			}
		},
		hideInnerCategoryNav() {
			const innerNavId = 'inner-category-nav'
			const el = document.getElementById(innerNavId)
			if (el) {
				el.style.opacity = '0'
			}
		},
		toggleShopMenu() {
			if (this.shopMenuOpen) {
				this.shopMenuOpen = false
				this.showInnerCategoryNav()
			} else {
				this.shopMenuOpen = true
				this.hideInnerCategoryNav()
			}
		},
		lockPageScroll() {
			document.body.style.overflow = 'hidden'
			this.isScrollLocked = true
		},
		unlockPageScroll() {
			document.body.style.overflow = 'unset'
			this.isScrollLocked = false
		},
		openProfilePanel() {
			this.lockPageScroll()
			this.profilePanelOpen = true
			this.mobileMenuActive = false
		},
		closeProfilePanel() {
			this.unlockPageScroll()
			this.profilePanelOpen = false
			this.mobileMenuActive = false
		},
		openBagPanel() {
			this.lockPageScroll()
			this.bagPanelOpen = true
			this.mobileMenuActive = false
		},
		closeBagPanel() {
			this.unlockPageScroll()
			this.bagPanelOpen = false
		},
		openSearch() {
			this.lockPageScroll()
			this.searchOpen = true
			this.hideInnerCategoryNav()
		},
		closeSearch() {
			this.unlockPageScroll()
			this.searchOpen = false
			this.showInnerCategoryNav()
		},
		// old below

		setBackDrop() {
			this.bagPanelActive = true
		},
		toggleMobileMenu() {
			if (!this.isLargeMediaQuery && !this.mobileMenuActive) {
				this.shopMenuItemActive = true
			}
			this.mobileMenuActive = !this.mobileMenuActive
			if (this.mobileMenuActive) {
				this.lockPageScroll()
			} else {
				this.unlockPageScroll()
			}
		},
		closeMobileMenu() {
			this.mobileMenuActive = false
		},
		toggleSearchActive() {
			this.pageScrollLock = !this.searchActive
			this.searchActive = !this.searchActive
		},
		toggleProfilePanelActive() {
			this.mobileMenuActive = false
			this.lockPageScroll = !this.profilePanelActive
			this.profilePanelActive = !this.profilePanelActive
		},
		toggleBagPanelActive() {
			this.mobileMenuActive = false
			this.lockPageScroll = !this.bagPanelActive
			this.bagPanelActive = !this.bagPanelActive
		},

		toggleShopMenuItem() {
			this.shopMenuItemActive = !this.shopMenuItemActive
		},
		setShopMenuItemActive() {
			this.shopMenuItemActive = true
		},
		unsetShopMenuItemActive() {
			this.shopMenuItemActive = false
		},
		toggleAccountHeaderItem() {
			if (this.shopMenuItemActive && this.isLargeMediaQuery) {
				this.shopMenuItemActive = false
			}
			this.accountHeaderItemActive = !this.accountHeaderItemActive
		},
		setAccountHeaderItemActive() {
			this.accountHeaderItemActive = true
		},
		unsetAccountHeaderItemActive() {
			this.accountHeaderItemActive = false
		},
	},
})
