import { defineStore } from 'pinia'

import { useCartStore } from './cart'
import { useRewardsStore } from './rewards.js'
import { useAppStore } from './app.js'

const canFit = ({ tins, halftins, tinsMax, nextAdd = 0 } = {}) => {
	const totalSpaceUsed = tins + halftins * 0.5
	return totalSpaceUsed + nextAdd <= tinsMax
}
const calculateItemThresholds = ({ tins, halftins, tinsMax }) => {
	const totalSpaceUsed = tins + halftins * 0.5
	const shrinkRatio = tinsMax / totalSpaceUsed
	const newTins = Math.floor(tins * shrinkRatio)
	const newEyeshadows = Math.floor(halftins * shrinkRatio)
	return {
		tins: newTins,
		halftins: newEyeshadows,
	}
}
const prepareGrid = ({ tins, halfTins, tinStack, spaces, layers = 1 } = {}) => {
	const total = [[]] // first arr is 'Top' view (remains empty)
	if (layers === 1) {
		total.push([...tinStack])
		// fill empty spaces for outline view
		const convertedTins = tins * 2 // takes 2x space
		let spaceLeft = spaces - convertedTins - halfTins
		if (spaceLeft > 0) {
			if (spaceLeft % 2 !== 0) {
				total[1].push({ isEmpty: true, isHalfTin: true })
				spaceLeft -= 1
			}
			spaceLeft /= 2 // only full tins left
			for (let i = 0; i < spaceLeft; i++) {
				total[1].push({ isEmpty: true, isHalfTin: false })
			}
		}
	} else if (layers === 2) {
		const halfTinSpacePerLayer = spaces / layers

		let limit = 0
		let sum = 0
		const len = tinStack.length
		let i = 0
		while (i < len && sum < halfTinSpacePerLayer) {
			sum += tinStack[i].isHalfTin ? 1 : 2
			limit += 1
			i += 1
		}

		total.push([...tinStack.slice(0, limit)], [...tinStack.slice(limit)])

		let layerOneTotal = total[1].reduce((sum, item) => sum + (item.isHalfTin ? 1 : 2), 0)
		let layerTwoTotal = total[2].reduce((sum, item) => sum + (item.isHalfTin ? 1 : 2), 0)

		if (layerOneTotal < halfTinSpacePerLayer) {
			if (layerOneTotal % 2 !== 0) {
				total[1].push({ isEmpty: true, isHalfTin: true })
				layerOneTotal += 1
			}
			for (let i = 0; i < halfTinSpacePerLayer - layerOneTotal; i += 2) {
				total[1].push({ isEmpty: true, isHalfTin: false })
			}
		}

		if (layerTwoTotal < halfTinSpacePerLayer) {
			if (layerTwoTotal % 2 !== 0) {
				total[2].push({ isEmpty: true, isHalfTin: true })
				layerTwoTotal += 1
			}
			for (let i = 0; i < halfTinSpacePerLayer - layerTwoTotal; i += 2) {
				total[2].push({ isEmpty: true, isHalfTin: false })
			}
		}
	}
	return total
}
const findIndexes = (arr, id) => {
	const len = arr.length
	for (let i = 0; i < len; i++) {
		const outerIndex = i
		const innerIndex = arr[i].variants.findIndex((variant) => variant.id === id)
		if (innerIndex !== -1) {
			return { outerIndex, innerIndex }
		}
	}
	return { outerIndex: 0, innerIndex: 0 }
}

export const usePaletteBuilderStore = defineStore('palette-builder', {
	state: () => ({
		loading: false,
		error: null,
		palettes: [],
		selectedPaletteId: null,
		items: {},
		activeSizeIndex: 0,
		activeDesignIndex: 0,
		currentDisplayLayer: 'Top',
		currentDisplayLayerIndex: 0,
		tins: 0,
		halfTins: 0,
		tinStack: [],
	}),

	getters: {
		activeSize: (state) => state.palettes[state.activeSizeIndex],
		activeSizeVariants: (state) => state.palettes[state.activeSizeIndex]?.variants ?? [],
		activeDesign: (state) => state.palettes[state.activeSizeIndex]?.variants[state.activeDesignIndex] ?? null,
		renderedLayers: (state) => state.palettes[state.activeSizeIndex]?.designDisplayType ?? ['Top', 'Inside'],
		designDisplayUrl() {
			return (type) => this.activeDesign?.designDisplayUrl[type] ?? null
		},
		renderedGridConstraints: (state) => {
			switch (state.palettes[state.activeSizeIndex]?.title) {
				case 'IIID Palette 4':
					return {
						width: 67.33001658374793, // percent
						maxWidth: 406,
						height: 44.78260869565218, // percent
						maxHeight: 206,
						top: 30.217391304347824, // percent
						left: 50,
						rows: 2,
						cols: 4,
						gap: 1,
						isHorizontal: false, // controls auto flow col vs row
					}
				case 'IIID Palette 8':
					return {
						width: 67.33001658374793, // percent
						maxWidth: 406,
						height: 44.78260869565218, // percent
						maxHeight: 206,
						top: 30.217391304347824, // percent
						left: 50,
						rows: 2,
						cols: 4,
						gap: 1,
						isHorizontal: false, // controls auto flow col vs row
					}
				case 'IIID Palette 12':
					return {
						width: 66.99834162520729, // percent
						maxWidth: 404,
						height: 56.25, // percent
						maxHeight: 306,
						top: 24.264705882352942, // percent
						left: 50,
						rows: 3,
						cols: 4,
						gap: 1,
						isHorizontal: true, // controls auto flow col vs row
					}
				case 'IIID Palette 18':
					return {
						width: 77.11442786069652, // percent
						maxWidth: 465,
						height: 51.76991150442478, // percent
						maxHeight: 234,
						top: 25.88495575221239, // percent
						left: 50,
						rows: 3,
						cols: 6,
						gap: 1,
						isHorizontal: true, // controls auto flow col vs row
					}
				default:
					return {
						width: 67.33001658374793, // percent
						maxWidth: 406,
						height: 44.78260869565218, // percent
						maxHeight: 206,
						top: 30.217391304347824, // percent
						left: 50,
						rows: 2,
						cols: 4,
						gap: 1,
						isHorizontal: false, // controls auto flow col vs row
					}
			}
		},
		paletteParameters: (state) => {
			let spaces = 8
			let layers = 1
			switch (state.palettes[state.activeSizeIndex]?.title) {
				case 'IIID Palette 4':
					spaces = 8
					layers = 1
					break
				case 'IIID Palette 8':
					spaces = 16
					layers = 2
					break
				case 'IIID Palette 12':
					spaces = 24
					layers = 2
					break
				case 'IIID Palette 18':
					spaces = 36
					layers = 2
					break
				default:
					spaces = 8
					layers = 1
					break
			}
			return {
				spaces,
				layers,
			}
		},
		renderedGridSpaces() {
			const { spaces, layers } = this.paletteParameters
			return prepareGrid({
				tins: this.tins,
				halfTins: this.halfTins,
				tinStack: this.tinStack,
				spaces,
				layers,
			})
		},
		currentDisplayLayerHasTins() {
			return (index) => {
				const spaces = this.renderedGridSpaces
				return !spaces[index][0]?.isEmpty ?? false
			}
		},
		maxVariantsAllowed: (state) => {
			return (nextIndex = null) => {
				const index = nextIndex ?? state.activeSizeIndex
				switch (state.palettes[index].title) {
					case 'IIID Palette 4':
						return { tins: 4, halftins: 8 }
					case 'IIID Palette 8':
						return { tins: 8, halftins: 16 }
					case 'IIID Palette 12':
						return { tins: 12, halftins: 24 }
					case 'IIID Palette 18':
						return { tins: 18, halftins: 36 }
					default:
						return { tins: 4, halftins: 8 }
				}
			}
		},
		currentCounts: (state) => {
			const counts = { tins: 0, halftins: 0, ids: { halftins: [], tins: [] } }
			for (const [id, item] of Object.entries(state.items)) {
				const { isHalfTin, quantity } = item
				if (quantity > 0) {
					if (isHalfTin) {
						counts.halftins += quantity
						counts.ids.halftins.push(id)
					} else {
						counts.tins += quantity
						counts.ids.tins.push(id)
					}
				}
			}
			return counts
		},
		isVariantDecrementDisabled: (state) => {
			return (id) => state.items[id]?.quantity === 0 ?? false
		},
		isVariantIncrementDisabled() {
			return (id) => {
				const isHalfTin = this.items[id]?.isHalfTin ?? false
				const nextAdd = isHalfTin ? 0.5 : 1.0
				const counts = this.currentCounts
				const { tins, halftins } = counts
				const maxes = this.maxVariantsAllowed()
				const { tins: tinsMax } = maxes
				return !canFit({ tins, halftins, tinsMax, nextAdd })
			}
		},
		downgradeRequiresDrop() {
			return (index) => {
				// index is the 'new' palette choice
				const counts = this.currentCounts
				const { tins, halftins } = counts
				const maxes = this.maxVariantsAllowed(index)
				const { tins: tinsMax } = maxes
				return !canFit({ tins, halftins, tinsMax })
			}
		},
		countByCategoryTitle: (state) => (categoryTitle) => {
			const category = Object.values(state.items).filter((item) => item.categoryTitle === categoryTitle)
			return category.reduce((sum, obj) => sum + obj.quantity, 0)
		},
		itemsWithQuantity: (state) => {
			const items = Object.values(state.items).filter((item) => item.quantity > 0)
			return items
		},
	},

	actions: {
		resetAll() {
			for (const id of Object.keys(this.items)) {
				this.items[id].quantity = 0
			}
			this.tins = 0
			this.halfTins = 0
			this.tinStack = []
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth',
			})
		},
		setPalettes(palettes) {
			this.palettes = palettes
		},
		setSelectedPalette(selectedPaletteId) {
			this.selectedPaletteId = selectedPaletteId
			this.handleDesignRefresh()
		},
		setItems(categories) {
			for (const category of categories) {
				const categoryTitle = category.title
				if (category.areVariantsGrouped) {
					for (const item of Object.values(category.variants).flat()) {
						this.setItem(item, categoryTitle)
					}
				} else {
					for (const item of category.variants) {
						this.setItem(item, categoryTitle)
					}
				}
			}
		},
		setItem(item, categoryTitle) {
			const { id } = item
			!(id in this.items) && (this.items[id] = { categoryTitle, ...item })
		},
		selectActiveIndex(index, requiresDrop = false) {
			if (requiresDrop) {
				const counts = this.currentCounts
				const { tins, halftins } = counts
				const maxes = this.maxVariantsAllowed(index)
				const { tins: tinsMax } = maxes
				const thresholds = calculateItemThresholds({ tins, halftins, tinsMax })
				let tinsToRemove = thresholds.tins
				if (tinsToRemove > 0) {
					for (const id of counts.ids.tins) {
						while (this.items[id].quantity > 0 && tinsToRemove > 0) {
							this.items[id].quantity -= 1
							tinsToRemove -= 1
						}
					}
				}
				let halfTinsToRemove = thresholds.halftins
				if (halfTinsToRemove > 0) {
					for (const id of counts.ids.halftins) {
						while (this.items[id].quantity > 0 && halfTinsToRemove > 0) {
							this.items[id].quantity -= 1
							halfTinsToRemove -= 1
						}
					}
				}
				this.handleStackRefresh()
			}
			this.activeSizeIndex = index
			this.activeDesignIndex = 0 // reset variants to first
			this.selectedPaletteId = this.activeDesign.id
			this.handleDesignRefresh()
			this.updateShareHash()

			// reset to correct view
			if (this.renderedLayers[this.currentDisplayLayerIndex]) {
				this.currentDisplayLayer = this.renderedLayers[this.currentDisplayLayerIndex]
			} else {
				this.currentDisplayLayer = this.renderedLayers[this.currentDisplayLayerIndex - 1]
				this.currentDisplayLayerIndex -= 1
			}
		},
		selectAcitveSizeVariantIndex(index) {
			this.activeDesignIndex = index
			this.selectedPaletteId = this.activeDesign.id
			this.handleDesignRefresh()
		},
		handleDesignRefresh() {
			const { outerIndex, innerIndex } = findIndexes(this.palettes, this.selectedPaletteId)
			this.activeSizeIndex = outerIndex
			this.activeDesignIndex = innerIndex
		},
		handleStackRefresh() {
			this.tinStack = []
			this.halfTins = 0
			this.tins = 0
			for (const item of this.itemsWithQuantity) {
				this[item.isHalfTin ? 'halfTins' : 'tins'] += item.quantity
				for (let i = 0; i < item.quantity; i++) {
					this.tinStack.push(item)
				}
			}
			this.organizeStack()
			if (this.tinStack.length > 0 && this.currentDisplayLayerIndex === 0) {
				this.currentDisplayLayer = this.renderedLayers[1]
				this.currentDisplayLayerIndex = 1
			}
		},
		organizeStack() {
			const { layers } = this.paletteParameters
			// rearrange if needed
			if (this.tinStack.length > 1 && this.halfTins > 0 && this.halfTins % 2 !== 0 && layers > 1) {
				// odd number of half tins (which creates orphan on Layer 1) - move to end of stack
				const firstHalfTinFoundIndex = this.tinStack.findIndex((tin) => tin.isHalfTin)
				if (firstHalfTinFoundIndex >= 0) {
					const item = this.tinStack.splice(firstHalfTinFoundIndex, 1)[0]
					this.tinStack.push(item)
				}
			}
		},
		incrementItemQuantity(id, toast) {
			const current = this.items[id].quantity
			const isHalfTin = this.items[id].isHalfTin
			const max = this.maxVariantsAllowed()[isHalfTin ? 'halftins' : 'tins']
			if (current + 1 > max) {
				toast({
					type: 'info',
					title: 'Palette is Full',
					message: 'Remove an item to make space for another item.',
					timeout: 5000,
				})
				return
			}
			this.items[id].quantity = Math.min(current + 1, max)
			this[isHalfTin ? 'halfTins' : 'tins'] += 1
			this.tinStack.push(this.items[id])
			this.organizeStack()
			// dynamic palette open(er)
			if (this.currentDisplayLayerIndex === 0) {
				this.currentDisplayLayer = this.renderedLayers[1]
				this.currentDisplayLayerIndex = 1
			} else if (
				this.renderedLayers.length > 2 &&
				this.currentDisplayLayerIndex === 1 &&
				this.currentDisplayLayerHasTins(2)
			) {
				this.currentDisplayLayer = this.renderedLayers[2]
				this.currentDisplayLayerIndex = 2
			}
		},
		decrementItemQuantity(id) {
			const current = this.items[id].quantity
			this.items[id].quantity = Math.max(current - 1, 0)
			const isHalfTin = this.items[id].isHalfTin
			this[isHalfTin ? 'halfTins' : 'tins'] -= 1
			const index = this.tinStack.findIndex((item) => item.id === id)
			if (index !== -1) {
				this.tinStack.splice(index, 1)
			}
			this.organizeStack()
			// dynamic palette close(r)
			if (this.currentDisplayLayerIndex === 1 && !this.currentDisplayLayerHasTins(1)) {
				this.currentDisplayLayer = this.renderedLayers[0]
				this.currentDisplayLayerIndex = 0
			} else if (this.currentDisplayLayerIndex === 2 && !this.currentDisplayLayerHasTins(2)) {
				this.currentDisplayLayer = this.renderedLayers[1]
				this.currentDisplayLayerIndex = 1
			}
		},
		generateNewUrlWithHashPath(hash) {
			const href = new URL(window.location.href)
			const url = href.toString()
			const pathTarget = 'palette-builder'
			const startIndex = url.indexOf(pathTarget)
			const endIndex = startIndex + pathTarget.length
			return `${url.substring(0, endIndex)}/${hash}`
		},
		updateShareHash() {
			try {
				const json = JSON.stringify({
					compactItemId: this.activeDesign.id,
					itemIds: this.itemsWithQuantity.map(({ id, quantity }) => ({ id, quantity })),
					version: 3.0,
				})
				const hash = btoa(json).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')
				const url = this.generateNewUrlWithHashPath(hash)
				window.history.pushState(null, '', url)
			} catch (err) {
				this.error = err
			}
		},
		async addAllToBag() {
			// init other stores
			const cartStore = useCartStore()
			const appStore = useAppStore()
			const rewardsStore = useRewardsStore()
			try {
				this.loading = true
				appStore.lockPageScroll()
				// prepare payloads
				const promises = []
				const items = this.itemsWithQuantity
				const [firstItem, ...restOfItems] = items
				// add first item
				const firstPayload = {
					itemId: firstItem.id,
					quantity: firstItem.quantity,
				}
				await cartStore.addToCart(firstPayload)
				// add remaing items
				for (const item of restOfItems) {
					const payload = {
						itemId: item.id,
						quantity: item.quantity,
					}

					const promise = cartStore.addToCart(payload)
					promises.push(promise)
				}

				await Promise.all(promises)
				// now add palette
				const palette = this.activeDesign
				const payload = {
					itemId: palette.id,
					quantity: 1,
				}
				const { data } = await cartStore.addToCart(payload)
				// hydrate and cleanup
				rewardsStore.addRewardsFromCart(data)
				appStore.openBagPanel()
				this.resetAll()
			} catch (err) {
				console.log('err: ', err)
				this.error = err // TODO: currently not shown?
			} finally {
				this.loading = false
				appStore.unlockPageScroll()
			}
		},
	},
})
