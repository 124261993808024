/*! jQuery v3.2.1 | (c) JS Foundation and other contributors | jquery.org/license */
!(function (a, b) {
	'use strict'
	'object' == typeof module && 'object' == typeof module.exports
		? (module.exports = a.document
				? b(a, !0)
				: function (a) {
						if (!a.document) throw new Error('jQuery requires a window with a document')
						return b(a)
					})
		: b(a)
})('undefined' != typeof window ? window : this, function (a, b) {
	'use strict'
	var c = [],
		d = a.document,
		e = Object.getPrototypeOf,
		f = c.slice,
		g = c.concat,
		h = c.push,
		i = c.indexOf,
		j = {},
		k = j.toString,
		l = j.hasOwnProperty,
		m = l.toString,
		n = m.call(Object),
		o = {}
	function p(a, b) {
		b = b || d
		var c = b.createElement('script')
		;(c.text = a), b.head.appendChild(c).parentNode.removeChild(c)
	}
	var q = '3.2.1',
		r = function (a, b) {
			return new r.fn.init(a, b)
		},
		s = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g,
		t = /^-ms-/,
		u = /-([a-z])/g,
		v = function (a, b) {
			return b.toUpperCase()
		}
	;(r.fn = r.prototype =
		{
			jquery: q,
			constructor: r,
			length: 0,
			toArray: function () {
				return f.call(this)
			},
			get: function (a) {
				return null == a ? f.call(this) : a < 0 ? this[a + this.length] : this[a]
			},
			pushStack: function (a) {
				var b = r.merge(this.constructor(), a)
				return (b.prevObject = this), b
			},
			each: function (a) {
				return r.each(this, a)
			},
			map: function (a) {
				return this.pushStack(
					r.map(this, function (b, c) {
						return a.call(b, c, b)
					})
				)
			},
			slice: function () {
				return this.pushStack(f.apply(this, arguments))
			},
			first: function () {
				return this.eq(0)
			},
			last: function () {
				return this.eq(-1)
			},
			eq: function (a) {
				var b = this.length,
					c = +a + (a < 0 ? b : 0)
				return this.pushStack(c >= 0 && c < b ? [this[c]] : [])
			},
			end: function () {
				return this.prevObject || this.constructor()
			},
			push: h,
			sort: c.sort,
			splice: c.splice,
		}),
		(r.extend = r.fn.extend =
			function () {
				var a,
					b,
					c,
					d,
					e,
					f,
					g = arguments[0] || {},
					h = 1,
					i = arguments.length,
					j = !1
				for (
					'boolean' == typeof g && ((j = g), (g = arguments[h] || {}), h++),
						'object' == typeof g || r.isFunction(g) || (g = {}),
						h === i && ((g = this), h--);
					h < i;
					h++
				)
					if (null != (a = arguments[h]))
						for (b in a)
							(c = g[b]),
								(d = a[b]),
								g !== d &&
									(j && d && (r.isPlainObject(d) || (e = Array.isArray(d)))
										? (e ? ((e = !1), (f = c && Array.isArray(c) ? c : [])) : (f = c && r.isPlainObject(c) ? c : {}),
											(g[b] = r.extend(j, f, d)))
										: void 0 !== d && (g[b] = d))
				return g
			}),
		r.extend({
			expando: 'jQuery' + (q + Math.random()).replace(/\D/g, ''),
			isReady: !0,
			error: function (a) {
				throw new Error(a)
			},
			noop: function () {},
			isFunction: function (a) {
				return 'function' === r.type(a)
			},
			isWindow: function (a) {
				return null != a && a === a.window
			},
			isNumeric: function (a) {
				var b = r.type(a)
				return ('number' === b || 'string' === b) && !isNaN(a - parseFloat(a))
			},
			isPlainObject: function (a) {
				var b, c
				return (
					!(!a || '[object Object]' !== k.call(a)) &&
					(!(b = e(a)) || ((c = l.call(b, 'constructor') && b.constructor), 'function' == typeof c && m.call(c) === n))
				)
			},
			isEmptyObject: function (a) {
				var b
				for (b in a) return !1
				return !0
			},
			type: function (a) {
				return null == a ? a + '' : 'object' == typeof a || 'function' == typeof a ? j[k.call(a)] || 'object' : typeof a
			},
			globalEval: function (a) {
				p(a)
			},
			camelCase: function (a) {
				return a.replace(t, 'ms-').replace(u, v)
			},
			each: function (a, b) {
				var c,
					d = 0
				if (w(a)) {
					for (c = a.length; d < c; d++) if (b.call(a[d], d, a[d]) === !1) break
				} else for (d in a) if (b.call(a[d], d, a[d]) === !1) break
				return a
			},
			trim: function (a) {
				return null == a ? '' : (a + '').replace(s, '')
			},
			makeArray: function (a, b) {
				var c = b || []
				return null != a && (w(Object(a)) ? r.merge(c, 'string' == typeof a ? [a] : a) : h.call(c, a)), c
			},
			inArray: function (a, b, c) {
				return null == b ? -1 : i.call(b, a, c)
			},
			merge: function (a, b) {
				for (var c = +b.length, d = 0, e = a.length; d < c; d++) a[e++] = b[d]
				return (a.length = e), a
			},
			grep: function (a, b, c) {
				for (var d, e = [], f = 0, g = a.length, h = !c; f < g; f++) (d = !b(a[f], f)), d !== h && e.push(a[f])
				return e
			},
			map: function (a, b, c) {
				var d,
					e,
					f = 0,
					h = []
				if (w(a)) for (d = a.length; f < d; f++) (e = b(a[f], f, c)), null != e && h.push(e)
				else for (f in a) (e = b(a[f], f, c)), null != e && h.push(e)
				return g.apply([], h)
			},
			guid: 1,
			proxy: function (a, b) {
				var c, d, e
				if (('string' == typeof b && ((c = a[b]), (b = a), (a = c)), r.isFunction(a)))
					return (
						(d = f.call(arguments, 2)),
						(e = function () {
							return a.apply(b || this, d.concat(f.call(arguments)))
						}),
						(e.guid = a.guid = a.guid || r.guid++),
						e
					)
			},
			now: Date.now,
			support: o,
		}),
		'function' == typeof Symbol && (r.fn[Symbol.iterator] = c[Symbol.iterator]),
		r.each('Boolean Number String Function Array Date RegExp Object Error Symbol'.split(' '), function (a, b) {
			j['[object ' + b + ']'] = b.toLowerCase()
		})
	function w(a) {
		var b = !!a && 'length' in a && a.length,
			c = r.type(a)
		return (
			'function' !== c && !r.isWindow(a) && ('array' === c || 0 === b || ('number' == typeof b && b > 0 && b - 1 in a))
		)
	}
	var x = (function (a) {
		var b,
			c,
			d,
			e,
			f,
			g,
			h,
			i,
			j,
			k,
			l,
			m,
			n,
			o,
			p,
			q,
			r,
			s,
			t,
			u = 'sizzle' + 1 * new Date(),
			v = a.document,
			w = 0,
			x = 0,
			y = ha(),
			z = ha(),
			A = ha(),
			B = function (a, b) {
				return a === b && (l = !0), 0
			},
			C = {}.hasOwnProperty,
			D = [],
			E = D.pop,
			F = D.push,
			G = D.push,
			H = D.slice,
			I = function (a, b) {
				for (var c = 0, d = a.length; c < d; c++) if (a[c] === b) return c
				return -1
			},
			J =
				'checked|selected|async|autofocus|autoplay|controls|defer|disabled|hidden|ismap|loop|multiple|open|readonly|required|scoped',
			K = '[\\x20\\t\\r\\n\\f]',
			L = '(?:\\\\.|[\\w-]|[^\0-\\xa0])+',
			M =
				'\\[' +
				K +
				'*(' +
				L +
				')(?:' +
				K +
				'*([*^$|!~]?=)' +
				K +
				'*(?:\'((?:\\\\.|[^\\\\\'])*)\'|"((?:\\\\.|[^\\\\"])*)"|(' +
				L +
				'))|)' +
				K +
				'*\\]',
			N =
				':(' +
				L +
				')(?:\\(((\'((?:\\\\.|[^\\\\\'])*)\'|"((?:\\\\.|[^\\\\"])*)")|((?:\\\\.|[^\\\\()[\\]]|' +
				M +
				')*)|.*)\\)|)',
			O = new RegExp(K + '+', 'g'),
			P = new RegExp('^' + K + '+|((?:^|[^\\\\])(?:\\\\.)*)' + K + '+$', 'g'),
			Q = new RegExp('^' + K + '*,' + K + '*'),
			R = new RegExp('^' + K + '*([>+~]|' + K + ')' + K + '*'),
			S = new RegExp('=' + K + '*([^\\]\'"]*?)' + K + '*\\]', 'g'),
			T = new RegExp(N),
			U = new RegExp('^' + L + '$'),
			V = {
				ID: new RegExp('^#(' + L + ')'),
				CLASS: new RegExp('^\\.(' + L + ')'),
				TAG: new RegExp('^(' + L + '|[*])'),
				ATTR: new RegExp('^' + M),
				PSEUDO: new RegExp('^' + N),
				CHILD: new RegExp(
					'^:(only|first|last|nth|nth-last)-(child|of-type)(?:\\(' +
						K +
						'*(even|odd|(([+-]|)(\\d*)n|)' +
						K +
						'*(?:([+-]|)' +
						K +
						'*(\\d+)|))' +
						K +
						'*\\)|)',
					'i'
				),
				bool: new RegExp('^(?:' + J + ')$', 'i'),
				needsContext: new RegExp(
					'^' +
						K +
						'*[>+~]|:(even|odd|eq|gt|lt|nth|first|last)(?:\\(' +
						K +
						'*((?:-\\d)?\\d*)' +
						K +
						'*\\)|)(?=[^-]|$)',
					'i'
				),
			},
			W = /^(?:input|select|textarea|button)$/i,
			X = /^h\d$/i,
			Y = /^[^{]+\{\s*\[native \w/,
			Z = /^(?:#([\w-]+)|(\w+)|\.([\w-]+))$/,
			$ = /[+~]/,
			_ = new RegExp('\\\\([\\da-f]{1,6}' + K + '?|(' + K + ')|.)', 'ig'),
			aa = function (a, b, c) {
				var d = '0x' + b - 65536
				return d !== d || c
					? b
					: d < 0
						? String.fromCharCode(d + 65536)
						: String.fromCharCode((d >> 10) | 55296, (1023 & d) | 56320)
			},
			ba = /([\0-\x1f\x7f]|^-?\d)|^-$|[^\0-\x1f\x7f-\uFFFF\w-]/g,
			ca = function (a, b) {
				return b
					? '\0' === a
						? '\ufffd'
						: a.slice(0, -1) + '\\' + a.charCodeAt(a.length - 1).toString(16) + ' '
					: '\\' + a
			},
			da = function () {
				m()
			},
			ea = ta(
				function (a) {
					return a.disabled === !0 && ('form' in a || 'label' in a)
				},
				{ dir: 'parentNode', next: 'legend' }
			)
		try {
			G.apply((D = H.call(v.childNodes)), v.childNodes), D[v.childNodes.length].nodeType
		} catch (fa) {
			G = {
				apply: D.length
					? function (a, b) {
							F.apply(a, H.call(b))
						}
					: function (a, b) {
							var c = a.length,
								d = 0
							while ((a[c++] = b[d++]));
							a.length = c - 1
						},
			}
		}
		function ga(a, b, d, e) {
			var f,
				h,
				j,
				k,
				l,
				o,
				r,
				s = b && b.ownerDocument,
				w = b ? b.nodeType : 9
			if (((d = d || []), 'string' != typeof a || !a || (1 !== w && 9 !== w && 11 !== w))) return d
			if (!e && ((b ? b.ownerDocument || b : v) !== n && m(b), (b = b || n), p)) {
				if (11 !== w && (l = Z.exec(a)))
					if ((f = l[1])) {
						if (9 === w) {
							if (!(j = b.getElementById(f))) return d
							if (j.id === f) return d.push(j), d
						} else if (s && (j = s.getElementById(f)) && t(b, j) && j.id === f) return d.push(j), d
					} else {
						if (l[2]) return G.apply(d, b.getElementsByTagName(a)), d
						if ((f = l[3]) && c.getElementsByClassName && b.getElementsByClassName)
							return G.apply(d, b.getElementsByClassName(f)), d
					}
				if (c.qsa && !A[a + ' '] && (!q || !q.test(a))) {
					if (1 !== w) (s = b), (r = a)
					else if ('object' !== b.nodeName.toLowerCase()) {
						;(k = b.getAttribute('id')) ? (k = k.replace(ba, ca)) : b.setAttribute('id', (k = u)),
							(o = g(a)),
							(h = o.length)
						while (h--) o[h] = '#' + k + ' ' + sa(o[h])
						;(r = o.join(',')), (s = ($.test(a) && qa(b.parentNode)) || b)
					}
					if (r)
						try {
							return G.apply(d, s.querySelectorAll(r)), d
						} catch (x) {
						} finally {
							k === u && b.removeAttribute('id')
						}
				}
			}
			return i(a.replace(P, '$1'), b, d, e)
		}
		function ha() {
			var a = []
			function b(c, e) {
				return a.push(c + ' ') > d.cacheLength && delete b[a.shift()], (b[c + ' '] = e)
			}
			return b
		}
		function ia(a) {
			return (a[u] = !0), a
		}
		function ja(a) {
			var b = n.createElement('fieldset')
			try {
				return !!a(b)
			} catch (c) {
				return !1
			} finally {
				b.parentNode && b.parentNode.removeChild(b), (b = null)
			}
		}
		function ka(a, b) {
			var c = a.split('|'),
				e = c.length
			while (e--) d.attrHandle[c[e]] = b
		}
		function la(a, b) {
			var c = b && a,
				d = c && 1 === a.nodeType && 1 === b.nodeType && a.sourceIndex - b.sourceIndex
			if (d) return d
			if (c) while ((c = c.nextSibling)) if (c === b) return -1
			return a ? 1 : -1
		}
		function ma(a) {
			return function (b) {
				var c = b.nodeName.toLowerCase()
				return 'input' === c && b.type === a
			}
		}
		function na(a) {
			return function (b) {
				var c = b.nodeName.toLowerCase()
				return ('input' === c || 'button' === c) && b.type === a
			}
		}
		function oa(a) {
			return function (b) {
				return 'form' in b
					? b.parentNode && b.disabled === !1
						? 'label' in b
							? 'label' in b.parentNode
								? b.parentNode.disabled === a
								: b.disabled === a
							: b.isDisabled === a || (b.isDisabled !== !a && ea(b) === a)
						: b.disabled === a
					: 'label' in b && b.disabled === a
			}
		}
		function pa(a) {
			return ia(function (b) {
				return (
					(b = +b),
					ia(function (c, d) {
						var e,
							f = a([], c.length, b),
							g = f.length
						while (g--) c[(e = f[g])] && (c[e] = !(d[e] = c[e]))
					})
				)
			})
		}
		function qa(a) {
			return a && 'undefined' != typeof a.getElementsByTagName && a
		}
		;(c = ga.support = {}),
			(f = ga.isXML =
				function (a) {
					var b = a && (a.ownerDocument || a).documentElement
					return !!b && 'HTML' !== b.nodeName
				}),
			(m = ga.setDocument =
				function (a) {
					var b,
						e,
						g = a ? a.ownerDocument || a : v
					return g !== n && 9 === g.nodeType && g.documentElement
						? ((n = g),
							(o = n.documentElement),
							(p = !f(n)),
							v !== n &&
								(e = n.defaultView) &&
								e.top !== e &&
								(e.addEventListener
									? e.addEventListener('unload', da, !1)
									: e.attachEvent && e.attachEvent('onunload', da)),
							(c.attributes = ja(function (a) {
								return (a.className = 'i'), !a.getAttribute('className')
							})),
							(c.getElementsByTagName = ja(function (a) {
								return a.appendChild(n.createComment('')), !a.getElementsByTagName('*').length
							})),
							(c.getElementsByClassName = Y.test(n.getElementsByClassName)),
							(c.getById = ja(function (a) {
								return (o.appendChild(a).id = u), !n.getElementsByName || !n.getElementsByName(u).length
							})),
							c.getById
								? ((d.filter.ID = function (a) {
										var b = a.replace(_, aa)
										return function (a) {
											return a.getAttribute('id') === b
										}
									}),
									(d.find.ID = function (a, b) {
										if ('undefined' != typeof b.getElementById && p) {
											var c = b.getElementById(a)
											return c ? [c] : []
										}
									}))
								: ((d.filter.ID = function (a) {
										var b = a.replace(_, aa)
										return function (a) {
											var c = 'undefined' != typeof a.getAttributeNode && a.getAttributeNode('id')
											return c && c.value === b
										}
									}),
									(d.find.ID = function (a, b) {
										if ('undefined' != typeof b.getElementById && p) {
											var c,
												d,
												e,
												f = b.getElementById(a)
											if (f) {
												if (((c = f.getAttributeNode('id')), c && c.value === a)) return [f]
												;(e = b.getElementsByName(a)), (d = 0)
												while ((f = e[d++])) if (((c = f.getAttributeNode('id')), c && c.value === a)) return [f]
											}
											return []
										}
									})),
							(d.find.TAG = c.getElementsByTagName
								? function (a, b) {
										return 'undefined' != typeof b.getElementsByTagName
											? b.getElementsByTagName(a)
											: c.qsa
												? b.querySelectorAll(a)
												: void 0
									}
								: function (a, b) {
										var c,
											d = [],
											e = 0,
											f = b.getElementsByTagName(a)
										if ('*' === a) {
											while ((c = f[e++])) 1 === c.nodeType && d.push(c)
											return d
										}
										return f
									}),
							(d.find.CLASS =
								c.getElementsByClassName &&
								function (a, b) {
									if ('undefined' != typeof b.getElementsByClassName && p) return b.getElementsByClassName(a)
								}),
							(r = []),
							(q = []),
							(c.qsa = Y.test(n.querySelectorAll)) &&
								(ja(function (a) {
									;(o.appendChild(a).innerHTML =
										"<a id='" +
										u +
										"'></a><select id='" +
										u +
										"-\r\\' msallowcapture=''><option selected=''></option></select>"),
										a.querySelectorAll("[msallowcapture^='']").length && q.push('[*^$]=' + K + '*(?:\'\'|"")'),
										a.querySelectorAll('[selected]').length || q.push('\\[' + K + '*(?:value|' + J + ')'),
										a.querySelectorAll('[id~=' + u + '-]').length || q.push('~='),
										a.querySelectorAll(':checked').length || q.push(':checked'),
										a.querySelectorAll('a#' + u + '+*').length || q.push('.#.+[+~]')
								}),
								ja(function (a) {
									a.innerHTML = "<a href='' disabled='disabled'></a><select disabled='disabled'><option/></select>"
									var b = n.createElement('input')
									b.setAttribute('type', 'hidden'),
										a.appendChild(b).setAttribute('name', 'D'),
										a.querySelectorAll('[name=d]').length && q.push('name' + K + '*[*^$|!~]?='),
										2 !== a.querySelectorAll(':enabled').length && q.push(':enabled', ':disabled'),
										(o.appendChild(a).disabled = !0),
										2 !== a.querySelectorAll(':disabled').length && q.push(':enabled', ':disabled'),
										a.querySelectorAll('*,:x'),
										q.push(',.*:')
								})),
							(c.matchesSelector = Y.test(
								(s =
									o.matches ||
									o.webkitMatchesSelector ||
									o.mozMatchesSelector ||
									o.oMatchesSelector ||
									o.msMatchesSelector)
							)) &&
								ja(function (a) {
									;(c.disconnectedMatch = s.call(a, '*')), s.call(a, "[s!='']:x"), r.push('!=', N)
								}),
							(q = q.length && new RegExp(q.join('|'))),
							(r = r.length && new RegExp(r.join('|'))),
							(b = Y.test(o.compareDocumentPosition)),
							(t =
								b || Y.test(o.contains)
									? function (a, b) {
											var c = 9 === a.nodeType ? a.documentElement : a,
												d = b && b.parentNode
											return (
												a === d ||
												!(
													!d ||
													1 !== d.nodeType ||
													!(c.contains ? c.contains(d) : a.compareDocumentPosition && 16 & a.compareDocumentPosition(d))
												)
											)
										}
									: function (a, b) {
											if (b) while ((b = b.parentNode)) if (b === a) return !0
											return !1
										}),
							(B = b
								? function (a, b) {
										if (a === b) return (l = !0), 0
										var d = !a.compareDocumentPosition - !b.compareDocumentPosition
										return d
											? d
											: ((d = (a.ownerDocument || a) === (b.ownerDocument || b) ? a.compareDocumentPosition(b) : 1),
												1 & d || (!c.sortDetached && b.compareDocumentPosition(a) === d)
													? a === n || (a.ownerDocument === v && t(v, a))
														? -1
														: b === n || (b.ownerDocument === v && t(v, b))
															? 1
															: k
																? I(k, a) - I(k, b)
																: 0
													: 4 & d
														? -1
														: 1)
									}
								: function (a, b) {
										if (a === b) return (l = !0), 0
										var c,
											d = 0,
											e = a.parentNode,
											f = b.parentNode,
											g = [a],
											h = [b]
										if (!e || !f) return a === n ? -1 : b === n ? 1 : e ? -1 : f ? 1 : k ? I(k, a) - I(k, b) : 0
										if (e === f) return la(a, b)
										c = a
										while ((c = c.parentNode)) g.unshift(c)
										c = b
										while ((c = c.parentNode)) h.unshift(c)
										while (g[d] === h[d]) d++
										return d ? la(g[d], h[d]) : g[d] === v ? -1 : h[d] === v ? 1 : 0
									}),
							n)
						: n
				}),
			(ga.matches = function (a, b) {
				return ga(a, null, null, b)
			}),
			(ga.matchesSelector = function (a, b) {
				if (
					((a.ownerDocument || a) !== n && m(a),
					(b = b.replace(S, "='$1']")),
					c.matchesSelector && p && !A[b + ' '] && (!r || !r.test(b)) && (!q || !q.test(b)))
				)
					try {
						var d = s.call(a, b)
						if (d || c.disconnectedMatch || (a.document && 11 !== a.document.nodeType)) return d
					} catch (e) {}
				return ga(b, n, null, [a]).length > 0
			}),
			(ga.contains = function (a, b) {
				return (a.ownerDocument || a) !== n && m(a), t(a, b)
			}),
			(ga.attr = function (a, b) {
				;(a.ownerDocument || a) !== n && m(a)
				var e = d.attrHandle[b.toLowerCase()],
					f = e && C.call(d.attrHandle, b.toLowerCase()) ? e(a, b, !p) : void 0
				return void 0 !== f
					? f
					: c.attributes || !p
						? a.getAttribute(b)
						: (f = a.getAttributeNode(b)) && f.specified
							? f.value
							: null
			}),
			(ga.escape = function (a) {
				return (a + '').replace(ba, ca)
			}),
			(ga.error = function (a) {
				throw new Error('Syntax error, unrecognized expression: ' + a)
			}),
			(ga.uniqueSort = function (a) {
				var b,
					d = [],
					e = 0,
					f = 0
				if (((l = !c.detectDuplicates), (k = !c.sortStable && a.slice(0)), a.sort(B), l)) {
					while ((b = a[f++])) b === a[f] && (e = d.push(f))
					while (e--) a.splice(d[e], 1)
				}
				return (k = null), a
			}),
			(e = ga.getText =
				function (a) {
					var b,
						c = '',
						d = 0,
						f = a.nodeType
					if (f) {
						if (1 === f || 9 === f || 11 === f) {
							if ('string' == typeof a.textContent) return a.textContent
							for (a = a.firstChild; a; a = a.nextSibling) c += e(a)
						} else if (3 === f || 4 === f) return a.nodeValue
					} else while ((b = a[d++])) c += e(b)
					return c
				}),
			(d = ga.selectors =
				{
					cacheLength: 50,
					createPseudo: ia,
					match: V,
					attrHandle: {},
					find: {},
					relative: {
						'>': { dir: 'parentNode', first: !0 },
						' ': { dir: 'parentNode' },
						'+': { dir: 'previousSibling', first: !0 },
						'~': { dir: 'previousSibling' },
					},
					preFilter: {
						ATTR: function (a) {
							return (
								(a[1] = a[1].replace(_, aa)),
								(a[3] = (a[3] || a[4] || a[5] || '').replace(_, aa)),
								'~=' === a[2] && (a[3] = ' ' + a[3] + ' '),
								a.slice(0, 4)
							)
						},
						CHILD: function (a) {
							return (
								(a[1] = a[1].toLowerCase()),
								'nth' === a[1].slice(0, 3)
									? (a[3] || ga.error(a[0]),
										(a[4] = +(a[4] ? a[5] + (a[6] || 1) : 2 * ('even' === a[3] || 'odd' === a[3]))),
										(a[5] = +(a[7] + a[8] || 'odd' === a[3])))
									: a[3] && ga.error(a[0]),
								a
							)
						},
						PSEUDO: function (a) {
							var b,
								c = !a[6] && a[2]
							return V.CHILD.test(a[0])
								? null
								: (a[3]
										? (a[2] = a[4] || a[5] || '')
										: c &&
											T.test(c) &&
											(b = g(c, !0)) &&
											(b = c.indexOf(')', c.length - b) - c.length) &&
											((a[0] = a[0].slice(0, b)), (a[2] = c.slice(0, b))),
									a.slice(0, 3))
						},
					},
					filter: {
						TAG: function (a) {
							var b = a.replace(_, aa).toLowerCase()
							return '*' === a
								? function () {
										return !0
									}
								: function (a) {
										return a.nodeName && a.nodeName.toLowerCase() === b
									}
						},
						CLASS: function (a) {
							var b = y[a + ' ']
							return (
								b ||
								((b = new RegExp('(^|' + K + ')' + a + '(' + K + '|$)')) &&
									y(a, function (a) {
										return b.test(
											('string' == typeof a.className && a.className) ||
												('undefined' != typeof a.getAttribute && a.getAttribute('class')) ||
												''
										)
									}))
							)
						},
						ATTR: function (a, b, c) {
							return function (d) {
								var e = ga.attr(d, a)
								return null == e
									? '!=' === b
									: !b ||
											((e += ''),
											'=' === b
												? e === c
												: '!=' === b
													? e !== c
													: '^=' === b
														? c && 0 === e.indexOf(c)
														: '*=' === b
															? c && e.indexOf(c) > -1
															: '$=' === b
																? c && e.slice(-c.length) === c
																: '~=' === b
																	? (' ' + e.replace(O, ' ') + ' ').indexOf(c) > -1
																	: '|=' === b && (e === c || e.slice(0, c.length + 1) === c + '-'))
							}
						},
						CHILD: function (a, b, c, d, e) {
							var f = 'nth' !== a.slice(0, 3),
								g = 'last' !== a.slice(-4),
								h = 'of-type' === b
							return 1 === d && 0 === e
								? function (a) {
										return !!a.parentNode
									}
								: function (b, c, i) {
										var j,
											k,
											l,
											m,
											n,
											o,
											p = f !== g ? 'nextSibling' : 'previousSibling',
											q = b.parentNode,
											r = h && b.nodeName.toLowerCase(),
											s = !i && !h,
											t = !1
										if (q) {
											if (f) {
												while (p) {
													m = b
													while ((m = m[p])) if (h ? m.nodeName.toLowerCase() === r : 1 === m.nodeType) return !1
													o = p = 'only' === a && !o && 'nextSibling'
												}
												return !0
											}
											if (((o = [g ? q.firstChild : q.lastChild]), g && s)) {
												;(m = q),
													(l = m[u] || (m[u] = {})),
													(k = l[m.uniqueID] || (l[m.uniqueID] = {})),
													(j = k[a] || []),
													(n = j[0] === w && j[1]),
													(t = n && j[2]),
													(m = n && q.childNodes[n])
												while ((m = (++n && m && m[p]) || (t = n = 0) || o.pop()))
													if (1 === m.nodeType && ++t && m === b) {
														k[a] = [w, n, t]
														break
													}
											} else if (
												(s &&
													((m = b),
													(l = m[u] || (m[u] = {})),
													(k = l[m.uniqueID] || (l[m.uniqueID] = {})),
													(j = k[a] || []),
													(n = j[0] === w && j[1]),
													(t = n)),
												t === !1)
											)
												while ((m = (++n && m && m[p]) || (t = n = 0) || o.pop()))
													if (
														(h ? m.nodeName.toLowerCase() === r : 1 === m.nodeType) &&
														++t &&
														(s &&
															((l = m[u] || (m[u] = {})), (k = l[m.uniqueID] || (l[m.uniqueID] = {})), (k[a] = [w, t])),
														m === b)
													)
														break
											return (t -= e), t === d || (t % d === 0 && t / d >= 0)
										}
									}
						},
						PSEUDO: function (a, b) {
							var c,
								e = d.pseudos[a] || d.setFilters[a.toLowerCase()] || ga.error('unsupported pseudo: ' + a)
							return e[u]
								? e(b)
								: e.length > 1
									? ((c = [a, a, '', b]),
										d.setFilters.hasOwnProperty(a.toLowerCase())
											? ia(function (a, c) {
													var d,
														f = e(a, b),
														g = f.length
													while (g--) (d = I(a, f[g])), (a[d] = !(c[d] = f[g]))
												})
											: function (a) {
													return e(a, 0, c)
												})
									: e
						},
					},
					pseudos: {
						not: ia(function (a) {
							var b = [],
								c = [],
								d = h(a.replace(P, '$1'))
							return d[u]
								? ia(function (a, b, c, e) {
										var f,
											g = d(a, null, e, []),
											h = a.length
										while (h--) (f = g[h]) && (a[h] = !(b[h] = f))
									})
								: function (a, e, f) {
										return (b[0] = a), d(b, null, f, c), (b[0] = null), !c.pop()
									}
						}),
						has: ia(function (a) {
							return function (b) {
								return ga(a, b).length > 0
							}
						}),
						contains: ia(function (a) {
							return (
								(a = a.replace(_, aa)),
								function (b) {
									return (b.textContent || b.innerText || e(b)).indexOf(a) > -1
								}
							)
						}),
						lang: ia(function (a) {
							return (
								U.test(a || '') || ga.error('unsupported lang: ' + a),
								(a = a.replace(_, aa).toLowerCase()),
								function (b) {
									var c
									do
										if ((c = p ? b.lang : b.getAttribute('xml:lang') || b.getAttribute('lang')))
											return (c = c.toLowerCase()), c === a || 0 === c.indexOf(a + '-')
									while ((b = b.parentNode) && 1 === b.nodeType)
									return !1
								}
							)
						}),
						target: function (b) {
							var c = a.location && a.location.hash
							return c && c.slice(1) === b.id
						},
						root: function (a) {
							return a === o
						},
						focus: function (a) {
							return a === n.activeElement && (!n.hasFocus || n.hasFocus()) && !!(a.type || a.href || ~a.tabIndex)
						},
						enabled: oa(!1),
						disabled: oa(!0),
						checked: function (a) {
							var b = a.nodeName.toLowerCase()
							return ('input' === b && !!a.checked) || ('option' === b && !!a.selected)
						},
						selected: function (a) {
							return a.parentNode && a.parentNode.selectedIndex, a.selected === !0
						},
						empty: function (a) {
							for (a = a.firstChild; a; a = a.nextSibling) if (a.nodeType < 6) return !1
							return !0
						},
						parent: function (a) {
							return !d.pseudos.empty(a)
						},
						header: function (a) {
							return X.test(a.nodeName)
						},
						input: function (a) {
							return W.test(a.nodeName)
						},
						button: function (a) {
							var b = a.nodeName.toLowerCase()
							return ('input' === b && 'button' === a.type) || 'button' === b
						},
						text: function (a) {
							var b
							return (
								'input' === a.nodeName.toLowerCase() &&
								'text' === a.type &&
								(null == (b = a.getAttribute('type')) || 'text' === b.toLowerCase())
							)
						},
						first: pa(function () {
							return [0]
						}),
						last: pa(function (a, b) {
							return [b - 1]
						}),
						eq: pa(function (a, b, c) {
							return [c < 0 ? c + b : c]
						}),
						even: pa(function (a, b) {
							for (var c = 0; c < b; c += 2) a.push(c)
							return a
						}),
						odd: pa(function (a, b) {
							for (var c = 1; c < b; c += 2) a.push(c)
							return a
						}),
						lt: pa(function (a, b, c) {
							for (var d = c < 0 ? c + b : c; --d >= 0; ) a.push(d)
							return a
						}),
						gt: pa(function (a, b, c) {
							for (var d = c < 0 ? c + b : c; ++d < b; ) a.push(d)
							return a
						}),
					},
				}),
			(d.pseudos.nth = d.pseudos.eq)
		for (b in { radio: !0, checkbox: !0, file: !0, password: !0, image: !0 }) d.pseudos[b] = ma(b)
		for (b in { submit: !0, reset: !0 }) d.pseudos[b] = na(b)
		function ra() {}
		;(ra.prototype = d.filters = d.pseudos),
			(d.setFilters = new ra()),
			(g = ga.tokenize =
				function (a, b) {
					var c,
						e,
						f,
						g,
						h,
						i,
						j,
						k = z[a + ' ']
					if (k) return b ? 0 : k.slice(0)
					;(h = a), (i = []), (j = d.preFilter)
					while (h) {
						;(c && !(e = Q.exec(h))) || (e && (h = h.slice(e[0].length) || h), i.push((f = []))),
							(c = !1),
							(e = R.exec(h)) &&
								((c = e.shift()), f.push({ value: c, type: e[0].replace(P, ' ') }), (h = h.slice(c.length)))
						for (g in d.filter)
							!(e = V[g].exec(h)) ||
								(j[g] && !(e = j[g](e))) ||
								((c = e.shift()), f.push({ value: c, type: g, matches: e }), (h = h.slice(c.length)))
						if (!c) break
					}
					return b ? h.length : h ? ga.error(a) : z(a, i).slice(0)
				})
		function sa(a) {
			for (var b = 0, c = a.length, d = ''; b < c; b++) d += a[b].value
			return d
		}
		function ta(a, b, c) {
			var d = b.dir,
				e = b.next,
				f = e || d,
				g = c && 'parentNode' === f,
				h = x++
			return b.first
				? function (b, c, e) {
						while ((b = b[d])) if (1 === b.nodeType || g) return a(b, c, e)
						return !1
					}
				: function (b, c, i) {
						var j,
							k,
							l,
							m = [w, h]
						if (i) {
							while ((b = b[d])) if ((1 === b.nodeType || g) && a(b, c, i)) return !0
						} else
							while ((b = b[d]))
								if (1 === b.nodeType || g)
									if (
										((l = b[u] || (b[u] = {})),
										(k = l[b.uniqueID] || (l[b.uniqueID] = {})),
										e && e === b.nodeName.toLowerCase())
									)
										b = b[d] || b
									else {
										if ((j = k[f]) && j[0] === w && j[1] === h) return (m[2] = j[2])
										if (((k[f] = m), (m[2] = a(b, c, i)))) return !0
									}
						return !1
					}
		}
		function ua(a) {
			return a.length > 1
				? function (b, c, d) {
						var e = a.length
						while (e--) if (!a[e](b, c, d)) return !1
						return !0
					}
				: a[0]
		}
		function va(a, b, c) {
			for (var d = 0, e = b.length; d < e; d++) ga(a, b[d], c)
			return c
		}
		function wa(a, b, c, d, e) {
			for (var f, g = [], h = 0, i = a.length, j = null != b; h < i; h++)
				(f = a[h]) && ((c && !c(f, d, e)) || (g.push(f), j && b.push(h)))
			return g
		}
		function xa(a, b, c, d, e, f) {
			return (
				d && !d[u] && (d = xa(d)),
				e && !e[u] && (e = xa(e, f)),
				ia(function (f, g, h, i) {
					var j,
						k,
						l,
						m = [],
						n = [],
						o = g.length,
						p = f || va(b || '*', h.nodeType ? [h] : h, []),
						q = !a || (!f && b) ? p : wa(p, m, a, h, i),
						r = c ? (e || (f ? a : o || d) ? [] : g) : q
					if ((c && c(q, r, h, i), d)) {
						;(j = wa(r, n)), d(j, [], h, i), (k = j.length)
						while (k--) (l = j[k]) && (r[n[k]] = !(q[n[k]] = l))
					}
					if (f) {
						if (e || a) {
							if (e) {
								;(j = []), (k = r.length)
								while (k--) (l = r[k]) && j.push((q[k] = l))
								e(null, (r = []), j, i)
							}
							k = r.length
							while (k--) (l = r[k]) && (j = e ? I(f, l) : m[k]) > -1 && (f[j] = !(g[j] = l))
						}
					} else (r = wa(r === g ? r.splice(o, r.length) : r)), e ? e(null, g, r, i) : G.apply(g, r)
				})
			)
		}
		function ya(a) {
			for (
				var b,
					c,
					e,
					f = a.length,
					g = d.relative[a[0].type],
					h = g || d.relative[' '],
					i = g ? 1 : 0,
					k = ta(
						function (a) {
							return a === b
						},
						h,
						!0
					),
					l = ta(
						function (a) {
							return I(b, a) > -1
						},
						h,
						!0
					),
					m = [
						function (a, c, d) {
							var e = (!g && (d || c !== j)) || ((b = c).nodeType ? k(a, c, d) : l(a, c, d))
							return (b = null), e
						},
					];
				i < f;
				i++
			)
				if ((c = d.relative[a[i].type])) m = [ta(ua(m), c)]
				else {
					if (((c = d.filter[a[i].type].apply(null, a[i].matches)), c[u])) {
						for (e = ++i; e < f; e++) if (d.relative[a[e].type]) break
						return xa(
							i > 1 && ua(m),
							i > 1 && sa(a.slice(0, i - 1).concat({ value: ' ' === a[i - 2].type ? '*' : '' })).replace(P, '$1'),
							c,
							i < e && ya(a.slice(i, e)),
							e < f && ya((a = a.slice(e))),
							e < f && sa(a)
						)
					}
					m.push(c)
				}
			return ua(m)
		}
		function za(a, b) {
			var c = b.length > 0,
				e = a.length > 0,
				f = function (f, g, h, i, k) {
					var l,
						o,
						q,
						r = 0,
						s = '0',
						t = f && [],
						u = [],
						v = j,
						x = f || (e && d.find.TAG('*', k)),
						y = (w += null == v ? 1 : Math.random() || 0.1),
						z = x.length
					for (k && (j = g === n || g || k); s !== z && null != (l = x[s]); s++) {
						if (e && l) {
							;(o = 0), g || l.ownerDocument === n || (m(l), (h = !p))
							while ((q = a[o++]))
								if (q(l, g || n, h)) {
									i.push(l)
									break
								}
							k && (w = y)
						}
						c && ((l = !q && l) && r--, f && t.push(l))
					}
					if (((r += s), c && s !== r)) {
						o = 0
						while ((q = b[o++])) q(t, u, g, h)
						if (f) {
							if (r > 0) while (s--) t[s] || u[s] || (u[s] = E.call(i))
							u = wa(u)
						}
						G.apply(i, u), k && !f && u.length > 0 && r + b.length > 1 && ga.uniqueSort(i)
					}
					return k && ((w = y), (j = v)), t
				}
			return c ? ia(f) : f
		}
		return (
			(h = ga.compile =
				function (a, b) {
					var c,
						d = [],
						e = [],
						f = A[a + ' ']
					if (!f) {
						b || (b = g(a)), (c = b.length)
						while (c--) (f = ya(b[c])), f[u] ? d.push(f) : e.push(f)
						;(f = A(a, za(e, d))), (f.selector = a)
					}
					return f
				}),
			(i = ga.select =
				function (a, b, c, e) {
					var f,
						i,
						j,
						k,
						l,
						m = 'function' == typeof a && a,
						n = !e && g((a = m.selector || a))
					if (((c = c || []), 1 === n.length)) {
						if (
							((i = n[0] = n[0].slice(0)),
							i.length > 2 && 'ID' === (j = i[0]).type && 9 === b.nodeType && p && d.relative[i[1].type])
						) {
							if (((b = (d.find.ID(j.matches[0].replace(_, aa), b) || [])[0]), !b)) return c
							m && (b = b.parentNode), (a = a.slice(i.shift().value.length))
						}
						f = V.needsContext.test(a) ? 0 : i.length
						while (f--) {
							if (((j = i[f]), d.relative[(k = j.type)])) break
							if (
								(l = d.find[k]) &&
								(e = l(j.matches[0].replace(_, aa), ($.test(i[0].type) && qa(b.parentNode)) || b))
							) {
								if ((i.splice(f, 1), (a = e.length && sa(i)), !a)) return G.apply(c, e), c
								break
							}
						}
					}
					return (m || h(a, n))(e, b, !p, c, !b || ($.test(a) && qa(b.parentNode)) || b), c
				}),
			(c.sortStable = u.split('').sort(B).join('') === u),
			(c.detectDuplicates = !!l),
			m(),
			(c.sortDetached = ja(function (a) {
				return 1 & a.compareDocumentPosition(n.createElement('fieldset'))
			})),
			ja(function (a) {
				return (a.innerHTML = "<a href='#'></a>"), '#' === a.firstChild.getAttribute('href')
			}) ||
				ka('type|href|height|width', function (a, b, c) {
					if (!c) return a.getAttribute(b, 'type' === b.toLowerCase() ? 1 : 2)
				}),
			(c.attributes &&
				ja(function (a) {
					return (
						(a.innerHTML = '<input/>'),
						a.firstChild.setAttribute('value', ''),
						'' === a.firstChild.getAttribute('value')
					)
				})) ||
				ka('value', function (a, b, c) {
					if (!c && 'input' === a.nodeName.toLowerCase()) return a.defaultValue
				}),
			ja(function (a) {
				return null == a.getAttribute('disabled')
			}) ||
				ka(J, function (a, b, c) {
					var d
					if (!c) return a[b] === !0 ? b.toLowerCase() : (d = a.getAttributeNode(b)) && d.specified ? d.value : null
				}),
			ga
		)
	})(a)
	;(r.find = x),
		(r.expr = x.selectors),
		(r.expr[':'] = r.expr.pseudos),
		(r.uniqueSort = r.unique = x.uniqueSort),
		(r.text = x.getText),
		(r.isXMLDoc = x.isXML),
		(r.contains = x.contains),
		(r.escapeSelector = x.escape)
	var y = function (a, b, c) {
			var d = [],
				e = void 0 !== c
			while ((a = a[b]) && 9 !== a.nodeType)
				if (1 === a.nodeType) {
					if (e && r(a).is(c)) break
					d.push(a)
				}
			return d
		},
		z = function (a, b) {
			for (var c = []; a; a = a.nextSibling) 1 === a.nodeType && a !== b && c.push(a)
			return c
		},
		A = r.expr.match.needsContext
	function B(a, b) {
		return a.nodeName && a.nodeName.toLowerCase() === b.toLowerCase()
	}
	var C = /^<([a-z][^\/\0>:\x20\t\r\n\f]*)[\x20\t\r\n\f]*\/?>(?:<\/\1>|)$/i,
		D = /^.[^:#\[\.,]*$/
	function E(a, b, c) {
		return r.isFunction(b)
			? r.grep(a, function (a, d) {
					return !!b.call(a, d, a) !== c
				})
			: b.nodeType
				? r.grep(a, function (a) {
						return (a === b) !== c
					})
				: 'string' != typeof b
					? r.grep(a, function (a) {
							return i.call(b, a) > -1 !== c
						})
					: D.test(b)
						? r.filter(b, a, c)
						: ((b = r.filter(b, a)),
							r.grep(a, function (a) {
								return i.call(b, a) > -1 !== c && 1 === a.nodeType
							}))
	}
	;(r.filter = function (a, b, c) {
		var d = b[0]
		return (
			c && (a = ':not(' + a + ')'),
			1 === b.length && 1 === d.nodeType
				? r.find.matchesSelector(d, a)
					? [d]
					: []
				: r.find.matches(
						a,
						r.grep(b, function (a) {
							return 1 === a.nodeType
						})
					)
		)
	}),
		r.fn.extend({
			find: function (a) {
				var b,
					c,
					d = this.length,
					e = this
				if ('string' != typeof a)
					return this.pushStack(
						r(a).filter(function () {
							for (b = 0; b < d; b++) if (r.contains(e[b], this)) return !0
						})
					)
				for (c = this.pushStack([]), b = 0; b < d; b++) r.find(a, e[b], c)
				return d > 1 ? r.uniqueSort(c) : c
			},
			filter: function (a) {
				return this.pushStack(E(this, a || [], !1))
			},
			not: function (a) {
				return this.pushStack(E(this, a || [], !0))
			},
			is: function (a) {
				return !!E(this, 'string' == typeof a && A.test(a) ? r(a) : a || [], !1).length
			},
		})
	var F,
		G = /^(?:\s*(<[\w\W]+>)[^>]*|#([\w-]+))$/,
		H = (r.fn.init = function (a, b, c) {
			var e, f
			if (!a) return this
			if (((c = c || F), 'string' == typeof a)) {
				if (
					((e = '<' === a[0] && '>' === a[a.length - 1] && a.length >= 3 ? [null, a, null] : G.exec(a)),
					!e || (!e[1] && b))
				)
					return !b || b.jquery ? (b || c).find(a) : this.constructor(b).find(a)
				if (e[1]) {
					if (
						((b = b instanceof r ? b[0] : b),
						r.merge(this, r.parseHTML(e[1], b && b.nodeType ? b.ownerDocument || b : d, !0)),
						C.test(e[1]) && r.isPlainObject(b))
					)
						for (e in b) r.isFunction(this[e]) ? this[e](b[e]) : this.attr(e, b[e])
					return this
				}
				return (f = d.getElementById(e[2])), f && ((this[0] = f), (this.length = 1)), this
			}
			return a.nodeType
				? ((this[0] = a), (this.length = 1), this)
				: r.isFunction(a)
					? void 0 !== c.ready
						? c.ready(a)
						: a(r)
					: r.makeArray(a, this)
		})
	;(H.prototype = r.fn), (F = r(d))
	var I = /^(?:parents|prev(?:Until|All))/,
		J = { children: !0, contents: !0, next: !0, prev: !0 }
	r.fn.extend({
		has: function (a) {
			var b = r(a, this),
				c = b.length
			return this.filter(function () {
				for (var a = 0; a < c; a++) if (r.contains(this, b[a])) return !0
			})
		},
		closest: function (a, b) {
			var c,
				d = 0,
				e = this.length,
				f = [],
				g = 'string' != typeof a && r(a)
			if (!A.test(a))
				for (; d < e; d++)
					for (c = this[d]; c && c !== b; c = c.parentNode)
						if (c.nodeType < 11 && (g ? g.index(c) > -1 : 1 === c.nodeType && r.find.matchesSelector(c, a))) {
							f.push(c)
							break
						}
			return this.pushStack(f.length > 1 ? r.uniqueSort(f) : f)
		},
		index: function (a) {
			return a
				? 'string' == typeof a
					? i.call(r(a), this[0])
					: i.call(this, a.jquery ? a[0] : a)
				: this[0] && this[0].parentNode
					? this.first().prevAll().length
					: -1
		},
		add: function (a, b) {
			return this.pushStack(r.uniqueSort(r.merge(this.get(), r(a, b))))
		},
		addBack: function (a) {
			return this.add(null == a ? this.prevObject : this.prevObject.filter(a))
		},
	})
	function K(a, b) {
		while ((a = a[b]) && 1 !== a.nodeType);
		return a
	}
	r.each(
		{
			parent: function (a) {
				var b = a.parentNode
				return b && 11 !== b.nodeType ? b : null
			},
			parents: function (a) {
				return y(a, 'parentNode')
			},
			parentsUntil: function (a, b, c) {
				return y(a, 'parentNode', c)
			},
			next: function (a) {
				return K(a, 'nextSibling')
			},
			prev: function (a) {
				return K(a, 'previousSibling')
			},
			nextAll: function (a) {
				return y(a, 'nextSibling')
			},
			prevAll: function (a) {
				return y(a, 'previousSibling')
			},
			nextUntil: function (a, b, c) {
				return y(a, 'nextSibling', c)
			},
			prevUntil: function (a, b, c) {
				return y(a, 'previousSibling', c)
			},
			siblings: function (a) {
				return z((a.parentNode || {}).firstChild, a)
			},
			children: function (a) {
				return z(a.firstChild)
			},
			contents: function (a) {
				return B(a, 'iframe')
					? a.contentDocument
					: (B(a, 'template') && (a = a.content || a), r.merge([], a.childNodes))
			},
		},
		function (a, b) {
			r.fn[a] = function (c, d) {
				var e = r.map(this, b, c)
				return (
					'Until' !== a.slice(-5) && (d = c),
					d && 'string' == typeof d && (e = r.filter(d, e)),
					this.length > 1 && (J[a] || r.uniqueSort(e), I.test(a) && e.reverse()),
					this.pushStack(e)
				)
			}
		}
	)
	var L = /[^\x20\t\r\n\f]+/g
	function M(a) {
		var b = {}
		return (
			r.each(a.match(L) || [], function (a, c) {
				b[c] = !0
			}),
			b
		)
	}
	r.Callbacks = function (a) {
		a = 'string' == typeof a ? M(a) : r.extend({}, a)
		var b,
			c,
			d,
			e,
			f = [],
			g = [],
			h = -1,
			i = function () {
				for (e = e || a.once, d = b = !0; g.length; h = -1) {
					c = g.shift()
					while (++h < f.length) f[h].apply(c[0], c[1]) === !1 && a.stopOnFalse && ((h = f.length), (c = !1))
				}
				a.memory || (c = !1), (b = !1), e && (f = c ? [] : '')
			},
			j = {
				add: function () {
					return (
						f &&
							(c && !b && ((h = f.length - 1), g.push(c)),
							(function d(b) {
								r.each(b, function (b, c) {
									r.isFunction(c)
										? (a.unique && j.has(c)) || f.push(c)
										: c && c.length && 'string' !== r.type(c) && d(c)
								})
							})(arguments),
							c && !b && i()),
						this
					)
				},
				remove: function () {
					return (
						r.each(arguments, function (a, b) {
							var c
							while ((c = r.inArray(b, f, c)) > -1) f.splice(c, 1), c <= h && h--
						}),
						this
					)
				},
				has: function (a) {
					return a ? r.inArray(a, f) > -1 : f.length > 0
				},
				empty: function () {
					return f && (f = []), this
				},
				disable: function () {
					return (e = g = []), (f = c = ''), this
				},
				disabled: function () {
					return !f
				},
				lock: function () {
					return (e = g = []), c || b || (f = c = ''), this
				},
				locked: function () {
					return !!e
				},
				fireWith: function (a, c) {
					return e || ((c = c || []), (c = [a, c.slice ? c.slice() : c]), g.push(c), b || i()), this
				},
				fire: function () {
					return j.fireWith(this, arguments), this
				},
				fired: function () {
					return !!d
				},
			}
		return j
	}
	function N(a) {
		return a
	}
	function O(a) {
		throw a
	}
	function P(a, b, c, d) {
		var e
		try {
			a && r.isFunction((e = a.promise))
				? e.call(a).done(b).fail(c)
				: a && r.isFunction((e = a.then))
					? e.call(a, b, c)
					: b.apply(void 0, [a].slice(d))
		} catch (a) {
			c.apply(void 0, [a])
		}
	}
	r.extend({
		Deferred: function (b) {
			var c = [
					['notify', 'progress', r.Callbacks('memory'), r.Callbacks('memory'), 2],
					['resolve', 'done', r.Callbacks('once memory'), r.Callbacks('once memory'), 0, 'resolved'],
					['reject', 'fail', r.Callbacks('once memory'), r.Callbacks('once memory'), 1, 'rejected'],
				],
				d = 'pending',
				e = {
					state: function () {
						return d
					},
					always: function () {
						return f.done(arguments).fail(arguments), this
					},
					catch: function (a) {
						return e.then(null, a)
					},
					pipe: function () {
						var a = arguments
						return r
							.Deferred(function (b) {
								r.each(c, function (c, d) {
									var e = r.isFunction(a[d[4]]) && a[d[4]]
									f[d[1]](function () {
										var a = e && e.apply(this, arguments)
										a && r.isFunction(a.promise)
											? a.promise().progress(b.notify).done(b.resolve).fail(b.reject)
											: b[d[0] + 'With'](this, e ? [a] : arguments)
									})
								}),
									(a = null)
							})
							.promise()
					},
					then: function (b, d, e) {
						var f = 0
						function g(b, c, d, e) {
							return function () {
								var h = this,
									i = arguments,
									j = function () {
										var a, j
										if (!(b < f)) {
											if (((a = d.apply(h, i)), a === c.promise())) throw new TypeError('Thenable self-resolution')
											;(j = a && ('object' == typeof a || 'function' == typeof a) && a.then),
												r.isFunction(j)
													? e
														? j.call(a, g(f, c, N, e), g(f, c, O, e))
														: (f++, j.call(a, g(f, c, N, e), g(f, c, O, e), g(f, c, N, c.notifyWith)))
													: (d !== N && ((h = void 0), (i = [a])), (e || c.resolveWith)(h, i))
										}
									},
									k = e
										? j
										: function () {
												try {
													j()
												} catch (a) {
													r.Deferred.exceptionHook && r.Deferred.exceptionHook(a, k.stackTrace),
														b + 1 >= f && (d !== O && ((h = void 0), (i = [a])), c.rejectWith(h, i))
												}
											}
								b ? k() : (r.Deferred.getStackHook && (k.stackTrace = r.Deferred.getStackHook()), a.setTimeout(k))
							}
						}
						return r
							.Deferred(function (a) {
								c[0][3].add(g(0, a, r.isFunction(e) ? e : N, a.notifyWith)),
									c[1][3].add(g(0, a, r.isFunction(b) ? b : N)),
									c[2][3].add(g(0, a, r.isFunction(d) ? d : O))
							})
							.promise()
					},
					promise: function (a) {
						return null != a ? r.extend(a, e) : e
					},
				},
				f = {}
			return (
				r.each(c, function (a, b) {
					var g = b[2],
						h = b[5]
					;(e[b[1]] = g.add),
						h &&
							g.add(
								function () {
									d = h
								},
								c[3 - a][2].disable,
								c[0][2].lock
							),
						g.add(b[3].fire),
						(f[b[0]] = function () {
							return f[b[0] + 'With'](this === f ? void 0 : this, arguments), this
						}),
						(f[b[0] + 'With'] = g.fireWith)
				}),
				e.promise(f),
				b && b.call(f, f),
				f
			)
		},
		when: function (a) {
			var b = arguments.length,
				c = b,
				d = Array(c),
				e = f.call(arguments),
				g = r.Deferred(),
				h = function (a) {
					return function (c) {
						;(d[a] = this), (e[a] = arguments.length > 1 ? f.call(arguments) : c), --b || g.resolveWith(d, e)
					}
				}
			if (
				b <= 1 &&
				(P(a, g.done(h(c)).resolve, g.reject, !b), 'pending' === g.state() || r.isFunction(e[c] && e[c].then))
			)
				return g.then()
			while (c--) P(e[c], h(c), g.reject)
			return g.promise()
		},
	})
	var Q = /^(Eval|Internal|Range|Reference|Syntax|Type|URI)Error$/
	;(r.Deferred.exceptionHook = function (b, c) {
		a.console &&
			a.console.warn &&
			b &&
			Q.test(b.name) &&
			a.console.warn('jQuery.Deferred exception: ' + b.message, b.stack, c)
	}),
		(r.readyException = function (b) {
			a.setTimeout(function () {
				throw b
			})
		})
	var R = r.Deferred()
	;(r.fn.ready = function (a) {
		return (
			R.then(a)['catch'](function (a) {
				r.readyException(a)
			}),
			this
		)
	}),
		r.extend({
			isReady: !1,
			readyWait: 1,
			ready: function (a) {
				;(a === !0 ? --r.readyWait : r.isReady) ||
					((r.isReady = !0), (a !== !0 && --r.readyWait > 0) || R.resolveWith(d, [r]))
			},
		}),
		(r.ready.then = R.then)
	function S() {
		d.removeEventListener('DOMContentLoaded', S), a.removeEventListener('load', S), r.ready()
	}
	'complete' === d.readyState || ('loading' !== d.readyState && !d.documentElement.doScroll)
		? a.setTimeout(r.ready)
		: (d.addEventListener('DOMContentLoaded', S), a.addEventListener('load', S))
	var T = function (a, b, c, d, e, f, g) {
			var h = 0,
				i = a.length,
				j = null == c
			if ('object' === r.type(c)) {
				e = !0
				for (h in c) T(a, b, h, c[h], !0, f, g)
			} else if (
				void 0 !== d &&
				((e = !0),
				r.isFunction(d) || (g = !0),
				j &&
					(g
						? (b.call(a, d), (b = null))
						: ((j = b),
							(b = function (a, b, c) {
								return j.call(r(a), c)
							}))),
				b)
			)
				for (; h < i; h++) b(a[h], c, g ? d : d.call(a[h], h, b(a[h], c)))
			return e ? a : j ? b.call(a) : i ? b(a[0], c) : f
		},
		U = function (a) {
			return 1 === a.nodeType || 9 === a.nodeType || !+a.nodeType
		}
	function V() {
		this.expando = r.expando + V.uid++
	}
	;(V.uid = 1),
		(V.prototype = {
			cache: function (a) {
				var b = a[this.expando]
				return (
					b ||
						((b = {}),
						U(a) &&
							(a.nodeType
								? (a[this.expando] = b)
								: Object.defineProperty(a, this.expando, { value: b, configurable: !0 }))),
					b
				)
			},
			set: function (a, b, c) {
				var d,
					e = this.cache(a)
				if ('string' == typeof b) e[r.camelCase(b)] = c
				else for (d in b) e[r.camelCase(d)] = b[d]
				return e
			},
			get: function (a, b) {
				return void 0 === b ? this.cache(a) : a[this.expando] && a[this.expando][r.camelCase(b)]
			},
			access: function (a, b, c) {
				return void 0 === b || (b && 'string' == typeof b && void 0 === c)
					? this.get(a, b)
					: (this.set(a, b, c), void 0 !== c ? c : b)
			},
			remove: function (a, b) {
				var c,
					d = a[this.expando]
				if (void 0 !== d) {
					if (void 0 !== b) {
						Array.isArray(b) ? (b = b.map(r.camelCase)) : ((b = r.camelCase(b)), (b = b in d ? [b] : b.match(L) || [])),
							(c = b.length)
						while (c--) delete d[b[c]]
					}
					;(void 0 === b || r.isEmptyObject(d)) && (a.nodeType ? (a[this.expando] = void 0) : delete a[this.expando])
				}
			},
			hasData: function (a) {
				var b = a[this.expando]
				return void 0 !== b && !r.isEmptyObject(b)
			},
		})
	var W = new V(),
		X = new V(),
		Y = /^(?:\{[\w\W]*\}|\[[\w\W]*\])$/,
		Z = /[A-Z]/g
	function $(a) {
		return 'true' === a || ('false' !== a && ('null' === a ? null : a === +a + '' ? +a : Y.test(a) ? JSON.parse(a) : a))
	}
	function _(a, b, c) {
		var d
		if (void 0 === c && 1 === a.nodeType)
			if (((d = 'data-' + b.replace(Z, '-$&').toLowerCase()), (c = a.getAttribute(d)), 'string' == typeof c)) {
				try {
					c = $(c)
				} catch (e) {}
				X.set(a, b, c)
			} else c = void 0
		return c
	}
	r.extend({
		hasData: function (a) {
			return X.hasData(a) || W.hasData(a)
		},
		data: function (a, b, c) {
			return X.access(a, b, c)
		},
		removeData: function (a, b) {
			X.remove(a, b)
		},
		_data: function (a, b, c) {
			return W.access(a, b, c)
		},
		_removeData: function (a, b) {
			W.remove(a, b)
		},
	}),
		r.fn.extend({
			data: function (a, b) {
				var c,
					d,
					e,
					f = this[0],
					g = f && f.attributes
				if (void 0 === a) {
					if (this.length && ((e = X.get(f)), 1 === f.nodeType && !W.get(f, 'hasDataAttrs'))) {
						c = g.length
						while (c--)
							g[c] && ((d = g[c].name), 0 === d.indexOf('data-') && ((d = r.camelCase(d.slice(5))), _(f, d, e[d])))
						W.set(f, 'hasDataAttrs', !0)
					}
					return e
				}
				return 'object' == typeof a
					? this.each(function () {
							X.set(this, a)
						})
					: T(
							this,
							function (b) {
								var c
								if (f && void 0 === b) {
									if (((c = X.get(f, a)), void 0 !== c)) return c
									if (((c = _(f, a)), void 0 !== c)) return c
								} else
									this.each(function () {
										X.set(this, a, b)
									})
							},
							null,
							b,
							arguments.length > 1,
							null,
							!0
						)
			},
			removeData: function (a) {
				return this.each(function () {
					X.remove(this, a)
				})
			},
		}),
		r.extend({
			queue: function (a, b, c) {
				var d
				if (a)
					return (
						(b = (b || 'fx') + 'queue'),
						(d = W.get(a, b)),
						c && (!d || Array.isArray(c) ? (d = W.access(a, b, r.makeArray(c))) : d.push(c)),
						d || []
					)
			},
			dequeue: function (a, b) {
				b = b || 'fx'
				var c = r.queue(a, b),
					d = c.length,
					e = c.shift(),
					f = r._queueHooks(a, b),
					g = function () {
						r.dequeue(a, b)
					}
				'inprogress' === e && ((e = c.shift()), d--),
					e && ('fx' === b && c.unshift('inprogress'), delete f.stop, e.call(a, g, f)),
					!d && f && f.empty.fire()
			},
			_queueHooks: function (a, b) {
				var c = b + 'queueHooks'
				return (
					W.get(a, c) ||
					W.access(a, c, {
						empty: r.Callbacks('once memory').add(function () {
							W.remove(a, [b + 'queue', c])
						}),
					})
				)
			},
		}),
		r.fn.extend({
			queue: function (a, b) {
				var c = 2
				return (
					'string' != typeof a && ((b = a), (a = 'fx'), c--),
					arguments.length < c
						? r.queue(this[0], a)
						: void 0 === b
							? this
							: this.each(function () {
									var c = r.queue(this, a, b)
									r._queueHooks(this, a), 'fx' === a && 'inprogress' !== c[0] && r.dequeue(this, a)
								})
				)
			},
			dequeue: function (a) {
				return this.each(function () {
					r.dequeue(this, a)
				})
			},
			clearQueue: function (a) {
				return this.queue(a || 'fx', [])
			},
			promise: function (a, b) {
				var c,
					d = 1,
					e = r.Deferred(),
					f = this,
					g = this.length,
					h = function () {
						--d || e.resolveWith(f, [f])
					}
				'string' != typeof a && ((b = a), (a = void 0)), (a = a || 'fx')
				while (g--) (c = W.get(f[g], a + 'queueHooks')), c && c.empty && (d++, c.empty.add(h))
				return h(), e.promise(b)
			},
		})
	var aa = /[+-]?(?:\d*\.|)\d+(?:[eE][+-]?\d+|)/.source,
		ba = new RegExp('^(?:([+-])=|)(' + aa + ')([a-z%]*)$', 'i'),
		ca = ['Top', 'Right', 'Bottom', 'Left'],
		da = function (a, b) {
			return (
				(a = b || a),
				'none' === a.style.display ||
					('' === a.style.display && r.contains(a.ownerDocument, a) && 'none' === r.css(a, 'display'))
			)
		},
		ea = function (a, b, c, d) {
			var e,
				f,
				g = {}
			for (f in b) (g[f] = a.style[f]), (a.style[f] = b[f])
			e = c.apply(a, d || [])
			for (f in b) a.style[f] = g[f]
			return e
		}
	function fa(a, b, c, d) {
		var e,
			f = 1,
			g = 20,
			h = d
				? function () {
						return d.cur()
					}
				: function () {
						return r.css(a, b, '')
					},
			i = h(),
			j = (c && c[3]) || (r.cssNumber[b] ? '' : 'px'),
			k = (r.cssNumber[b] || ('px' !== j && +i)) && ba.exec(r.css(a, b))
		if (k && k[3] !== j) {
			;(j = j || k[3]), (c = c || []), (k = +i || 1)
			do (f = f || '.5'), (k /= f), r.style(a, b, k + j)
			while (f !== (f = h() / i) && 1 !== f && --g)
		}
		return (
			c &&
				((k = +k || +i || 0),
				(e = c[1] ? k + (c[1] + 1) * c[2] : +c[2]),
				d && ((d.unit = j), (d.start = k), (d.end = e))),
			e
		)
	}
	var ga = {}
	function ha(a) {
		var b,
			c = a.ownerDocument,
			d = a.nodeName,
			e = ga[d]
		return e
			? e
			: ((b = c.body.appendChild(c.createElement(d))),
				(e = r.css(b, 'display')),
				b.parentNode.removeChild(b),
				'none' === e && (e = 'block'),
				(ga[d] = e),
				e)
	}
	function ia(a, b) {
		for (var c, d, e = [], f = 0, g = a.length; f < g; f++)
			(d = a[f]),
				d.style &&
					((c = d.style.display),
					b
						? ('none' === c && ((e[f] = W.get(d, 'display') || null), e[f] || (d.style.display = '')),
							'' === d.style.display && da(d) && (e[f] = ha(d)))
						: 'none' !== c && ((e[f] = 'none'), W.set(d, 'display', c)))
		for (f = 0; f < g; f++) null != e[f] && (a[f].style.display = e[f])
		return a
	}
	r.fn.extend({
		show: function () {
			return ia(this, !0)
		},
		hide: function () {
			return ia(this)
		},
		toggle: function (a) {
			return 'boolean' == typeof a
				? a
					? this.show()
					: this.hide()
				: this.each(function () {
						da(this) ? r(this).show() : r(this).hide()
					})
		},
	})
	var ja = /^(?:checkbox|radio)$/i,
		ka = /<([a-z][^\/\0>\x20\t\r\n\f]+)/i,
		la = /^$|\/(?:java|ecma)script/i,
		ma = {
			option: [1, "<select multiple='multiple'>", '</select>'],
			thead: [1, '<table>', '</table>'],
			col: [2, '<table><colgroup>', '</colgroup></table>'],
			tr: [2, '<table><tbody>', '</tbody></table>'],
			td: [3, '<table><tbody><tr>', '</tr></tbody></table>'],
			_default: [0, '', ''],
		}
	;(ma.optgroup = ma.option), (ma.tbody = ma.tfoot = ma.colgroup = ma.caption = ma.thead), (ma.th = ma.td)
	function na(a, b) {
		var c
		return (
			(c =
				'undefined' != typeof a.getElementsByTagName
					? a.getElementsByTagName(b || '*')
					: 'undefined' != typeof a.querySelectorAll
						? a.querySelectorAll(b || '*')
						: []),
			void 0 === b || (b && B(a, b)) ? r.merge([a], c) : c
		)
	}
	function oa(a, b) {
		for (var c = 0, d = a.length; c < d; c++) W.set(a[c], 'globalEval', !b || W.get(b[c], 'globalEval'))
	}
	var pa = /<|&#?\w+;/
	function qa(a, b, c, d, e) {
		for (var f, g, h, i, j, k, l = b.createDocumentFragment(), m = [], n = 0, o = a.length; n < o; n++)
			if (((f = a[n]), f || 0 === f))
				if ('object' === r.type(f)) r.merge(m, f.nodeType ? [f] : f)
				else if (pa.test(f)) {
					;(g = g || l.appendChild(b.createElement('div'))),
						(h = (ka.exec(f) || ['', ''])[1].toLowerCase()),
						(i = ma[h] || ma._default),
						(g.innerHTML = i[1] + r.htmlPrefilter(f) + i[2]),
						(k = i[0])
					while (k--) g = g.lastChild
					r.merge(m, g.childNodes), (g = l.firstChild), (g.textContent = '')
				} else m.push(b.createTextNode(f))
		;(l.textContent = ''), (n = 0)
		while ((f = m[n++]))
			if (d && r.inArray(f, d) > -1) e && e.push(f)
			else if (((j = r.contains(f.ownerDocument, f)), (g = na(l.appendChild(f), 'script')), j && oa(g), c)) {
				k = 0
				while ((f = g[k++])) la.test(f.type || '') && c.push(f)
			}
		return l
	}
	!(function () {
		var a = d.createDocumentFragment(),
			b = a.appendChild(d.createElement('div')),
			c = d.createElement('input')
		c.setAttribute('type', 'radio'),
			c.setAttribute('checked', 'checked'),
			c.setAttribute('name', 't'),
			b.appendChild(c),
			(o.checkClone = b.cloneNode(!0).cloneNode(!0).lastChild.checked),
			(b.innerHTML = '<textarea>x</textarea>'),
			(o.noCloneChecked = !!b.cloneNode(!0).lastChild.defaultValue)
	})()
	var ra = d.documentElement,
		sa = /^key/,
		ta = /^(?:mouse|pointer|contextmenu|drag|drop)|click/,
		ua = /^([^.]*)(?:\.(.+)|)/
	function va() {
		return !0
	}
	function wa() {
		return !1
	}
	function xa() {
		try {
			return d.activeElement
		} catch (a) {}
	}
	function ya(a, b, c, d, e, f) {
		var g, h
		if ('object' == typeof b) {
			'string' != typeof c && ((d = d || c), (c = void 0))
			for (h in b) ya(a, h, c, d, b[h], f)
			return a
		}
		if (
			(null == d && null == e
				? ((e = c), (d = c = void 0))
				: null == e && ('string' == typeof c ? ((e = d), (d = void 0)) : ((e = d), (d = c), (c = void 0))),
			e === !1)
		)
			e = wa
		else if (!e) return a
		return (
			1 === f &&
				((g = e),
				(e = function (a) {
					return r().off(a), g.apply(this, arguments)
				}),
				(e.guid = g.guid || (g.guid = r.guid++))),
			a.each(function () {
				r.event.add(this, b, e, d, c)
			})
		)
	}
	;(r.event = {
		global: {},
		add: function (a, b, c, d, e) {
			var f,
				g,
				h,
				i,
				j,
				k,
				l,
				m,
				n,
				o,
				p,
				q = W.get(a)
			if (q) {
				c.handler && ((f = c), (c = f.handler), (e = f.selector)),
					e && r.find.matchesSelector(ra, e),
					c.guid || (c.guid = r.guid++),
					(i = q.events) || (i = q.events = {}),
					(g = q.handle) ||
						(g = q.handle =
							function (b) {
								return 'undefined' != typeof r && r.event.triggered !== b.type
									? r.event.dispatch.apply(a, arguments)
									: void 0
							}),
					(b = (b || '').match(L) || ['']),
					(j = b.length)
				while (j--)
					(h = ua.exec(b[j]) || []),
						(n = p = h[1]),
						(o = (h[2] || '').split('.').sort()),
						n &&
							((l = r.event.special[n] || {}),
							(n = (e ? l.delegateType : l.bindType) || n),
							(l = r.event.special[n] || {}),
							(k = r.extend(
								{
									type: n,
									origType: p,
									data: d,
									handler: c,
									guid: c.guid,
									selector: e,
									needsContext: e && r.expr.match.needsContext.test(e),
									namespace: o.join('.'),
								},
								f
							)),
							(m = i[n]) ||
								((m = i[n] = []),
								(m.delegateCount = 0),
								(l.setup && l.setup.call(a, d, o, g) !== !1) || (a.addEventListener && a.addEventListener(n, g))),
							l.add && (l.add.call(a, k), k.handler.guid || (k.handler.guid = c.guid)),
							e ? m.splice(m.delegateCount++, 0, k) : m.push(k),
							(r.event.global[n] = !0))
			}
		},
		remove: function (a, b, c, d, e) {
			var f,
				g,
				h,
				i,
				j,
				k,
				l,
				m,
				n,
				o,
				p,
				q = W.hasData(a) && W.get(a)
			if (q && (i = q.events)) {
				;(b = (b || '').match(L) || ['']), (j = b.length)
				while (j--)
					if (((h = ua.exec(b[j]) || []), (n = p = h[1]), (o = (h[2] || '').split('.').sort()), n)) {
						;(l = r.event.special[n] || {}),
							(n = (d ? l.delegateType : l.bindType) || n),
							(m = i[n] || []),
							(h = h[2] && new RegExp('(^|\\.)' + o.join('\\.(?:.*\\.|)') + '(\\.|$)')),
							(g = f = m.length)
						while (f--)
							(k = m[f]),
								(!e && p !== k.origType) ||
									(c && c.guid !== k.guid) ||
									(h && !h.test(k.namespace)) ||
									(d && d !== k.selector && ('**' !== d || !k.selector)) ||
									(m.splice(f, 1), k.selector && m.delegateCount--, l.remove && l.remove.call(a, k))
						g &&
							!m.length &&
							((l.teardown && l.teardown.call(a, o, q.handle) !== !1) || r.removeEvent(a, n, q.handle), delete i[n])
					} else for (n in i) r.event.remove(a, n + b[j], c, d, !0)
				r.isEmptyObject(i) && W.remove(a, 'handle events')
			}
		},
		dispatch: function (a) {
			var b = r.event.fix(a),
				c,
				d,
				e,
				f,
				g,
				h,
				i = new Array(arguments.length),
				j = (W.get(this, 'events') || {})[b.type] || [],
				k = r.event.special[b.type] || {}
			for (i[0] = b, c = 1; c < arguments.length; c++) i[c] = arguments[c]
			if (((b.delegateTarget = this), !k.preDispatch || k.preDispatch.call(this, b) !== !1)) {
				;(h = r.event.handlers.call(this, b, j)), (c = 0)
				while ((f = h[c++]) && !b.isPropagationStopped()) {
					;(b.currentTarget = f.elem), (d = 0)
					while ((g = f.handlers[d++]) && !b.isImmediatePropagationStopped())
						(b.rnamespace && !b.rnamespace.test(g.namespace)) ||
							((b.handleObj = g),
							(b.data = g.data),
							(e = ((r.event.special[g.origType] || {}).handle || g.handler).apply(f.elem, i)),
							void 0 !== e && (b.result = e) === !1 && (b.preventDefault(), b.stopPropagation()))
				}
				return k.postDispatch && k.postDispatch.call(this, b), b.result
			}
		},
		handlers: function (a, b) {
			var c,
				d,
				e,
				f,
				g,
				h = [],
				i = b.delegateCount,
				j = a.target
			if (i && j.nodeType && !('click' === a.type && a.button >= 1))
				for (; j !== this; j = j.parentNode || this)
					if (1 === j.nodeType && ('click' !== a.type || j.disabled !== !0)) {
						for (f = [], g = {}, c = 0; c < i; c++)
							(d = b[c]),
								(e = d.selector + ' '),
								void 0 === g[e] &&
									(g[e] = d.needsContext ? r(e, this).index(j) > -1 : r.find(e, this, null, [j]).length),
								g[e] && f.push(d)
						f.length && h.push({ elem: j, handlers: f })
					}
			return (j = this), i < b.length && h.push({ elem: j, handlers: b.slice(i) }), h
		},
		addProp: function (a, b) {
			Object.defineProperty(r.Event.prototype, a, {
				enumerable: !0,
				configurable: !0,
				get: r.isFunction(b)
					? function () {
							if (this.originalEvent) return b(this.originalEvent)
						}
					: function () {
							if (this.originalEvent) return this.originalEvent[a]
						},
				set: function (b) {
					Object.defineProperty(this, a, { enumerable: !0, configurable: !0, writable: !0, value: b })
				},
			})
		},
		fix: function (a) {
			return a[r.expando] ? a : new r.Event(a)
		},
		special: {
			load: { noBubble: !0 },
			focus: {
				trigger: function () {
					if (this !== xa() && this.focus) return this.focus(), !1
				},
				delegateType: 'focusin',
			},
			blur: {
				trigger: function () {
					if (this === xa() && this.blur) return this.blur(), !1
				},
				delegateType: 'focusout',
			},
			click: {
				trigger: function () {
					if ('checkbox' === this.type && this.click && B(this, 'input')) return this.click(), !1
				},
				_default: function (a) {
					return B(a.target, 'a')
				},
			},
			beforeunload: {
				postDispatch: function (a) {
					void 0 !== a.result && a.originalEvent && (a.originalEvent.returnValue = a.result)
				},
			},
		},
	}),
		(r.removeEvent = function (a, b, c) {
			a.removeEventListener && a.removeEventListener(b, c)
		}),
		(r.Event = function (a, b) {
			return this instanceof r.Event
				? (a && a.type
						? ((this.originalEvent = a),
							(this.type = a.type),
							(this.isDefaultPrevented =
								a.defaultPrevented || (void 0 === a.defaultPrevented && a.returnValue === !1) ? va : wa),
							(this.target = a.target && 3 === a.target.nodeType ? a.target.parentNode : a.target),
							(this.currentTarget = a.currentTarget),
							(this.relatedTarget = a.relatedTarget))
						: (this.type = a),
					b && r.extend(this, b),
					(this.timeStamp = (a && a.timeStamp) || r.now()),
					void (this[r.expando] = !0))
				: new r.Event(a, b)
		}),
		(r.Event.prototype = {
			constructor: r.Event,
			isDefaultPrevented: wa,
			isPropagationStopped: wa,
			isImmediatePropagationStopped: wa,
			isSimulated: !1,
			preventDefault: function () {
				var a = this.originalEvent
				;(this.isDefaultPrevented = va), a && !this.isSimulated && a.preventDefault()
			},
			stopPropagation: function () {
				var a = this.originalEvent
				;(this.isPropagationStopped = va), a && !this.isSimulated && a.stopPropagation()
			},
			stopImmediatePropagation: function () {
				var a = this.originalEvent
				;(this.isImmediatePropagationStopped = va),
					a && !this.isSimulated && a.stopImmediatePropagation(),
					this.stopPropagation()
			},
		}),
		r.each(
			{
				altKey: !0,
				bubbles: !0,
				cancelable: !0,
				changedTouches: !0,
				ctrlKey: !0,
				detail: !0,
				eventPhase: !0,
				metaKey: !0,
				pageX: !0,
				pageY: !0,
				shiftKey: !0,
				view: !0,
				char: !0,
				charCode: !0,
				key: !0,
				keyCode: !0,
				button: !0,
				buttons: !0,
				clientX: !0,
				clientY: !0,
				offsetX: !0,
				offsetY: !0,
				pointerId: !0,
				pointerType: !0,
				screenX: !0,
				screenY: !0,
				targetTouches: !0,
				toElement: !0,
				touches: !0,
				which: function (a) {
					var b = a.button
					return null == a.which && sa.test(a.type)
						? null != a.charCode
							? a.charCode
							: a.keyCode
						: !a.which && void 0 !== b && ta.test(a.type)
							? 1 & b
								? 1
								: 2 & b
									? 3
									: 4 & b
										? 2
										: 0
							: a.which
				},
			},
			r.event.addProp
		),
		r.each(
			{ mouseenter: 'mouseover', mouseleave: 'mouseout', pointerenter: 'pointerover', pointerleave: 'pointerout' },
			function (a, b) {
				r.event.special[a] = {
					delegateType: b,
					bindType: b,
					handle: function (a) {
						var c,
							d = this,
							e = a.relatedTarget,
							f = a.handleObj
						return (
							(e && (e === d || r.contains(d, e))) ||
								((a.type = f.origType), (c = f.handler.apply(this, arguments)), (a.type = b)),
							c
						)
					},
				}
			}
		),
		r.fn.extend({
			on: function (a, b, c, d) {
				return ya(this, a, b, c, d)
			},
			one: function (a, b, c, d) {
				return ya(this, a, b, c, d, 1)
			},
			off: function (a, b, c) {
				var d, e
				if (a && a.preventDefault && a.handleObj)
					return (
						(d = a.handleObj),
						r(a.delegateTarget).off(d.namespace ? d.origType + '.' + d.namespace : d.origType, d.selector, d.handler),
						this
					)
				if ('object' == typeof a) {
					for (e in a) this.off(e, b, a[e])
					return this
				}
				return (
					(b !== !1 && 'function' != typeof b) || ((c = b), (b = void 0)),
					c === !1 && (c = wa),
					this.each(function () {
						r.event.remove(this, a, c, b)
					})
				)
			},
		})
	var za = /<(?!area|br|col|embed|hr|img|input|link|meta|param)(([a-z][^\/\0>\x20\t\r\n\f]*)[^>]*)\/>/gi,
		Aa = /<script|<style|<link/i,
		Ba = /checked\s*(?:[^=]|=\s*.checked.)/i,
		Ca = /^true\/(.*)/,
		Da = /^\s*<!(?:\[CDATA\[|--)|(?:\]\]|--)>\s*$/g
	function Ea(a, b) {
		return B(a, 'table') && B(11 !== b.nodeType ? b : b.firstChild, 'tr') ? r('>tbody', a)[0] || a : a
	}
	function Fa(a) {
		return (a.type = (null !== a.getAttribute('type')) + '/' + a.type), a
	}
	function Ga(a) {
		var b = Ca.exec(a.type)
		return b ? (a.type = b[1]) : a.removeAttribute('type'), a
	}
	function Ha(a, b) {
		var c, d, e, f, g, h, i, j
		if (1 === b.nodeType) {
			if (W.hasData(a) && ((f = W.access(a)), (g = W.set(b, f)), (j = f.events))) {
				delete g.handle, (g.events = {})
				for (e in j) for (c = 0, d = j[e].length; c < d; c++) r.event.add(b, e, j[e][c])
			}
			X.hasData(a) && ((h = X.access(a)), (i = r.extend({}, h)), X.set(b, i))
		}
	}
	function Ia(a, b) {
		var c = b.nodeName.toLowerCase()
		'input' === c && ja.test(a.type)
			? (b.checked = a.checked)
			: ('input' !== c && 'textarea' !== c) || (b.defaultValue = a.defaultValue)
	}
	function Ja(a, b, c, d) {
		b = g.apply([], b)
		var e,
			f,
			h,
			i,
			j,
			k,
			l = 0,
			m = a.length,
			n = m - 1,
			q = b[0],
			s = r.isFunction(q)
		if (s || (m > 1 && 'string' == typeof q && !o.checkClone && Ba.test(q)))
			return a.each(function (e) {
				var f = a.eq(e)
				s && (b[0] = q.call(this, e, f.html())), Ja(f, b, c, d)
			})
		if (
			m &&
			((e = qa(b, a[0].ownerDocument, !1, a, d)), (f = e.firstChild), 1 === e.childNodes.length && (e = f), f || d)
		) {
			for (h = r.map(na(e, 'script'), Fa), i = h.length; l < m; l++)
				(j = e), l !== n && ((j = r.clone(j, !0, !0)), i && r.merge(h, na(j, 'script'))), c.call(a[l], j, l)
			if (i)
				for (k = h[h.length - 1].ownerDocument, r.map(h, Ga), l = 0; l < i; l++)
					(j = h[l]),
						la.test(j.type || '') &&
							!W.access(j, 'globalEval') &&
							r.contains(k, j) &&
							(j.src ? r._evalUrl && r._evalUrl(j.src) : p(j.textContent.replace(Da, ''), k))
		}
		return a
	}
	function Ka(a, b, c) {
		for (var d, e = b ? r.filter(b, a) : a, f = 0; null != (d = e[f]); f++)
			c || 1 !== d.nodeType || r.cleanData(na(d)),
				d.parentNode && (c && r.contains(d.ownerDocument, d) && oa(na(d, 'script')), d.parentNode.removeChild(d))
		return a
	}
	r.extend({
		htmlPrefilter: function (a) {
			return a.replace(za, '<$1></$2>')
		},
		clone: function (a, b, c) {
			var d,
				e,
				f,
				g,
				h = a.cloneNode(!0),
				i = r.contains(a.ownerDocument, a)
			if (!(o.noCloneChecked || (1 !== a.nodeType && 11 !== a.nodeType) || r.isXMLDoc(a)))
				for (g = na(h), f = na(a), d = 0, e = f.length; d < e; d++) Ia(f[d], g[d])
			if (b)
				if (c) for (f = f || na(a), g = g || na(h), d = 0, e = f.length; d < e; d++) Ha(f[d], g[d])
				else Ha(a, h)
			return (g = na(h, 'script')), g.length > 0 && oa(g, !i && na(a, 'script')), h
		},
		cleanData: function (a) {
			for (var b, c, d, e = r.event.special, f = 0; void 0 !== (c = a[f]); f++)
				if (U(c)) {
					if ((b = c[W.expando])) {
						if (b.events) for (d in b.events) e[d] ? r.event.remove(c, d) : r.removeEvent(c, d, b.handle)
						c[W.expando] = void 0
					}
					c[X.expando] && (c[X.expando] = void 0)
				}
		},
	}),
		r.fn.extend({
			detach: function (a) {
				return Ka(this, a, !0)
			},
			remove: function (a) {
				return Ka(this, a)
			},
			text: function (a) {
				return T(
					this,
					function (a) {
						return void 0 === a
							? r.text(this)
							: this.empty().each(function () {
									;(1 !== this.nodeType && 11 !== this.nodeType && 9 !== this.nodeType) || (this.textContent = a)
								})
					},
					null,
					a,
					arguments.length
				)
			},
			append: function () {
				return Ja(this, arguments, function (a) {
					if (1 === this.nodeType || 11 === this.nodeType || 9 === this.nodeType) {
						var b = Ea(this, a)
						b.appendChild(a)
					}
				})
			},
			prepend: function () {
				return Ja(this, arguments, function (a) {
					if (1 === this.nodeType || 11 === this.nodeType || 9 === this.nodeType) {
						var b = Ea(this, a)
						b.insertBefore(a, b.firstChild)
					}
				})
			},
			before: function () {
				return Ja(this, arguments, function (a) {
					this.parentNode && this.parentNode.insertBefore(a, this)
				})
			},
			after: function () {
				return Ja(this, arguments, function (a) {
					this.parentNode && this.parentNode.insertBefore(a, this.nextSibling)
				})
			},
			empty: function () {
				for (var a, b = 0; null != (a = this[b]); b++)
					1 === a.nodeType && (r.cleanData(na(a, !1)), (a.textContent = ''))
				return this
			},
			clone: function (a, b) {
				return (
					(a = null != a && a),
					(b = null == b ? a : b),
					this.map(function () {
						return r.clone(this, a, b)
					})
				)
			},
			html: function (a) {
				return T(
					this,
					function (a) {
						var b = this[0] || {},
							c = 0,
							d = this.length
						if (void 0 === a && 1 === b.nodeType) return b.innerHTML
						if ('string' == typeof a && !Aa.test(a) && !ma[(ka.exec(a) || ['', ''])[1].toLowerCase()]) {
							a = r.htmlPrefilter(a)
							try {
								for (; c < d; c++) (b = this[c] || {}), 1 === b.nodeType && (r.cleanData(na(b, !1)), (b.innerHTML = a))
								b = 0
							} catch (e) {}
						}
						b && this.empty().append(a)
					},
					null,
					a,
					arguments.length
				)
			},
			replaceWith: function () {
				var a = []
				return Ja(
					this,
					arguments,
					function (b) {
						var c = this.parentNode
						r.inArray(this, a) < 0 && (r.cleanData(na(this)), c && c.replaceChild(b, this))
					},
					a
				)
			},
		}),
		r.each(
			{
				appendTo: 'append',
				prependTo: 'prepend',
				insertBefore: 'before',
				insertAfter: 'after',
				replaceAll: 'replaceWith',
			},
			function (a, b) {
				r.fn[a] = function (a) {
					for (var c, d = [], e = r(a), f = e.length - 1, g = 0; g <= f; g++)
						(c = g === f ? this : this.clone(!0)), r(e[g])[b](c), h.apply(d, c.get())
					return this.pushStack(d)
				}
			}
		)
	var La = /^margin/,
		Ma = new RegExp('^(' + aa + ')(?!px)[a-z%]+$', 'i'),
		Na = function (b) {
			var c = b.ownerDocument.defaultView
			return (c && c.opener) || (c = a), c.getComputedStyle(b)
		}
	!(function () {
		function b() {
			if (i) {
				;(i.style.cssText =
					'box-sizing:border-box;position:relative;display:block;margin:auto;border:1px;padding:1px;top:1%;width:50%'),
					(i.innerHTML = ''),
					ra.appendChild(h)
				var b = a.getComputedStyle(i)
				;(c = '1%' !== b.top),
					(g = '2px' === b.marginLeft),
					(e = '4px' === b.width),
					(i.style.marginRight = '50%'),
					(f = '4px' === b.marginRight),
					ra.removeChild(h),
					(i = null)
			}
		}
		var c,
			e,
			f,
			g,
			h = d.createElement('div'),
			i = d.createElement('div')
		i.style &&
			((i.style.backgroundClip = 'content-box'),
			(i.cloneNode(!0).style.backgroundClip = ''),
			(o.clearCloneStyle = 'content-box' === i.style.backgroundClip),
			(h.style.cssText = 'border:0;width:8px;height:0;top:0;left:-9999px;padding:0;margin-top:1px;position:absolute'),
			h.appendChild(i),
			r.extend(o, {
				pixelPosition: function () {
					return b(), c
				},
				boxSizingReliable: function () {
					return b(), e
				},
				pixelMarginRight: function () {
					return b(), f
				},
				reliableMarginLeft: function () {
					return b(), g
				},
			}))
	})()
	function Oa(a, b, c) {
		var d,
			e,
			f,
			g,
			h = a.style
		return (
			(c = c || Na(a)),
			c &&
				((g = c.getPropertyValue(b) || c[b]),
				'' !== g || r.contains(a.ownerDocument, a) || (g = r.style(a, b)),
				!o.pixelMarginRight() &&
					Ma.test(g) &&
					La.test(b) &&
					((d = h.width),
					(e = h.minWidth),
					(f = h.maxWidth),
					(h.minWidth = h.maxWidth = h.width = g),
					(g = c.width),
					(h.width = d),
					(h.minWidth = e),
					(h.maxWidth = f))),
			void 0 !== g ? g + '' : g
		)
	}
	function Pa(a, b) {
		return {
			get: function () {
				return a() ? void delete this.get : (this.get = b).apply(this, arguments)
			},
		}
	}
	var Qa = /^(none|table(?!-c[ea]).+)/,
		Ra = /^--/,
		Sa = { position: 'absolute', visibility: 'hidden', display: 'block' },
		Ta = { letterSpacing: '0', fontWeight: '400' },
		Ua = ['Webkit', 'Moz', 'ms'],
		Va = d.createElement('div').style
	function Wa(a) {
		if (a in Va) return a
		var b = a[0].toUpperCase() + a.slice(1),
			c = Ua.length
		while (c--) if (((a = Ua[c] + b), a in Va)) return a
	}
	function Xa(a) {
		var b = r.cssProps[a]
		return b || (b = r.cssProps[a] = Wa(a) || a), b
	}
	function Ya(a, b, c) {
		var d = ba.exec(b)
		return d ? Math.max(0, d[2] - (c || 0)) + (d[3] || 'px') : b
	}
	function Za(a, b, c, d, e) {
		var f,
			g = 0
		for (f = c === (d ? 'border' : 'content') ? 4 : 'width' === b ? 1 : 0; f < 4; f += 2)
			'margin' === c && (g += r.css(a, c + ca[f], !0, e)),
				d
					? ('content' === c && (g -= r.css(a, 'padding' + ca[f], !0, e)),
						'margin' !== c && (g -= r.css(a, 'border' + ca[f] + 'Width', !0, e)))
					: ((g += r.css(a, 'padding' + ca[f], !0, e)),
						'padding' !== c && (g += r.css(a, 'border' + ca[f] + 'Width', !0, e)))
		return g
	}
	function $a(a, b, c) {
		var d,
			e = Na(a),
			f = Oa(a, b, e),
			g = 'border-box' === r.css(a, 'boxSizing', !1, e)
		return Ma.test(f)
			? f
			: ((d = g && (o.boxSizingReliable() || f === a.style[b])),
				'auto' === f && (f = a['offset' + b[0].toUpperCase() + b.slice(1)]),
				(f = parseFloat(f) || 0),
				f + Za(a, b, c || (g ? 'border' : 'content'), d, e) + 'px')
	}
	r.extend({
		cssHooks: {
			opacity: {
				get: function (a, b) {
					if (b) {
						var c = Oa(a, 'opacity')
						return '' === c ? '1' : c
					}
				},
			},
		},
		cssNumber: {
			animationIterationCount: !0,
			columnCount: !0,
			fillOpacity: !0,
			flexGrow: !0,
			flexShrink: !0,
			fontWeight: !0,
			lineHeight: !0,
			opacity: !0,
			order: !0,
			orphans: !0,
			widows: !0,
			zIndex: !0,
			zoom: !0,
		},
		cssProps: { float: 'cssFloat' },
		style: function (a, b, c, d) {
			if (a && 3 !== a.nodeType && 8 !== a.nodeType && a.style) {
				var e,
					f,
					g,
					h = r.camelCase(b),
					i = Ra.test(b),
					j = a.style
				return (
					i || (b = Xa(h)),
					(g = r.cssHooks[b] || r.cssHooks[h]),
					void 0 === c
						? g && 'get' in g && void 0 !== (e = g.get(a, !1, d))
							? e
							: j[b]
						: ((f = typeof c),
							'string' === f && (e = ba.exec(c)) && e[1] && ((c = fa(a, b, e)), (f = 'number')),
							null != c &&
								c === c &&
								('number' === f && (c += (e && e[3]) || (r.cssNumber[h] ? '' : 'px')),
								o.clearCloneStyle || '' !== c || 0 !== b.indexOf('background') || (j[b] = 'inherit'),
								(g && 'set' in g && void 0 === (c = g.set(a, c, d))) || (i ? j.setProperty(b, c) : (j[b] = c))),
							void 0)
				)
			}
		},
		css: function (a, b, c, d) {
			var e,
				f,
				g,
				h = r.camelCase(b),
				i = Ra.test(b)
			return (
				i || (b = Xa(h)),
				(g = r.cssHooks[b] || r.cssHooks[h]),
				g && 'get' in g && (e = g.get(a, !0, c)),
				void 0 === e && (e = Oa(a, b, d)),
				'normal' === e && b in Ta && (e = Ta[b]),
				'' === c || c ? ((f = parseFloat(e)), c === !0 || isFinite(f) ? f || 0 : e) : e
			)
		},
	}),
		r.each(['height', 'width'], function (a, b) {
			r.cssHooks[b] = {
				get: function (a, c, d) {
					if (c)
						return !Qa.test(r.css(a, 'display')) || (a.getClientRects().length && a.getBoundingClientRect().width)
							? $a(a, b, d)
							: ea(a, Sa, function () {
									return $a(a, b, d)
								})
				},
				set: function (a, c, d) {
					var e,
						f = d && Na(a),
						g = d && Za(a, b, d, 'border-box' === r.css(a, 'boxSizing', !1, f), f)
					return g && (e = ba.exec(c)) && 'px' !== (e[3] || 'px') && ((a.style[b] = c), (c = r.css(a, b))), Ya(a, c, g)
				},
			}
		}),
		(r.cssHooks.marginLeft = Pa(o.reliableMarginLeft, function (a, b) {
			if (b)
				return (
					(parseFloat(Oa(a, 'marginLeft')) ||
						a.getBoundingClientRect().left -
							ea(a, { marginLeft: 0 }, function () {
								return a.getBoundingClientRect().left
							})) + 'px'
				)
		})),
		r.each({ margin: '', padding: '', border: 'Width' }, function (a, b) {
			;(r.cssHooks[a + b] = {
				expand: function (c) {
					for (var d = 0, e = {}, f = 'string' == typeof c ? c.split(' ') : [c]; d < 4; d++)
						e[a + ca[d] + b] = f[d] || f[d - 2] || f[0]
					return e
				},
			}),
				La.test(a) || (r.cssHooks[a + b].set = Ya)
		}),
		r.fn.extend({
			css: function (a, b) {
				return T(
					this,
					function (a, b, c) {
						var d,
							e,
							f = {},
							g = 0
						if (Array.isArray(b)) {
							for (d = Na(a), e = b.length; g < e; g++) f[b[g]] = r.css(a, b[g], !1, d)
							return f
						}
						return void 0 !== c ? r.style(a, b, c) : r.css(a, b)
					},
					a,
					b,
					arguments.length > 1
				)
			},
		})
	function _a(a, b, c, d, e) {
		return new _a.prototype.init(a, b, c, d, e)
	}
	;(r.Tween = _a),
		(_a.prototype = {
			constructor: _a,
			init: function (a, b, c, d, e, f) {
				;(this.elem = a),
					(this.prop = c),
					(this.easing = e || r.easing._default),
					(this.options = b),
					(this.start = this.now = this.cur()),
					(this.end = d),
					(this.unit = f || (r.cssNumber[c] ? '' : 'px'))
			},
			cur: function () {
				var a = _a.propHooks[this.prop]
				return a && a.get ? a.get(this) : _a.propHooks._default.get(this)
			},
			run: function (a) {
				var b,
					c = _a.propHooks[this.prop]
				return (
					this.options.duration
						? (this.pos = b = r.easing[this.easing](a, this.options.duration * a, 0, 1, this.options.duration))
						: (this.pos = b = a),
					(this.now = (this.end - this.start) * b + this.start),
					this.options.step && this.options.step.call(this.elem, this.now, this),
					c && c.set ? c.set(this) : _a.propHooks._default.set(this),
					this
				)
			},
		}),
		(_a.prototype.init.prototype = _a.prototype),
		(_a.propHooks = {
			_default: {
				get: function (a) {
					var b
					return 1 !== a.elem.nodeType || (null != a.elem[a.prop] && null == a.elem.style[a.prop])
						? a.elem[a.prop]
						: ((b = r.css(a.elem, a.prop, '')), b && 'auto' !== b ? b : 0)
				},
				set: function (a) {
					r.fx.step[a.prop]
						? r.fx.step[a.prop](a)
						: 1 !== a.elem.nodeType || (null == a.elem.style[r.cssProps[a.prop]] && !r.cssHooks[a.prop])
							? (a.elem[a.prop] = a.now)
							: r.style(a.elem, a.prop, a.now + a.unit)
				},
			},
		}),
		(_a.propHooks.scrollTop = _a.propHooks.scrollLeft =
			{
				set: function (a) {
					a.elem.nodeType && a.elem.parentNode && (a.elem[a.prop] = a.now)
				},
			}),
		(r.easing = {
			linear: function (a) {
				return a
			},
			swing: function (a) {
				return 0.5 - Math.cos(a * Math.PI) / 2
			},
			_default: 'swing',
		}),
		(r.fx = _a.prototype.init),
		(r.fx.step = {})
	var ab,
		bb,
		cb = /^(?:toggle|show|hide)$/,
		db = /queueHooks$/
	function eb() {
		bb &&
			(d.hidden === !1 && a.requestAnimationFrame ? a.requestAnimationFrame(eb) : a.setTimeout(eb, r.fx.interval),
			r.fx.tick())
	}
	function fb() {
		return (
			a.setTimeout(function () {
				ab = void 0
			}),
			(ab = r.now())
		)
	}
	function gb(a, b) {
		var c,
			d = 0,
			e = { height: a }
		for (b = b ? 1 : 0; d < 4; d += 2 - b) (c = ca[d]), (e['margin' + c] = e['padding' + c] = a)
		return b && (e.opacity = e.width = a), e
	}
	function hb(a, b, c) {
		for (var d, e = (kb.tweeners[b] || []).concat(kb.tweeners['*']), f = 0, g = e.length; f < g; f++)
			if ((d = e[f].call(c, b, a))) return d
	}
	function ib(a, b, c) {
		var d,
			e,
			f,
			g,
			h,
			i,
			j,
			k,
			l = 'width' in b || 'height' in b,
			m = this,
			n = {},
			o = a.style,
			p = a.nodeType && da(a),
			q = W.get(a, 'fxshow')
		c.queue ||
			((g = r._queueHooks(a, 'fx')),
			null == g.unqueued &&
				((g.unqueued = 0),
				(h = g.empty.fire),
				(g.empty.fire = function () {
					g.unqueued || h()
				})),
			g.unqueued++,
			m.always(function () {
				m.always(function () {
					g.unqueued--, r.queue(a, 'fx').length || g.empty.fire()
				})
			}))
		for (d in b)
			if (((e = b[d]), cb.test(e))) {
				if ((delete b[d], (f = f || 'toggle' === e), e === (p ? 'hide' : 'show'))) {
					if ('show' !== e || !q || void 0 === q[d]) continue
					p = !0
				}
				n[d] = (q && q[d]) || r.style(a, d)
			}
		if (((i = !r.isEmptyObject(b)), i || !r.isEmptyObject(n))) {
			l &&
				1 === a.nodeType &&
				((c.overflow = [o.overflow, o.overflowX, o.overflowY]),
				(j = q && q.display),
				null == j && (j = W.get(a, 'display')),
				(k = r.css(a, 'display')),
				'none' === k && (j ? (k = j) : (ia([a], !0), (j = a.style.display || j), (k = r.css(a, 'display')), ia([a]))),
				('inline' === k || ('inline-block' === k && null != j)) &&
					'none' === r.css(a, 'float') &&
					(i ||
						(m.done(function () {
							o.display = j
						}),
						null == j && ((k = o.display), (j = 'none' === k ? '' : k))),
					(o.display = 'inline-block'))),
				c.overflow &&
					((o.overflow = 'hidden'),
					m.always(function () {
						;(o.overflow = c.overflow[0]), (o.overflowX = c.overflow[1]), (o.overflowY = c.overflow[2])
					})),
				(i = !1)
			for (d in n)
				i ||
					(q ? 'hidden' in q && (p = q.hidden) : (q = W.access(a, 'fxshow', { display: j })),
					f && (q.hidden = !p),
					p && ia([a], !0),
					m.done(function () {
						p || ia([a]), W.remove(a, 'fxshow')
						for (d in n) r.style(a, d, n[d])
					})),
					(i = hb(p ? q[d] : 0, d, m)),
					d in q || ((q[d] = i.start), p && ((i.end = i.start), (i.start = 0)))
		}
	}
	function jb(a, b) {
		var c, d, e, f, g
		for (c in a)
			if (
				((d = r.camelCase(c)),
				(e = b[d]),
				(f = a[c]),
				Array.isArray(f) && ((e = f[1]), (f = a[c] = f[0])),
				c !== d && ((a[d] = f), delete a[c]),
				(g = r.cssHooks[d]),
				g && 'expand' in g)
			) {
				;(f = g.expand(f)), delete a[d]
				for (c in f) c in a || ((a[c] = f[c]), (b[c] = e))
			} else b[d] = e
	}
	function kb(a, b, c) {
		var d,
			e,
			f = 0,
			g = kb.prefilters.length,
			h = r.Deferred().always(function () {
				delete i.elem
			}),
			i = function () {
				if (e) return !1
				for (
					var b = ab || fb(),
						c = Math.max(0, j.startTime + j.duration - b),
						d = c / j.duration || 0,
						f = 1 - d,
						g = 0,
						i = j.tweens.length;
					g < i;
					g++
				)
					j.tweens[g].run(f)
				return h.notifyWith(a, [j, f, c]), f < 1 && i ? c : (i || h.notifyWith(a, [j, 1, 0]), h.resolveWith(a, [j]), !1)
			},
			j = h.promise({
				elem: a,
				props: r.extend({}, b),
				opts: r.extend(!0, { specialEasing: {}, easing: r.easing._default }, c),
				originalProperties: b,
				originalOptions: c,
				startTime: ab || fb(),
				duration: c.duration,
				tweens: [],
				createTween: function (b, c) {
					var d = r.Tween(a, j.opts, b, c, j.opts.specialEasing[b] || j.opts.easing)
					return j.tweens.push(d), d
				},
				stop: function (b) {
					var c = 0,
						d = b ? j.tweens.length : 0
					if (e) return this
					for (e = !0; c < d; c++) j.tweens[c].run(1)
					return b ? (h.notifyWith(a, [j, 1, 0]), h.resolveWith(a, [j, b])) : h.rejectWith(a, [j, b]), this
				},
			}),
			k = j.props
		for (jb(k, j.opts.specialEasing); f < g; f++)
			if ((d = kb.prefilters[f].call(j, a, k, j.opts)))
				return r.isFunction(d.stop) && (r._queueHooks(j.elem, j.opts.queue).stop = r.proxy(d.stop, d)), d
		return (
			r.map(k, hb, j),
			r.isFunction(j.opts.start) && j.opts.start.call(a, j),
			j.progress(j.opts.progress).done(j.opts.done, j.opts.complete).fail(j.opts.fail).always(j.opts.always),
			r.fx.timer(r.extend(i, { elem: a, anim: j, queue: j.opts.queue })),
			j
		)
	}
	;(r.Animation = r.extend(kb, {
		tweeners: {
			'*': [
				function (a, b) {
					var c = this.createTween(a, b)
					return fa(c.elem, a, ba.exec(b), c), c
				},
			],
		},
		tweener: function (a, b) {
			r.isFunction(a) ? ((b = a), (a = ['*'])) : (a = a.match(L))
			for (var c, d = 0, e = a.length; d < e; d++)
				(c = a[d]), (kb.tweeners[c] = kb.tweeners[c] || []), kb.tweeners[c].unshift(b)
		},
		prefilters: [ib],
		prefilter: function (a, b) {
			b ? kb.prefilters.unshift(a) : kb.prefilters.push(a)
		},
	})),
		(r.speed = function (a, b, c) {
			var d =
				a && 'object' == typeof a
					? r.extend({}, a)
					: {
							complete: c || (!c && b) || (r.isFunction(a) && a),
							duration: a,
							easing: (c && b) || (b && !r.isFunction(b) && b),
						}
			return (
				r.fx.off
					? (d.duration = 0)
					: 'number' != typeof d.duration &&
						(d.duration in r.fx.speeds ? (d.duration = r.fx.speeds[d.duration]) : (d.duration = r.fx.speeds._default)),
				(null != d.queue && d.queue !== !0) || (d.queue = 'fx'),
				(d.old = d.complete),
				(d.complete = function () {
					r.isFunction(d.old) && d.old.call(this), d.queue && r.dequeue(this, d.queue)
				}),
				d
			)
		}),
		r.fn.extend({
			fadeTo: function (a, b, c, d) {
				return this.filter(da).css('opacity', 0).show().end().animate({ opacity: b }, a, c, d)
			},
			animate: function (a, b, c, d) {
				var e = r.isEmptyObject(a),
					f = r.speed(b, c, d),
					g = function () {
						var b = kb(this, r.extend({}, a), f)
						;(e || W.get(this, 'finish')) && b.stop(!0)
					}
				return (g.finish = g), e || f.queue === !1 ? this.each(g) : this.queue(f.queue, g)
			},
			stop: function (a, b, c) {
				var d = function (a) {
					var b = a.stop
					delete a.stop, b(c)
				}
				return (
					'string' != typeof a && ((c = b), (b = a), (a = void 0)),
					b && a !== !1 && this.queue(a || 'fx', []),
					this.each(function () {
						var b = !0,
							e = null != a && a + 'queueHooks',
							f = r.timers,
							g = W.get(this)
						if (e) g[e] && g[e].stop && d(g[e])
						else for (e in g) g[e] && g[e].stop && db.test(e) && d(g[e])
						for (e = f.length; e--; )
							f[e].elem !== this || (null != a && f[e].queue !== a) || (f[e].anim.stop(c), (b = !1), f.splice(e, 1))
						;(!b && c) || r.dequeue(this, a)
					})
				)
			},
			finish: function (a) {
				return (
					a !== !1 && (a = a || 'fx'),
					this.each(function () {
						var b,
							c = W.get(this),
							d = c[a + 'queue'],
							e = c[a + 'queueHooks'],
							f = r.timers,
							g = d ? d.length : 0
						for (c.finish = !0, r.queue(this, a, []), e && e.stop && e.stop.call(this, !0), b = f.length; b--; )
							f[b].elem === this && f[b].queue === a && (f[b].anim.stop(!0), f.splice(b, 1))
						for (b = 0; b < g; b++) d[b] && d[b].finish && d[b].finish.call(this)
						delete c.finish
					})
				)
			},
		}),
		r.each(['toggle', 'show', 'hide'], function (a, b) {
			var c = r.fn[b]
			r.fn[b] = function (a, d, e) {
				return null == a || 'boolean' == typeof a ? c.apply(this, arguments) : this.animate(gb(b, !0), a, d, e)
			}
		}),
		r.each(
			{
				slideDown: gb('show'),
				slideUp: gb('hide'),
				slideToggle: gb('toggle'),
				fadeIn: { opacity: 'show' },
				fadeOut: { opacity: 'hide' },
				fadeToggle: { opacity: 'toggle' },
			},
			function (a, b) {
				r.fn[a] = function (a, c, d) {
					return this.animate(b, a, c, d)
				}
			}
		),
		(r.timers = []),
		(r.fx.tick = function () {
			var a,
				b = 0,
				c = r.timers
			for (ab = r.now(); b < c.length; b++) (a = c[b]), a() || c[b] !== a || c.splice(b--, 1)
			c.length || r.fx.stop(), (ab = void 0)
		}),
		(r.fx.timer = function (a) {
			r.timers.push(a), r.fx.start()
		}),
		(r.fx.interval = 13),
		(r.fx.start = function () {
			bb || ((bb = !0), eb())
		}),
		(r.fx.stop = function () {
			bb = null
		}),
		(r.fx.speeds = { slow: 600, fast: 200, _default: 400 }),
		(r.fn.delay = function (b, c) {
			return (
				(b = r.fx ? r.fx.speeds[b] || b : b),
				(c = c || 'fx'),
				this.queue(c, function (c, d) {
					var e = a.setTimeout(c, b)
					d.stop = function () {
						a.clearTimeout(e)
					}
				})
			)
		}),
		(function () {
			var a = d.createElement('input'),
				b = d.createElement('select'),
				c = b.appendChild(d.createElement('option'))
			;(a.type = 'checkbox'),
				(o.checkOn = '' !== a.value),
				(o.optSelected = c.selected),
				(a = d.createElement('input')),
				(a.value = 't'),
				(a.type = 'radio'),
				(o.radioValue = 't' === a.value)
		})()
	var lb,
		mb = r.expr.attrHandle
	r.fn.extend({
		attr: function (a, b) {
			return T(this, r.attr, a, b, arguments.length > 1)
		},
		removeAttr: function (a) {
			return this.each(function () {
				r.removeAttr(this, a)
			})
		},
	}),
		r.extend({
			attr: function (a, b, c) {
				var d,
					e,
					f = a.nodeType
				if (3 !== f && 8 !== f && 2 !== f)
					return 'undefined' == typeof a.getAttribute
						? r.prop(a, b, c)
						: ((1 === f && r.isXMLDoc(a)) ||
								(e = r.attrHooks[b.toLowerCase()] || (r.expr.match.bool.test(b) ? lb : void 0)),
							void 0 !== c
								? null === c
									? void r.removeAttr(a, b)
									: e && 'set' in e && void 0 !== (d = e.set(a, c, b))
										? d
										: (a.setAttribute(b, c + ''), c)
								: e && 'get' in e && null !== (d = e.get(a, b))
									? d
									: ((d = r.find.attr(a, b)), null == d ? void 0 : d))
			},
			attrHooks: {
				type: {
					set: function (a, b) {
						if (!o.radioValue && 'radio' === b && B(a, 'input')) {
							var c = a.value
							return a.setAttribute('type', b), c && (a.value = c), b
						}
					},
				},
			},
			removeAttr: function (a, b) {
				var c,
					d = 0,
					e = b && b.match(L)
				if (e && 1 === a.nodeType) while ((c = e[d++])) a.removeAttribute(c)
			},
		}),
		(lb = {
			set: function (a, b, c) {
				return b === !1 ? r.removeAttr(a, c) : a.setAttribute(c, c), c
			},
		}),
		r.each(r.expr.match.bool.source.match(/\w+/g), function (a, b) {
			var c = mb[b] || r.find.attr
			mb[b] = function (a, b, d) {
				var e,
					f,
					g = b.toLowerCase()
				return d || ((f = mb[g]), (mb[g] = e), (e = null != c(a, b, d) ? g : null), (mb[g] = f)), e
			}
		})
	var nb = /^(?:input|select|textarea|button)$/i,
		ob = /^(?:a|area)$/i
	r.fn.extend({
		prop: function (a, b) {
			return T(this, r.prop, a, b, arguments.length > 1)
		},
		removeProp: function (a) {
			return this.each(function () {
				delete this[r.propFix[a] || a]
			})
		},
	}),
		r.extend({
			prop: function (a, b, c) {
				var d,
					e,
					f = a.nodeType
				if (3 !== f && 8 !== f && 2 !== f)
					return (
						(1 === f && r.isXMLDoc(a)) || ((b = r.propFix[b] || b), (e = r.propHooks[b])),
						void 0 !== c
							? e && 'set' in e && void 0 !== (d = e.set(a, c, b))
								? d
								: (a[b] = c)
							: e && 'get' in e && null !== (d = e.get(a, b))
								? d
								: a[b]
					)
			},
			propHooks: {
				tabIndex: {
					get: function (a) {
						var b = r.find.attr(a, 'tabindex')
						return b ? parseInt(b, 10) : nb.test(a.nodeName) || (ob.test(a.nodeName) && a.href) ? 0 : -1
					},
				},
			},
			propFix: { for: 'htmlFor', class: 'className' },
		}),
		o.optSelected ||
			(r.propHooks.selected = {
				get: function (a) {
					var b = a.parentNode
					return b && b.parentNode && b.parentNode.selectedIndex, null
				},
				set: function (a) {
					var b = a.parentNode
					b && (b.selectedIndex, b.parentNode && b.parentNode.selectedIndex)
				},
			}),
		r.each(
			[
				'tabIndex',
				'readOnly',
				'maxLength',
				'cellSpacing',
				'cellPadding',
				'rowSpan',
				'colSpan',
				'useMap',
				'frameBorder',
				'contentEditable',
			],
			function () {
				r.propFix[this.toLowerCase()] = this
			}
		)
	function pb(a) {
		var b = a.match(L) || []
		return b.join(' ')
	}
	function qb(a) {
		return (a.getAttribute && a.getAttribute('class')) || ''
	}
	r.fn.extend({
		addClass: function (a) {
			var b,
				c,
				d,
				e,
				f,
				g,
				h,
				i = 0
			if (r.isFunction(a))
				return this.each(function (b) {
					r(this).addClass(a.call(this, b, qb(this)))
				})
			if ('string' == typeof a && a) {
				b = a.match(L) || []
				while ((c = this[i++]))
					if (((e = qb(c)), (d = 1 === c.nodeType && ' ' + pb(e) + ' '))) {
						g = 0
						while ((f = b[g++])) d.indexOf(' ' + f + ' ') < 0 && (d += f + ' ')
						;(h = pb(d)), e !== h && c.setAttribute('class', h)
					}
			}
			return this
		},
		removeClass: function (a) {
			var b,
				c,
				d,
				e,
				f,
				g,
				h,
				i = 0
			if (r.isFunction(a))
				return this.each(function (b) {
					r(this).removeClass(a.call(this, b, qb(this)))
				})
			if (!arguments.length) return this.attr('class', '')
			if ('string' == typeof a && a) {
				b = a.match(L) || []
				while ((c = this[i++]))
					if (((e = qb(c)), (d = 1 === c.nodeType && ' ' + pb(e) + ' '))) {
						g = 0
						while ((f = b[g++])) while (d.indexOf(' ' + f + ' ') > -1) d = d.replace(' ' + f + ' ', ' ')
						;(h = pb(d)), e !== h && c.setAttribute('class', h)
					}
			}
			return this
		},
		toggleClass: function (a, b) {
			var c = typeof a
			return 'boolean' == typeof b && 'string' === c
				? b
					? this.addClass(a)
					: this.removeClass(a)
				: r.isFunction(a)
					? this.each(function (c) {
							r(this).toggleClass(a.call(this, c, qb(this), b), b)
						})
					: this.each(function () {
							var b, d, e, f
							if ('string' === c) {
								;(d = 0), (e = r(this)), (f = a.match(L) || [])
								while ((b = f[d++])) e.hasClass(b) ? e.removeClass(b) : e.addClass(b)
							} else
								(void 0 !== a && 'boolean' !== c) ||
									((b = qb(this)),
									b && W.set(this, '__className__', b),
									this.setAttribute &&
										this.setAttribute('class', b || a === !1 ? '' : W.get(this, '__className__') || ''))
						})
		},
		hasClass: function (a) {
			var b,
				c,
				d = 0
			b = ' ' + a + ' '
			while ((c = this[d++])) if (1 === c.nodeType && (' ' + pb(qb(c)) + ' ').indexOf(b) > -1) return !0
			return !1
		},
	})
	var rb = /\r/g
	r.fn.extend({
		val: function (a) {
			var b,
				c,
				d,
				e = this[0]
			{
				if (arguments.length)
					return (
						(d = r.isFunction(a)),
						this.each(function (c) {
							var e
							1 === this.nodeType &&
								((e = d ? a.call(this, c, r(this).val()) : a),
								null == e
									? (e = '')
									: 'number' == typeof e
										? (e += '')
										: Array.isArray(e) &&
											(e = r.map(e, function (a) {
												return null == a ? '' : a + ''
											})),
								(b = r.valHooks[this.type] || r.valHooks[this.nodeName.toLowerCase()]),
								(b && 'set' in b && void 0 !== b.set(this, e, 'value')) || (this.value = e))
						})
					)
				if (e)
					return (
						(b = r.valHooks[e.type] || r.valHooks[e.nodeName.toLowerCase()]),
						b && 'get' in b && void 0 !== (c = b.get(e, 'value'))
							? c
							: ((c = e.value), 'string' == typeof c ? c.replace(rb, '') : null == c ? '' : c)
					)
			}
		},
	}),
		r.extend({
			valHooks: {
				option: {
					get: function (a) {
						var b = r.find.attr(a, 'value')
						return null != b ? b : pb(r.text(a))
					},
				},
				select: {
					get: function (a) {
						var b,
							c,
							d,
							e = a.options,
							f = a.selectedIndex,
							g = 'select-one' === a.type,
							h = g ? null : [],
							i = g ? f + 1 : e.length
						for (d = f < 0 ? i : g ? f : 0; d < i; d++)
							if (
								((c = e[d]),
								(c.selected || d === f) && !c.disabled && (!c.parentNode.disabled || !B(c.parentNode, 'optgroup')))
							) {
								if (((b = r(c).val()), g)) return b
								h.push(b)
							}
						return h
					},
					set: function (a, b) {
						var c,
							d,
							e = a.options,
							f = r.makeArray(b),
							g = e.length
						while (g--) (d = e[g]), (d.selected = r.inArray(r.valHooks.option.get(d), f) > -1) && (c = !0)
						return c || (a.selectedIndex = -1), f
					},
				},
			},
		}),
		r.each(['radio', 'checkbox'], function () {
			;(r.valHooks[this] = {
				set: function (a, b) {
					if (Array.isArray(b)) return (a.checked = r.inArray(r(a).val(), b) > -1)
				},
			}),
				o.checkOn ||
					(r.valHooks[this].get = function (a) {
						return null === a.getAttribute('value') ? 'on' : a.value
					})
		})
	var sb = /^(?:focusinfocus|focusoutblur)$/
	r.extend(r.event, {
		trigger: function (b, c, e, f) {
			var g,
				h,
				i,
				j,
				k,
				m,
				n,
				o = [e || d],
				p = l.call(b, 'type') ? b.type : b,
				q = l.call(b, 'namespace') ? b.namespace.split('.') : []
			if (
				((h = i = e = e || d),
				3 !== e.nodeType &&
					8 !== e.nodeType &&
					!sb.test(p + r.event.triggered) &&
					(p.indexOf('.') > -1 && ((q = p.split('.')), (p = q.shift()), q.sort()),
					(k = p.indexOf(':') < 0 && 'on' + p),
					(b = b[r.expando] ? b : new r.Event(p, 'object' == typeof b && b)),
					(b.isTrigger = f ? 2 : 3),
					(b.namespace = q.join('.')),
					(b.rnamespace = b.namespace ? new RegExp('(^|\\.)' + q.join('\\.(?:.*\\.|)') + '(\\.|$)') : null),
					(b.result = void 0),
					b.target || (b.target = e),
					(c = null == c ? [b] : r.makeArray(c, [b])),
					(n = r.event.special[p] || {}),
					f || !n.trigger || n.trigger.apply(e, c) !== !1))
			) {
				if (!f && !n.noBubble && !r.isWindow(e)) {
					for (j = n.delegateType || p, sb.test(j + p) || (h = h.parentNode); h; h = h.parentNode) o.push(h), (i = h)
					i === (e.ownerDocument || d) && o.push(i.defaultView || i.parentWindow || a)
				}
				g = 0
				while ((h = o[g++]) && !b.isPropagationStopped())
					(b.type = g > 1 ? j : n.bindType || p),
						(m = (W.get(h, 'events') || {})[b.type] && W.get(h, 'handle')),
						m && m.apply(h, c),
						(m = k && h[k]),
						m && m.apply && U(h) && ((b.result = m.apply(h, c)), b.result === !1 && b.preventDefault())
				return (
					(b.type = p),
					f ||
						b.isDefaultPrevented() ||
						(n._default && n._default.apply(o.pop(), c) !== !1) ||
						!U(e) ||
						(k &&
							r.isFunction(e[p]) &&
							!r.isWindow(e) &&
							((i = e[k]),
							i && (e[k] = null),
							(r.event.triggered = p),
							e[p](),
							(r.event.triggered = void 0),
							i && (e[k] = i))),
					b.result
				)
			}
		},
		simulate: function (a, b, c) {
			var d = r.extend(new r.Event(), c, { type: a, isSimulated: !0 })
			r.event.trigger(d, null, b)
		},
	}),
		r.fn.extend({
			trigger: function (a, b) {
				return this.each(function () {
					r.event.trigger(a, b, this)
				})
			},
			triggerHandler: function (a, b) {
				var c = this[0]
				if (c) return r.event.trigger(a, b, c, !0)
			},
		}),
		r.each(
			'blur focus focusin focusout resize scroll click dblclick mousedown mouseup mousemove mouseover mouseout mouseenter mouseleave change select submit keydown keypress keyup contextmenu'.split(
				' '
			),
			function (a, b) {
				r.fn[b] = function (a, c) {
					return arguments.length > 0 ? this.on(b, null, a, c) : this.trigger(b)
				}
			}
		),
		r.fn.extend({
			hover: function (a, b) {
				return this.mouseenter(a).mouseleave(b || a)
			},
		}),
		(o.focusin = 'onfocusin' in a),
		o.focusin ||
			r.each({ focus: 'focusin', blur: 'focusout' }, function (a, b) {
				var c = function (a) {
					r.event.simulate(b, a.target, r.event.fix(a))
				}
				r.event.special[b] = {
					setup: function () {
						var d = this.ownerDocument || this,
							e = W.access(d, b)
						e || d.addEventListener(a, c, !0), W.access(d, b, (e || 0) + 1)
					},
					teardown: function () {
						var d = this.ownerDocument || this,
							e = W.access(d, b) - 1
						e ? W.access(d, b, e) : (d.removeEventListener(a, c, !0), W.remove(d, b))
					},
				}
			})
	var tb = a.location,
		ub = r.now(),
		vb = /\?/
	r.parseXML = function (b) {
		var c
		if (!b || 'string' != typeof b) return null
		try {
			c = new a.DOMParser().parseFromString(b, 'text/xml')
		} catch (d) {
			c = void 0
		}
		return (c && !c.getElementsByTagName('parsererror').length) || r.error('Invalid XML: ' + b), c
	}
	var wb = /\[\]$/,
		xb = /\r?\n/g,
		yb = /^(?:submit|button|image|reset|file)$/i,
		zb = /^(?:input|select|textarea|keygen)/i
	function Ab(a, b, c, d) {
		var e
		if (Array.isArray(b))
			r.each(b, function (b, e) {
				c || wb.test(a) ? d(a, e) : Ab(a + '[' + ('object' == typeof e && null != e ? b : '') + ']', e, c, d)
			})
		else if (c || 'object' !== r.type(b)) d(a, b)
		else for (e in b) Ab(a + '[' + e + ']', b[e], c, d)
	}
	;(r.param = function (a, b) {
		var c,
			d = [],
			e = function (a, b) {
				var c = r.isFunction(b) ? b() : b
				d[d.length] = encodeURIComponent(a) + '=' + encodeURIComponent(null == c ? '' : c)
			}
		if (Array.isArray(a) || (a.jquery && !r.isPlainObject(a)))
			r.each(a, function () {
				e(this.name, this.value)
			})
		else for (c in a) Ab(c, a[c], b, e)
		return d.join('&')
	}),
		r.fn.extend({
			serialize: function () {
				return r.param(this.serializeArray())
			},
			serializeArray: function () {
				return this.map(function () {
					var a = r.prop(this, 'elements')
					return a ? r.makeArray(a) : this
				})
					.filter(function () {
						var a = this.type
						return (
							this.name &&
							!r(this).is(':disabled') &&
							zb.test(this.nodeName) &&
							!yb.test(a) &&
							(this.checked || !ja.test(a))
						)
					})
					.map(function (a, b) {
						var c = r(this).val()
						return null == c
							? null
							: Array.isArray(c)
								? r.map(c, function (a) {
										return { name: b.name, value: a.replace(xb, '\r\n') }
									})
								: { name: b.name, value: c.replace(xb, '\r\n') }
					})
					.get()
			},
		})
	var Bb = /%20/g,
		Cb = /#.*$/,
		Db = /([?&])_=[^&]*/,
		Eb = /^(.*?):[ \t]*([^\r\n]*)$/gm,
		Fb = /^(?:about|app|app-storage|.+-extension|file|res|widget):$/,
		Gb = /^(?:GET|HEAD)$/,
		Hb = /^\/\//,
		Ib = {},
		Jb = {},
		Kb = '*/'.concat('*'),
		Lb = d.createElement('a')
	Lb.href = tb.href
	function Mb(a) {
		return function (b, c) {
			'string' != typeof b && ((c = b), (b = '*'))
			var d,
				e = 0,
				f = b.toLowerCase().match(L) || []
			if (r.isFunction(c))
				while ((d = f[e++]))
					'+' === d[0] ? ((d = d.slice(1) || '*'), (a[d] = a[d] || []).unshift(c)) : (a[d] = a[d] || []).push(c)
		}
	}
	function Nb(a, b, c, d) {
		var e = {},
			f = a === Jb
		function g(h) {
			var i
			return (
				(e[h] = !0),
				r.each(a[h] || [], function (a, h) {
					var j = h(b, c, d)
					return 'string' != typeof j || f || e[j] ? (f ? !(i = j) : void 0) : (b.dataTypes.unshift(j), g(j), !1)
				}),
				i
			)
		}
		return g(b.dataTypes[0]) || (!e['*'] && g('*'))
	}
	function Ob(a, b) {
		var c,
			d,
			e = r.ajaxSettings.flatOptions || {}
		for (c in b) void 0 !== b[c] && ((e[c] ? a : d || (d = {}))[c] = b[c])
		return d && r.extend(!0, a, d), a
	}
	function Pb(a, b, c) {
		var d,
			e,
			f,
			g,
			h = a.contents,
			i = a.dataTypes
		while ('*' === i[0]) i.shift(), void 0 === d && (d = a.mimeType || b.getResponseHeader('Content-Type'))
		if (d)
			for (e in h)
				if (h[e] && h[e].test(d)) {
					i.unshift(e)
					break
				}
		if (i[0] in c) f = i[0]
		else {
			for (e in c) {
				if (!i[0] || a.converters[e + ' ' + i[0]]) {
					f = e
					break
				}
				g || (g = e)
			}
			f = f || g
		}
		if (f) return f !== i[0] && i.unshift(f), c[f]
	}
	function Qb(a, b, c, d) {
		var e,
			f,
			g,
			h,
			i,
			j = {},
			k = a.dataTypes.slice()
		if (k[1]) for (g in a.converters) j[g.toLowerCase()] = a.converters[g]
		f = k.shift()
		while (f)
			if (
				(a.responseFields[f] && (c[a.responseFields[f]] = b),
				!i && d && a.dataFilter && (b = a.dataFilter(b, a.dataType)),
				(i = f),
				(f = k.shift()))
			)
				if ('*' === f) f = i
				else if ('*' !== i && i !== f) {
					if (((g = j[i + ' ' + f] || j['* ' + f]), !g))
						for (e in j)
							if (((h = e.split(' ')), h[1] === f && (g = j[i + ' ' + h[0]] || j['* ' + h[0]]))) {
								g === !0 ? (g = j[e]) : j[e] !== !0 && ((f = h[0]), k.unshift(h[1]))
								break
							}
					if (g !== !0)
						if (g && a['throws']) b = g(b)
						else
							try {
								b = g(b)
							} catch (l) {
								return { state: 'parsererror', error: g ? l : 'No conversion from ' + i + ' to ' + f }
							}
				}
		return { state: 'success', data: b }
	}
	r.extend({
		active: 0,
		lastModified: {},
		etag: {},
		ajaxSettings: {
			url: tb.href,
			type: 'GET',
			isLocal: Fb.test(tb.protocol),
			global: !0,
			processData: !0,
			async: !0,
			contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
			accepts: {
				'*': Kb,
				text: 'text/plain',
				html: 'text/html',
				xml: 'application/xml, text/xml',
				json: 'application/json, text/javascript',
			},
			contents: { xml: /\bxml\b/, html: /\bhtml/, json: /\bjson\b/ },
			responseFields: { xml: 'responseXML', text: 'responseText', json: 'responseJSON' },
			converters: { '* text': String, 'text html': !0, 'text json': JSON.parse, 'text xml': r.parseXML },
			flatOptions: { url: !0, context: !0 },
		},
		ajaxSetup: function (a, b) {
			return b ? Ob(Ob(a, r.ajaxSettings), b) : Ob(r.ajaxSettings, a)
		},
		ajaxPrefilter: Mb(Ib),
		ajaxTransport: Mb(Jb),
		ajax: function (b, c) {
			'object' == typeof b && ((c = b), (b = void 0)), (c = c || {})
			var e,
				f,
				g,
				h,
				i,
				j,
				k,
				l,
				m,
				n,
				o = r.ajaxSetup({}, c),
				p = o.context || o,
				q = o.context && (p.nodeType || p.jquery) ? r(p) : r.event,
				s = r.Deferred(),
				t = r.Callbacks('once memory'),
				u = o.statusCode || {},
				v = {},
				w = {},
				x = 'canceled',
				y = {
					readyState: 0,
					getResponseHeader: function (a) {
						var b
						if (k) {
							if (!h) {
								h = {}
								while ((b = Eb.exec(g))) h[b[1].toLowerCase()] = b[2]
							}
							b = h[a.toLowerCase()]
						}
						return null == b ? null : b
					},
					getAllResponseHeaders: function () {
						return k ? g : null
					},
					setRequestHeader: function (a, b) {
						return null == k && ((a = w[a.toLowerCase()] = w[a.toLowerCase()] || a), (v[a] = b)), this
					},
					overrideMimeType: function (a) {
						return null == k && (o.mimeType = a), this
					},
					statusCode: function (a) {
						var b
						if (a)
							if (k) y.always(a[y.status])
							else for (b in a) u[b] = [u[b], a[b]]
						return this
					},
					abort: function (a) {
						var b = a || x
						return e && e.abort(b), A(0, b), this
					},
				}
			if (
				(s.promise(y),
				(o.url = ((b || o.url || tb.href) + '').replace(Hb, tb.protocol + '//')),
				(o.type = c.method || c.type || o.method || o.type),
				(o.dataTypes = (o.dataType || '*').toLowerCase().match(L) || ['']),
				null == o.crossDomain)
			) {
				j = d.createElement('a')
				try {
					;(j.href = o.url),
						(j.href = j.href),
						(o.crossDomain = Lb.protocol + '//' + Lb.host != j.protocol + '//' + j.host)
				} catch (z) {
					o.crossDomain = !0
				}
			}
			if (
				(o.data && o.processData && 'string' != typeof o.data && (o.data = r.param(o.data, o.traditional)),
				Nb(Ib, o, c, y),
				k)
			)
				return y
			;(l = r.event && o.global),
				l && 0 === r.active++ && r.event.trigger('ajaxStart'),
				(o.type = o.type.toUpperCase()),
				(o.hasContent = !Gb.test(o.type)),
				(f = o.url.replace(Cb, '')),
				o.hasContent
					? o.data &&
						o.processData &&
						0 === (o.contentType || '').indexOf('application/x-www-form-urlencoded') &&
						(o.data = o.data.replace(Bb, '+'))
					: ((n = o.url.slice(f.length)),
						o.data && ((f += (vb.test(f) ? '&' : '?') + o.data), delete o.data),
						o.cache === !1 && ((f = f.replace(Db, '$1')), (n = (vb.test(f) ? '&' : '?') + '_=' + ub++ + n)),
						(o.url = f + n)),
				o.ifModified &&
					(r.lastModified[f] && y.setRequestHeader('If-Modified-Since', r.lastModified[f]),
					r.etag[f] && y.setRequestHeader('If-None-Match', r.etag[f])),
				((o.data && o.hasContent && o.contentType !== !1) || c.contentType) &&
					y.setRequestHeader('Content-Type', o.contentType),
				y.setRequestHeader(
					'Accept',
					o.dataTypes[0] && o.accepts[o.dataTypes[0]]
						? o.accepts[o.dataTypes[0]] + ('*' !== o.dataTypes[0] ? ', ' + Kb + '; q=0.01' : '')
						: o.accepts['*']
				)
			for (m in o.headers) y.setRequestHeader(m, o.headers[m])
			if (o.beforeSend && (o.beforeSend.call(p, y, o) === !1 || k)) return y.abort()
			if (((x = 'abort'), t.add(o.complete), y.done(o.success), y.fail(o.error), (e = Nb(Jb, o, c, y)))) {
				if (((y.readyState = 1), l && q.trigger('ajaxSend', [y, o]), k)) return y
				o.async &&
					o.timeout > 0 &&
					(i = a.setTimeout(function () {
						y.abort('timeout')
					}, o.timeout))
				try {
					;(k = !1), e.send(v, A)
				} catch (z) {
					if (k) throw z
					A(-1, z)
				}
			} else A(-1, 'No Transport')
			function A(b, c, d, h) {
				var j,
					m,
					n,
					v,
					w,
					x = c
				k ||
					((k = !0),
					i && a.clearTimeout(i),
					(e = void 0),
					(g = h || ''),
					(y.readyState = b > 0 ? 4 : 0),
					(j = (b >= 200 && b < 300) || 304 === b),
					d && (v = Pb(o, y, d)),
					(v = Qb(o, v, y, j)),
					j
						? (o.ifModified &&
								((w = y.getResponseHeader('Last-Modified')),
								w && (r.lastModified[f] = w),
								(w = y.getResponseHeader('etag')),
								w && (r.etag[f] = w)),
							204 === b || 'HEAD' === o.type
								? (x = 'nocontent')
								: 304 === b
									? (x = 'notmodified')
									: ((x = v.state), (m = v.data), (n = v.error), (j = !n)))
						: ((n = x), (!b && x) || ((x = 'error'), b < 0 && (b = 0))),
					(y.status = b),
					(y.statusText = (c || x) + ''),
					j ? s.resolveWith(p, [m, x, y]) : s.rejectWith(p, [y, x, n]),
					y.statusCode(u),
					(u = void 0),
					l && q.trigger(j ? 'ajaxSuccess' : 'ajaxError', [y, o, j ? m : n]),
					t.fireWith(p, [y, x]),
					l && (q.trigger('ajaxComplete', [y, o]), --r.active || r.event.trigger('ajaxStop')))
			}
			return y
		},
		getJSON: function (a, b, c) {
			return r.get(a, b, c, 'json')
		},
		getScript: function (a, b) {
			return r.get(a, void 0, b, 'script')
		},
	}),
		r.each(['get', 'post'], function (a, b) {
			r[b] = function (a, c, d, e) {
				return (
					r.isFunction(c) && ((e = e || d), (d = c), (c = void 0)),
					r.ajax(r.extend({ url: a, type: b, dataType: e, data: c, success: d }, r.isPlainObject(a) && a))
				)
			}
		}),
		(r._evalUrl = function (a) {
			return r.ajax({ url: a, type: 'GET', dataType: 'script', cache: !0, async: !1, global: !1, throws: !0 })
		}),
		r.fn.extend({
			wrapAll: function (a) {
				var b
				return (
					this[0] &&
						(r.isFunction(a) && (a = a.call(this[0])),
						(b = r(a, this[0].ownerDocument).eq(0).clone(!0)),
						this[0].parentNode && b.insertBefore(this[0]),
						b
							.map(function () {
								var a = this
								while (a.firstElementChild) a = a.firstElementChild
								return a
							})
							.append(this)),
					this
				)
			},
			wrapInner: function (a) {
				return r.isFunction(a)
					? this.each(function (b) {
							r(this).wrapInner(a.call(this, b))
						})
					: this.each(function () {
							var b = r(this),
								c = b.contents()
							c.length ? c.wrapAll(a) : b.append(a)
						})
			},
			wrap: function (a) {
				var b = r.isFunction(a)
				return this.each(function (c) {
					r(this).wrapAll(b ? a.call(this, c) : a)
				})
			},
			unwrap: function (a) {
				return (
					this.parent(a)
						.not('body')
						.each(function () {
							r(this).replaceWith(this.childNodes)
						}),
					this
				)
			},
		}),
		(r.expr.pseudos.hidden = function (a) {
			return !r.expr.pseudos.visible(a)
		}),
		(r.expr.pseudos.visible = function (a) {
			return !!(a.offsetWidth || a.offsetHeight || a.getClientRects().length)
		}),
		(r.ajaxSettings.xhr = function () {
			try {
				return new a.XMLHttpRequest()
			} catch (b) {}
		})
	var Rb = { 0: 200, 1223: 204 },
		Sb = r.ajaxSettings.xhr()
	;(o.cors = !!Sb && 'withCredentials' in Sb),
		(o.ajax = Sb = !!Sb),
		r.ajaxTransport(function (b) {
			var c, d
			if (o.cors || (Sb && !b.crossDomain))
				return {
					send: function (e, f) {
						var g,
							h = b.xhr()
						if ((h.open(b.type, b.url, b.async, b.username, b.password), b.xhrFields))
							for (g in b.xhrFields) h[g] = b.xhrFields[g]
						b.mimeType && h.overrideMimeType && h.overrideMimeType(b.mimeType),
							b.crossDomain || e['X-Requested-With'] || (e['X-Requested-With'] = 'XMLHttpRequest')
						for (g in e) h.setRequestHeader(g, e[g])
						;(c = function (a) {
							return function () {
								c &&
									((c = d = h.onload = h.onerror = h.onabort = h.onreadystatechange = null),
									'abort' === a
										? h.abort()
										: 'error' === a
											? 'number' != typeof h.status
												? f(0, 'error')
												: f(h.status, h.statusText)
											: f(
													Rb[h.status] || h.status,
													h.statusText,
													'text' !== (h.responseType || 'text') || 'string' != typeof h.responseText
														? { binary: h.response }
														: { text: h.responseText },
													h.getAllResponseHeaders()
												))
							}
						}),
							(h.onload = c()),
							(d = h.onerror = c('error')),
							void 0 !== h.onabort
								? (h.onabort = d)
								: (h.onreadystatechange = function () {
										4 === h.readyState &&
											a.setTimeout(function () {
												c && d()
											})
									}),
							(c = c('abort'))
						try {
							h.send((b.hasContent && b.data) || null)
						} catch (i) {
							if (c) throw i
						}
					},
					abort: function () {
						c && c()
					},
				}
		}),
		r.ajaxPrefilter(function (a) {
			a.crossDomain && (a.contents.script = !1)
		}),
		r.ajaxSetup({
			accepts: { script: 'text/javascript, application/javascript, application/ecmascript, application/x-ecmascript' },
			contents: { script: /\b(?:java|ecma)script\b/ },
			converters: {
				'text script': function (a) {
					return r.globalEval(a), a
				},
			},
		}),
		r.ajaxPrefilter('script', function (a) {
			void 0 === a.cache && (a.cache = !1), a.crossDomain && (a.type = 'GET')
		}),
		r.ajaxTransport('script', function (a) {
			if (a.crossDomain) {
				var b, c
				return {
					send: function (e, f) {
						;(b = r('<script>')
							.prop({ charset: a.scriptCharset, src: a.url })
							.on(
								'load error',
								(c = function (a) {
									b.remove(), (c = null), a && f('error' === a.type ? 404 : 200, a.type)
								})
							)),
							d.head.appendChild(b[0])
					},
					abort: function () {
						c && c()
					},
				}
			}
		})
	var Tb = [],
		Ub = /(=)\?(?=&|$)|\?\?/
	r.ajaxSetup({
		jsonp: 'callback',
		jsonpCallback: function () {
			var a = Tb.pop() || r.expando + '_' + ub++
			return (this[a] = !0), a
		},
	}),
		r.ajaxPrefilter('json jsonp', function (b, c, d) {
			var e,
				f,
				g,
				h =
					b.jsonp !== !1 &&
					(Ub.test(b.url)
						? 'url'
						: 'string' == typeof b.data &&
							0 === (b.contentType || '').indexOf('application/x-www-form-urlencoded') &&
							Ub.test(b.data) &&
							'data')
			if (h || 'jsonp' === b.dataTypes[0])
				return (
					(e = b.jsonpCallback = r.isFunction(b.jsonpCallback) ? b.jsonpCallback() : b.jsonpCallback),
					h
						? (b[h] = b[h].replace(Ub, '$1' + e))
						: b.jsonp !== !1 && (b.url += (vb.test(b.url) ? '&' : '?') + b.jsonp + '=' + e),
					(b.converters['script json'] = function () {
						return g || r.error(e + ' was not called'), g[0]
					}),
					(b.dataTypes[0] = 'json'),
					(f = a[e]),
					(a[e] = function () {
						g = arguments
					}),
					d.always(function () {
						void 0 === f ? r(a).removeProp(e) : (a[e] = f),
							b[e] && ((b.jsonpCallback = c.jsonpCallback), Tb.push(e)),
							g && r.isFunction(f) && f(g[0]),
							(g = f = void 0)
					}),
					'script'
				)
		}),
		(o.createHTMLDocument = (function () {
			var a = d.implementation.createHTMLDocument('').body
			return (a.innerHTML = '<form></form><form></form>'), 2 === a.childNodes.length
		})()),
		(r.parseHTML = function (a, b, c) {
			if ('string' != typeof a) return []
			'boolean' == typeof b && ((c = b), (b = !1))
			var e, f, g
			return (
				b ||
					(o.createHTMLDocument
						? ((b = d.implementation.createHTMLDocument('')),
							(e = b.createElement('base')),
							(e.href = d.location.href),
							b.head.appendChild(e))
						: (b = d)),
				(f = C.exec(a)),
				(g = !c && []),
				f ? [b.createElement(f[1])] : ((f = qa([a], b, g)), g && g.length && r(g).remove(), r.merge([], f.childNodes))
			)
		}),
		(r.fn.load = function (a, b, c) {
			var d,
				e,
				f,
				g = this,
				h = a.indexOf(' ')
			return (
				h > -1 && ((d = pb(a.slice(h))), (a = a.slice(0, h))),
				r.isFunction(b) ? ((c = b), (b = void 0)) : b && 'object' == typeof b && (e = 'POST'),
				g.length > 0 &&
					r
						.ajax({ url: a, type: e || 'GET', dataType: 'html', data: b })
						.done(function (a) {
							;(f = arguments), g.html(d ? r('<div>').append(r.parseHTML(a)).find(d) : a)
						})
						.always(
							c &&
								function (a, b) {
									g.each(function () {
										c.apply(this, f || [a.responseText, b, a])
									})
								}
						),
				this
			)
		}),
		r.each(['ajaxStart', 'ajaxStop', 'ajaxComplete', 'ajaxError', 'ajaxSuccess', 'ajaxSend'], function (a, b) {
			r.fn[b] = function (a) {
				return this.on(b, a)
			}
		}),
		(r.expr.pseudos.animated = function (a) {
			return r.grep(r.timers, function (b) {
				return a === b.elem
			}).length
		}),
		(r.offset = {
			setOffset: function (a, b, c) {
				var d,
					e,
					f,
					g,
					h,
					i,
					j,
					k = r.css(a, 'position'),
					l = r(a),
					m = {}
				'static' === k && (a.style.position = 'relative'),
					(h = l.offset()),
					(f = r.css(a, 'top')),
					(i = r.css(a, 'left')),
					(j = ('absolute' === k || 'fixed' === k) && (f + i).indexOf('auto') > -1),
					j ? ((d = l.position()), (g = d.top), (e = d.left)) : ((g = parseFloat(f) || 0), (e = parseFloat(i) || 0)),
					r.isFunction(b) && (b = b.call(a, c, r.extend({}, h))),
					null != b.top && (m.top = b.top - h.top + g),
					null != b.left && (m.left = b.left - h.left + e),
					'using' in b ? b.using.call(a, m) : l.css(m)
			},
		}),
		r.fn.extend({
			offset: function (a) {
				if (arguments.length)
					return void 0 === a
						? this
						: this.each(function (b) {
								r.offset.setOffset(this, a, b)
							})
				var b,
					c,
					d,
					e,
					f = this[0]
				if (f)
					return f.getClientRects().length
						? ((d = f.getBoundingClientRect()),
							(b = f.ownerDocument),
							(c = b.documentElement),
							(e = b.defaultView),
							{ top: d.top + e.pageYOffset - c.clientTop, left: d.left + e.pageXOffset - c.clientLeft })
						: { top: 0, left: 0 }
			},
			position: function () {
				if (this[0]) {
					var a,
						b,
						c = this[0],
						d = { top: 0, left: 0 }
					return (
						'fixed' === r.css(c, 'position')
							? (b = c.getBoundingClientRect())
							: ((a = this.offsetParent()),
								(b = this.offset()),
								B(a[0], 'html') || (d = a.offset()),
								(d = {
									top: d.top + r.css(a[0], 'borderTopWidth', !0),
									left: d.left + r.css(a[0], 'borderLeftWidth', !0),
								})),
						{ top: b.top - d.top - r.css(c, 'marginTop', !0), left: b.left - d.left - r.css(c, 'marginLeft', !0) }
					)
				}
			},
			offsetParent: function () {
				return this.map(function () {
					var a = this.offsetParent
					while (a && 'static' === r.css(a, 'position')) a = a.offsetParent
					return a || ra
				})
			},
		}),
		r.each({ scrollLeft: 'pageXOffset', scrollTop: 'pageYOffset' }, function (a, b) {
			var c = 'pageYOffset' === b
			r.fn[a] = function (d) {
				return T(
					this,
					function (a, d, e) {
						var f
						return (
							r.isWindow(a) ? (f = a) : 9 === a.nodeType && (f = a.defaultView),
							void 0 === e
								? f
									? f[b]
									: a[d]
								: void (f ? f.scrollTo(c ? f.pageXOffset : e, c ? e : f.pageYOffset) : (a[d] = e))
						)
					},
					a,
					d,
					arguments.length
				)
			}
		}),
		r.each(['top', 'left'], function (a, b) {
			r.cssHooks[b] = Pa(o.pixelPosition, function (a, c) {
				if (c) return (c = Oa(a, b)), Ma.test(c) ? r(a).position()[b] + 'px' : c
			})
		}),
		r.each({ Height: 'height', Width: 'width' }, function (a, b) {
			r.each({ padding: 'inner' + a, content: b, '': 'outer' + a }, function (c, d) {
				r.fn[d] = function (e, f) {
					var g = arguments.length && (c || 'boolean' != typeof e),
						h = c || (e === !0 || f === !0 ? 'margin' : 'border')
					return T(
						this,
						function (b, c, e) {
							var f
							return r.isWindow(b)
								? 0 === d.indexOf('outer')
									? b['inner' + a]
									: b.document.documentElement['client' + a]
								: 9 === b.nodeType
									? ((f = b.documentElement),
										Math.max(
											b.body['scroll' + a],
											f['scroll' + a],
											b.body['offset' + a],
											f['offset' + a],
											f['client' + a]
										))
									: void 0 === e
										? r.css(b, c, h)
										: r.style(b, c, e, h)
						},
						b,
						g ? e : void 0,
						g
					)
				}
			})
		}),
		r.fn.extend({
			bind: function (a, b, c) {
				return this.on(a, null, b, c)
			},
			unbind: function (a, b) {
				return this.off(a, null, b)
			},
			delegate: function (a, b, c, d) {
				return this.on(b, a, c, d)
			},
			undelegate: function (a, b, c) {
				return 1 === arguments.length ? this.off(a, '**') : this.off(b, a || '**', c)
			},
		}),
		(r.holdReady = function (a) {
			a ? r.readyWait++ : r.ready(!0)
		}),
		(r.isArray = Array.isArray),
		(r.parseJSON = JSON.parse),
		(r.nodeName = B),
		'function' == typeof define &&
			define.amd &&
			define('jquery', [], function () {
				return r
			})
	var Vb = a.jQuery,
		Wb = a.$
	return (
		(r.noConflict = function (b) {
			return a.$ === r && (a.$ = Wb), b && a.jQuery === r && (a.jQuery = Vb), r
		}),
		b || (a.jQuery = a.$ = r),
		r
	)
})

/*!
 DataTables 1.10.16
 ©2008-2017 SpryMedia Ltd - datatables.net/license
*/
;(function (h) {
	'function' === typeof define && define.amd
		? define(['jquery'], function (E) {
				return h(E, window, document)
			})
		: 'object' === typeof exports
			? (module.exports = function (E, G) {
					E || (E = window)
					G || (G = 'undefined' !== typeof window ? require('jquery') : require('jquery')(E))
					return h(G, E, E.document)
				})
			: h(jQuery, window, document)
})(function (h, E, G, k) {
	function X(a) {
		var b,
			c,
			d = {}
		h.each(a, function (e) {
			if ((b = e.match(/^([^A-Z]+?)([A-Z])/)) && -1 !== 'a aa ai ao as b fn i m o s '.indexOf(b[1] + ' '))
				(c = e.replace(b[0], b[2].toLowerCase())), (d[c] = e), 'o' === b[1] && X(a[e])
		})
		a._hungarianMap = d
	}
	function I(a, b, c) {
		a._hungarianMap || X(a)
		var d
		h.each(b, function (e) {
			d = a._hungarianMap[e]
			if (d !== k && (c || b[d] === k))
				'o' === d.charAt(0) ? (b[d] || (b[d] = {}), h.extend(!0, b[d], b[e]), I(a[d], b[d], c)) : (b[d] = b[e])
		})
	}
	function Ca(a) {
		var b = m.defaults.oLanguage,
			c = a.sZeroRecords
		!a.sEmptyTable && c && 'No data available in table' === b.sEmptyTable && F(a, a, 'sZeroRecords', 'sEmptyTable')
		!a.sLoadingRecords && c && 'Loading...' === b.sLoadingRecords && F(a, a, 'sZeroRecords', 'sLoadingRecords')
		a.sInfoThousands && (a.sThousands = a.sInfoThousands)
		;(a = a.sDecimal) && cb(a)
	}
	function db(a) {
		A(a, 'ordering', 'bSort')
		A(a, 'orderMulti', 'bSortMulti')
		A(a, 'orderClasses', 'bSortClasses')
		A(a, 'orderCellsTop', 'bSortCellsTop')
		A(a, 'order', 'aaSorting')
		A(a, 'orderFixed', 'aaSortingFixed')
		A(a, 'paging', 'bPaginate')
		A(a, 'pagingType', 'sPaginationType')
		A(a, 'pageLength', 'iDisplayLength')
		A(a, 'searching', 'bFilter')
		'boolean' === typeof a.sScrollX && (a.sScrollX = a.sScrollX ? '100%' : '')
		'boolean' === typeof a.scrollX && (a.scrollX = a.scrollX ? '100%' : '')
		if ((a = a.aoSearchCols)) for (var b = 0, c = a.length; b < c; b++) a[b] && I(m.models.oSearch, a[b])
	}
	function eb(a) {
		A(a, 'orderable', 'bSortable')
		A(a, 'orderData', 'aDataSort')
		A(a, 'orderSequence', 'asSorting')
		A(a, 'orderDataType', 'sortDataType')
		var b = a.aDataSort
		'number' === typeof b && !h.isArray(b) && (a.aDataSort = [b])
	}
	function fb(a) {
		if (!m.__browser) {
			var b = {}
			m.__browser = b
			var c = h('<div/>')
					.css({ position: 'fixed', top: 0, left: -1 * h(E).scrollLeft(), height: 1, width: 1, overflow: 'hidden' })
					.append(
						h('<div/>')
							.css({ position: 'absolute', top: 1, left: 1, width: 100, overflow: 'scroll' })
							.append(h('<div/>').css({ width: '100%', height: 10 }))
					)
					.appendTo('body'),
				d = c.children(),
				e = d.children()
			b.barWidth = d[0].offsetWidth - d[0].clientWidth
			b.bScrollOversize = 100 === e[0].offsetWidth && 100 !== d[0].clientWidth
			b.bScrollbarLeft = 1 !== Math.round(e.offset().left)
			b.bBounding = c[0].getBoundingClientRect().width ? !0 : !1
			c.remove()
		}
		h.extend(a.oBrowser, m.__browser)
		a.oScroll.iBarWidth = m.__browser.barWidth
	}
	function gb(a, b, c, d, e, f) {
		var g,
			j = !1
		c !== k && ((g = c), (j = !0))
		for (; d !== e; ) a.hasOwnProperty(d) && ((g = j ? b(g, a[d], d, a) : a[d]), (j = !0), (d += f))
		return g
	}
	function Da(a, b) {
		var c = m.defaults.column,
			d = a.aoColumns.length,
			c = h.extend({}, m.models.oColumn, c, {
				nTh: b ? b : G.createElement('th'),
				sTitle: c.sTitle ? c.sTitle : b ? b.innerHTML : '',
				aDataSort: c.aDataSort ? c.aDataSort : [d],
				mData: c.mData ? c.mData : d,
				idx: d,
			})
		a.aoColumns.push(c)
		c = a.aoPreSearchCols
		c[d] = h.extend({}, m.models.oSearch, c[d])
		ja(a, d, h(b).data())
	}
	function ja(a, b, c) {
		var b = a.aoColumns[b],
			d = a.oClasses,
			e = h(b.nTh)
		if (!b.sWidthOrig) {
			b.sWidthOrig = e.attr('width') || null
			var f = (e.attr('style') || '').match(/width:\s*(\d+[pxem%]+)/)
			f && (b.sWidthOrig = f[1])
		}
		c !== k &&
			null !== c &&
			(eb(c),
			I(m.defaults.column, c),
			c.mDataProp !== k && !c.mData && (c.mData = c.mDataProp),
			c.sType && (b._sManualType = c.sType),
			c.className && !c.sClass && (c.sClass = c.className),
			c.sClass && e.addClass(c.sClass),
			h.extend(b, c),
			F(b, c, 'sWidth', 'sWidthOrig'),
			c.iDataSort !== k && (b.aDataSort = [c.iDataSort]),
			F(b, c, 'aDataSort'))
		var g = b.mData,
			j = Q(g),
			i = b.mRender ? Q(b.mRender) : null,
			c = function (a) {
				return 'string' === typeof a && -1 !== a.indexOf('@')
			}
		b._bAttrSrc = h.isPlainObject(g) && (c(g.sort) || c(g.type) || c(g.filter))
		b._setter = null
		b.fnGetData = function (a, b, c) {
			var d = j(a, b, k, c)
			return i && b ? i(d, b, a, c) : d
		}
		b.fnSetData = function (a, b, c) {
			return R(g)(a, b, c)
		}
		'number' !== typeof g && (a._rowReadObject = !0)
		a.oFeatures.bSort || ((b.bSortable = !1), e.addClass(d.sSortableNone))
		a = -1 !== h.inArray('asc', b.asSorting)
		c = -1 !== h.inArray('desc', b.asSorting)
		!b.bSortable || (!a && !c)
			? ((b.sSortingClass = d.sSortableNone), (b.sSortingClassJUI = ''))
			: a && !c
				? ((b.sSortingClass = d.sSortableAsc), (b.sSortingClassJUI = d.sSortJUIAscAllowed))
				: !a && c
					? ((b.sSortingClass = d.sSortableDesc), (b.sSortingClassJUI = d.sSortJUIDescAllowed))
					: ((b.sSortingClass = d.sSortable), (b.sSortingClassJUI = d.sSortJUI))
	}
	function Y(a) {
		if (!1 !== a.oFeatures.bAutoWidth) {
			var b = a.aoColumns
			Ea(a)
			for (var c = 0, d = b.length; c < d; c++) b[c].nTh.style.width = b[c].sWidth
		}
		b = a.oScroll
		;('' !== b.sY || '' !== b.sX) && ka(a)
		r(a, null, 'column-sizing', [a])
	}
	function Z(a, b) {
		var c = la(a, 'bVisible')
		return 'number' === typeof c[b] ? c[b] : null
	}
	function $(a, b) {
		var c = la(a, 'bVisible'),
			c = h.inArray(b, c)
		return -1 !== c ? c : null
	}
	function aa(a) {
		var b = 0
		h.each(a.aoColumns, function (a, d) {
			d.bVisible && 'none' !== h(d.nTh).css('display') && b++
		})
		return b
	}
	function la(a, b) {
		var c = []
		h.map(a.aoColumns, function (a, e) {
			a[b] && c.push(e)
		})
		return c
	}
	function Fa(a) {
		var b = a.aoColumns,
			c = a.aoData,
			d = m.ext.type.detect,
			e,
			f,
			g,
			j,
			i,
			h,
			l,
			q,
			t
		e = 0
		for (f = b.length; e < f; e++)
			if (((l = b[e]), (t = []), !l.sType && l._sManualType)) l.sType = l._sManualType
			else if (!l.sType) {
				g = 0
				for (j = d.length; g < j; g++) {
					i = 0
					for (h = c.length; i < h; i++) {
						t[i] === k && (t[i] = B(a, i, e, 'type'))
						q = d[g](t[i], a)
						if (!q && g !== d.length - 1) break
						if ('html' === q) break
					}
					if (q) {
						l.sType = q
						break
					}
				}
				l.sType || (l.sType = 'string')
			}
	}
	function hb(a, b, c, d) {
		var e,
			f,
			g,
			j,
			i,
			n,
			l = a.aoColumns
		if (b)
			for (e = b.length - 1; 0 <= e; e--) {
				n = b[e]
				var q = n.targets !== k ? n.targets : n.aTargets
				h.isArray(q) || (q = [q])
				f = 0
				for (g = q.length; f < g; f++)
					if ('number' === typeof q[f] && 0 <= q[f]) {
						for (; l.length <= q[f]; ) Da(a)
						d(q[f], n)
					} else if ('number' === typeof q[f] && 0 > q[f]) d(l.length + q[f], n)
					else if ('string' === typeof q[f]) {
						j = 0
						for (i = l.length; j < i; j++) ('_all' == q[f] || h(l[j].nTh).hasClass(q[f])) && d(j, n)
					}
			}
		if (c) {
			e = 0
			for (a = c.length; e < a; e++) d(e, c[e])
		}
	}
	function M(a, b, c, d) {
		var e = a.aoData.length,
			f = h.extend(!0, {}, m.models.oRow, { src: c ? 'dom' : 'data', idx: e })
		f._aData = b
		a.aoData.push(f)
		for (var g = a.aoColumns, j = 0, i = g.length; j < i; j++) g[j].sType = null
		a.aiDisplayMaster.push(e)
		b = a.rowIdFn(b)
		b !== k && (a.aIds[b] = f)
		;(c || !a.oFeatures.bDeferRender) && Ga(a, e, c, d)
		return e
	}
	function ma(a, b) {
		var c
		b instanceof h || (b = h(b))
		return b.map(function (b, e) {
			c = Ha(a, e)
			return M(a, c.data, e, c.cells)
		})
	}
	function B(a, b, c, d) {
		var e = a.iDraw,
			f = a.aoColumns[c],
			g = a.aoData[b]._aData,
			j = f.sDefaultContent,
			i = f.fnGetData(g, d, { settings: a, row: b, col: c })
		if (i === k)
			return (
				a.iDrawError != e &&
					null === j &&
					(J(
						a,
						0,
						'Requested unknown parameter ' +
							('function' == typeof f.mData ? '{function}' : "'" + f.mData + "'") +
							' for row ' +
							b +
							', column ' +
							c,
						4
					),
					(a.iDrawError = e)),
				j
			)
		if ((i === g || null === i) && null !== j && d !== k) i = j
		else if ('function' === typeof i) return i.call(g)
		return null === i && 'display' == d ? '' : i
	}
	function ib(a, b, c, d) {
		a.aoColumns[c].fnSetData(a.aoData[b]._aData, d, { settings: a, row: b, col: c })
	}
	function Ia(a) {
		return h.map(a.match(/(\\.|[^\.])+/g) || [''], function (a) {
			return a.replace(/\\\./g, '.')
		})
	}
	function Q(a) {
		if (h.isPlainObject(a)) {
			var b = {}
			h.each(a, function (a, c) {
				c && (b[a] = Q(c))
			})
			return function (a, c, f, g) {
				var j = b[c] || b._
				return j !== k ? j(a, c, f, g) : a
			}
		}
		if (null === a)
			return function (a) {
				return a
			}
		if ('function' === typeof a)
			return function (b, c, f, g) {
				return a(b, c, f, g)
			}
		if ('string' === typeof a && (-1 !== a.indexOf('.') || -1 !== a.indexOf('[') || -1 !== a.indexOf('('))) {
			var c = function (a, b, f) {
				var g, j
				if ('' !== f) {
					j = Ia(f)
					for (var i = 0, n = j.length; i < n; i++) {
						f = j[i].match(ba)
						g = j[i].match(U)
						if (f) {
							j[i] = j[i].replace(ba, '')
							'' !== j[i] && (a = a[j[i]])
							g = []
							j.splice(0, i + 1)
							j = j.join('.')
							if (h.isArray(a)) {
								i = 0
								for (n = a.length; i < n; i++) g.push(c(a[i], b, j))
							}
							a = f[0].substring(1, f[0].length - 1)
							a = '' === a ? g : g.join(a)
							break
						} else if (g) {
							j[i] = j[i].replace(U, '')
							a = a[j[i]]()
							continue
						}
						if (null === a || a[j[i]] === k) return k
						a = a[j[i]]
					}
				}
				return a
			}
			return function (b, e) {
				return c(b, e, a)
			}
		}
		return function (b) {
			return b[a]
		}
	}
	function R(a) {
		if (h.isPlainObject(a)) return R(a._)
		if (null === a) return function () {}
		if ('function' === typeof a)
			return function (b, d, e) {
				a(b, 'set', d, e)
			}
		if ('string' === typeof a && (-1 !== a.indexOf('.') || -1 !== a.indexOf('[') || -1 !== a.indexOf('('))) {
			var b = function (a, d, e) {
				var e = Ia(e),
					f
				f = e[e.length - 1]
				for (var g, j, i = 0, n = e.length - 1; i < n; i++) {
					g = e[i].match(ba)
					j = e[i].match(U)
					if (g) {
						e[i] = e[i].replace(ba, '')
						a[e[i]] = []
						f = e.slice()
						f.splice(0, i + 1)
						g = f.join('.')
						if (h.isArray(d)) {
							j = 0
							for (n = d.length; j < n; j++) (f = {}), b(f, d[j], g), a[e[i]].push(f)
						} else a[e[i]] = d
						return
					}
					j && ((e[i] = e[i].replace(U, '')), (a = a[e[i]](d)))
					if (null === a[e[i]] || a[e[i]] === k) a[e[i]] = {}
					a = a[e[i]]
				}
				if (f.match(U)) a[f.replace(U, '')](d)
				else a[f.replace(ba, '')] = d
			}
			return function (c, d) {
				return b(c, d, a)
			}
		}
		return function (b, d) {
			b[a] = d
		}
	}
	function Ja(a) {
		return D(a.aoData, '_aData')
	}
	function na(a) {
		a.aoData.length = 0
		a.aiDisplayMaster.length = 0
		a.aiDisplay.length = 0
		a.aIds = {}
	}
	function oa(a, b, c) {
		for (var d = -1, e = 0, f = a.length; e < f; e++) a[e] == b ? (d = e) : a[e] > b && a[e]--
		;-1 != d && c === k && a.splice(d, 1)
	}
	function ca(a, b, c, d) {
		var e = a.aoData[b],
			f,
			g = function (c, d) {
				for (; c.childNodes.length; ) c.removeChild(c.firstChild)
				c.innerHTML = B(a, b, d, 'display')
			}
		if ('dom' === c || ((!c || 'auto' === c) && 'dom' === e.src)) e._aData = Ha(a, e, d, d === k ? k : e._aData).data
		else {
			var j = e.anCells
			if (j)
				if (d !== k) g(j[d], d)
				else {
					c = 0
					for (f = j.length; c < f; c++) g(j[c], c)
				}
		}
		e._aSortData = null
		e._aFilterData = null
		g = a.aoColumns
		if (d !== k) g[d].sType = null
		else {
			c = 0
			for (f = g.length; c < f; c++) g[c].sType = null
			Ka(a, e)
		}
	}
	function Ha(a, b, c, d) {
		var e = [],
			f = b.firstChild,
			g,
			j,
			i = 0,
			n,
			l = a.aoColumns,
			q = a._rowReadObject,
			d = d !== k ? d : q ? {} : [],
			t = function (a, b) {
				if ('string' === typeof a) {
					var c = a.indexOf('@')
					;-1 !== c && ((c = a.substring(c + 1)), R(a)(d, b.getAttribute(c)))
				}
			},
			m = function (a) {
				if (c === k || c === i)
					(j = l[i]),
						(n = h.trim(a.innerHTML)),
						j && j._bAttrSrc
							? (R(j.mData._)(d, n), t(j.mData.sort, a), t(j.mData.type, a), t(j.mData.filter, a))
							: q
								? (j._setter || (j._setter = R(j.mData)), j._setter(d, n))
								: (d[i] = n)
				i++
			}
		if (f)
			for (; f; ) {
				g = f.nodeName.toUpperCase()
				if ('TD' == g || 'TH' == g) m(f), e.push(f)
				f = f.nextSibling
			}
		else {
			e = b.anCells
			f = 0
			for (g = e.length; f < g; f++) m(e[f])
		}
		if ((b = b.firstChild ? b : b.nTr)) (b = b.getAttribute('id')) && R(a.rowId)(d, b)
		return { data: d, cells: e }
	}
	function Ga(a, b, c, d) {
		var e = a.aoData[b],
			f = e._aData,
			g = [],
			j,
			i,
			n,
			l,
			q
		if (null === e.nTr) {
			j = c || G.createElement('tr')
			e.nTr = j
			e.anCells = g
			j._DT_RowIndex = b
			Ka(a, e)
			l = 0
			for (q = a.aoColumns.length; l < q; l++) {
				n = a.aoColumns[l]
				i = c ? d[l] : G.createElement(n.sCellType)
				i._DT_CellIndex = { row: b, column: l }
				g.push(i)
				if ((!c || n.mRender || n.mData !== l) && (!h.isPlainObject(n.mData) || n.mData._ !== l + '.display'))
					i.innerHTML = B(a, b, l, 'display')
				n.sClass && (i.className += ' ' + n.sClass)
				n.bVisible && !c ? j.appendChild(i) : !n.bVisible && c && i.parentNode.removeChild(i)
				n.fnCreatedCell && n.fnCreatedCell.call(a.oInstance, i, B(a, b, l), f, b, l)
			}
			r(a, 'aoRowCreatedCallback', null, [j, f, b])
		}
		e.nTr.setAttribute('role', 'row')
	}
	function Ka(a, b) {
		var c = b.nTr,
			d = b._aData
		if (c) {
			var e = a.rowIdFn(d)
			e && (c.id = e)
			d.DT_RowClass &&
				((e = d.DT_RowClass.split(' ')),
				(b.__rowc = b.__rowc ? qa(b.__rowc.concat(e)) : e),
				h(c).removeClass(b.__rowc.join(' ')).addClass(d.DT_RowClass))
			d.DT_RowAttr && h(c).attr(d.DT_RowAttr)
			d.DT_RowData && h(c).data(d.DT_RowData)
		}
	}
	function jb(a) {
		var b,
			c,
			d,
			e,
			f,
			g = a.nTHead,
			j = a.nTFoot,
			i = 0 === h('th, td', g).length,
			n = a.oClasses,
			l = a.aoColumns
		i && (e = h('<tr/>').appendTo(g))
		b = 0
		for (c = l.length; b < c; b++)
			(f = l[b]),
				(d = h(f.nTh).addClass(f.sClass)),
				i && d.appendTo(e),
				a.oFeatures.bSort &&
					(d.addClass(f.sSortingClass),
					!1 !== f.bSortable && (d.attr('tabindex', a.iTabIndex).attr('aria-controls', a.sTableId), La(a, f.nTh, b))),
				f.sTitle != d[0].innerHTML && d.html(f.sTitle),
				Ma(a, 'header')(a, d, f, n)
		i && da(a.aoHeader, g)
		h(g).find('>tr').attr('role', 'row')
		h(g).find('>tr>th, >tr>td').addClass(n.sHeaderTH)
		h(j).find('>tr>th, >tr>td').addClass(n.sFooterTH)
		if (null !== j) {
			a = a.aoFooter[0]
			b = 0
			for (c = a.length; b < c; b++) (f = l[b]), (f.nTf = a[b].cell), f.sClass && h(f.nTf).addClass(f.sClass)
		}
	}
	function ea(a, b, c) {
		var d,
			e,
			f,
			g = [],
			j = [],
			i = a.aoColumns.length,
			n
		if (b) {
			c === k && (c = !1)
			d = 0
			for (e = b.length; d < e; d++) {
				g[d] = b[d].slice()
				g[d].nTr = b[d].nTr
				for (f = i - 1; 0 <= f; f--) !a.aoColumns[f].bVisible && !c && g[d].splice(f, 1)
				j.push([])
			}
			d = 0
			for (e = g.length; d < e; d++) {
				if ((a = g[d].nTr)) for (; (f = a.firstChild); ) a.removeChild(f)
				f = 0
				for (b = g[d].length; f < b; f++)
					if (((n = i = 1), j[d][f] === k)) {
						a.appendChild(g[d][f].cell)
						for (j[d][f] = 1; g[d + i] !== k && g[d][f].cell == g[d + i][f].cell; ) (j[d + i][f] = 1), i++
						for (; g[d][f + n] !== k && g[d][f].cell == g[d][f + n].cell; ) {
							for (c = 0; c < i; c++) j[d + c][f + n] = 1
							n++
						}
						h(g[d][f].cell).attr('rowspan', i).attr('colspan', n)
					}
			}
		}
	}
	function N(a) {
		var b = r(a, 'aoPreDrawCallback', 'preDraw', [a])
		if (-1 !== h.inArray(!1, b)) C(a, !1)
		else {
			var b = [],
				c = 0,
				d = a.asStripeClasses,
				e = d.length,
				f = a.oLanguage,
				g = a.iInitDisplayStart,
				j = 'ssp' == y(a),
				i = a.aiDisplay
			a.bDrawing = !0
			g !== k &&
				-1 !== g &&
				((a._iDisplayStart = j ? g : g >= a.fnRecordsDisplay() ? 0 : g), (a.iInitDisplayStart = -1))
			var g = a._iDisplayStart,
				n = a.fnDisplayEnd()
			if (a.bDeferLoading) (a.bDeferLoading = !1), a.iDraw++, C(a, !1)
			else if (j) {
				if (!a.bDestroying && !kb(a)) return
			} else a.iDraw++
			if (0 !== i.length) {
				f = j ? a.aoData.length : n
				for (j = j ? 0 : g; j < f; j++) {
					var l = i[j],
						q = a.aoData[l]
					null === q.nTr && Ga(a, l)
					l = q.nTr
					if (0 !== e) {
						var t = d[c % e]
						q._sRowStripe != t && (h(l).removeClass(q._sRowStripe).addClass(t), (q._sRowStripe = t))
					}
					r(a, 'aoRowCallback', null, [l, q._aData, c, j])
					b.push(l)
					c++
				}
			} else
				(c = f.sZeroRecords),
					1 == a.iDraw && 'ajax' == y(a)
						? (c = f.sLoadingRecords)
						: f.sEmptyTable && 0 === a.fnRecordsTotal() && (c = f.sEmptyTable),
					(b[0] = h('<tr/>', { class: e ? d[0] : '' }).append(
						h('<td />', { valign: 'top', colSpan: aa(a), class: a.oClasses.sRowEmpty }).html(c)
					)[0])
			r(a, 'aoHeaderCallback', 'header', [h(a.nTHead).children('tr')[0], Ja(a), g, n, i])
			r(a, 'aoFooterCallback', 'footer', [h(a.nTFoot).children('tr')[0], Ja(a), g, n, i])
			d = h(a.nTBody)
			d.children().detach()
			d.append(h(b))
			r(a, 'aoDrawCallback', 'draw', [a])
			a.bSorted = !1
			a.bFiltered = !1
			a.bDrawing = !1
		}
	}
	function S(a, b) {
		var c = a.oFeatures,
			d = c.bFilter
		c.bSort && lb(a)
		d ? fa(a, a.oPreviousSearch) : (a.aiDisplay = a.aiDisplayMaster.slice())
		!0 !== b && (a._iDisplayStart = 0)
		a._drawHold = b
		N(a)
		a._drawHold = !1
	}
	function mb(a) {
		var b = a.oClasses,
			c = h(a.nTable),
			c = h('<div/>').insertBefore(c),
			d = a.oFeatures,
			e = h('<div/>', { id: a.sTableId + '_wrapper', class: b.sWrapper + (a.nTFoot ? '' : ' ' + b.sNoFooter) })
		a.nHolding = c[0]
		a.nTableWrapper = e[0]
		a.nTableReinsertBefore = a.nTable.nextSibling
		for (var f = a.sDom.split(''), g, j, i, n, l, q, k = 0; k < f.length; k++) {
			g = null
			j = f[k]
			if ('<' == j) {
				i = h('<div/>')[0]
				n = f[k + 1]
				if ("'" == n || '"' == n) {
					l = ''
					for (q = 2; f[k + q] != n; ) (l += f[k + q]), q++
					'H' == l ? (l = b.sJUIHeader) : 'F' == l && (l = b.sJUIFooter)
					;-1 != l.indexOf('.')
						? ((n = l.split('.')), (i.id = n[0].substr(1, n[0].length - 1)), (i.className = n[1]))
						: '#' == l.charAt(0)
							? (i.id = l.substr(1, l.length - 1))
							: (i.className = l)
					k += q
				}
				e.append(i)
				e = h(i)
			} else if ('>' == j) e = e.parent()
			else if ('l' == j && d.bPaginate && d.bLengthChange) g = nb(a)
			else if ('f' == j && d.bFilter) g = ob(a)
			else if ('r' == j && d.bProcessing) g = pb(a)
			else if ('t' == j) g = qb(a)
			else if ('i' == j && d.bInfo) g = rb(a)
			else if ('p' == j && d.bPaginate) g = sb(a)
			else if (0 !== m.ext.feature.length) {
				i = m.ext.feature
				q = 0
				for (n = i.length; q < n; q++)
					if (j == i[q].cFeature) {
						g = i[q].fnInit(a)
						break
					}
			}
			g && ((i = a.aanFeatures), i[j] || (i[j] = []), i[j].push(g), e.append(g))
		}
		c.replaceWith(e)
		a.nHolding = null
	}
	function da(a, b) {
		var c = h(b).children('tr'),
			d,
			e,
			f,
			g,
			j,
			i,
			n,
			l,
			q,
			k
		a.splice(0, a.length)
		f = 0
		for (i = c.length; f < i; f++) a.push([])
		f = 0
		for (i = c.length; f < i; f++) {
			d = c[f]
			for (e = d.firstChild; e; ) {
				if ('TD' == e.nodeName.toUpperCase() || 'TH' == e.nodeName.toUpperCase()) {
					l = 1 * e.getAttribute('colspan')
					q = 1 * e.getAttribute('rowspan')
					l = !l || 0 === l || 1 === l ? 1 : l
					q = !q || 0 === q || 1 === q ? 1 : q
					g = 0
					for (j = a[f]; j[g]; ) g++
					n = g
					k = 1 === l ? !0 : !1
					for (j = 0; j < l; j++) for (g = 0; g < q; g++) (a[f + g][n + j] = { cell: e, unique: k }), (a[f + g].nTr = d)
				}
				e = e.nextSibling
			}
		}
	}
	function ra(a, b, c) {
		var d = []
		c || ((c = a.aoHeader), b && ((c = []), da(c, b)))
		for (var b = 0, e = c.length; b < e; b++)
			for (var f = 0, g = c[b].length; f < g; f++)
				if (c[b][f].unique && (!d[f] || !a.bSortCellsTop)) d[f] = c[b][f].cell
		return d
	}
	function sa(a, b, c) {
		r(a, 'aoServerParams', 'serverParams', [b])
		if (b && h.isArray(b)) {
			var d = {},
				e = /(.*?)\[\]$/
			h.each(b, function (a, b) {
				var c = b.name.match(e)
				c ? ((c = c[0]), d[c] || (d[c] = []), d[c].push(b.value)) : (d[b.name] = b.value)
			})
			b = d
		}
		var f,
			g = a.ajax,
			j = a.oInstance,
			i = function (b) {
				r(a, null, 'xhr', [a, b, a.jqXHR])
				c(b)
			}
		if (h.isPlainObject(g) && g.data) {
			f = g.data
			var n = h.isFunction(f) ? f(b, a) : f,
				b = h.isFunction(f) && n ? n : h.extend(!0, b, n)
			delete g.data
		}
		n = {
			data: b,
			success: function (b) {
				var c = b.error || b.sError
				c && J(a, 0, c)
				a.json = b
				i(b)
			},
			dataType: 'json',
			cache: !1,
			type: a.sServerMethod,
			error: function (b, c) {
				var d = r(a, null, 'xhr', [a, null, a.jqXHR])
				;-1 === h.inArray(!0, d) &&
					('parsererror' == c ? J(a, 0, 'Invalid JSON response', 1) : 4 === b.readyState && J(a, 0, 'Ajax error', 7))
				C(a, !1)
			},
		}
		a.oAjaxData = b
		r(a, null, 'preXhr', [a, b])
		a.fnServerData
			? a.fnServerData.call(
					j,
					a.sAjaxSource,
					h.map(b, function (a, b) {
						return { name: b, value: a }
					}),
					i,
					a
				)
			: a.sAjaxSource || 'string' === typeof g
				? (a.jqXHR = h.ajax(h.extend(n, { url: g || a.sAjaxSource })))
				: h.isFunction(g)
					? (a.jqXHR = g.call(j, b, i, a))
					: ((a.jqXHR = h.ajax(h.extend(n, g))), (g.data = f))
	}
	function kb(a) {
		return a.bAjaxDataGet
			? (a.iDraw++,
				C(a, !0),
				sa(a, tb(a), function (b) {
					ub(a, b)
				}),
				!1)
			: !0
	}
	function tb(a) {
		var b = a.aoColumns,
			c = b.length,
			d = a.oFeatures,
			e = a.oPreviousSearch,
			f = a.aoPreSearchCols,
			g,
			j = [],
			i,
			n,
			l,
			k = V(a)
		g = a._iDisplayStart
		i = !1 !== d.bPaginate ? a._iDisplayLength : -1
		var t = function (a, b) {
			j.push({ name: a, value: b })
		}
		t('sEcho', a.iDraw)
		t('iColumns', c)
		t('sColumns', D(b, 'sName').join(','))
		t('iDisplayStart', g)
		t('iDisplayLength', i)
		var pa = {
			draw: a.iDraw,
			columns: [],
			order: [],
			start: g,
			length: i,
			search: { value: e.sSearch, regex: e.bRegex },
		}
		for (g = 0; g < c; g++)
			(n = b[g]),
				(l = f[g]),
				(i = 'function' == typeof n.mData ? 'function' : n.mData),
				pa.columns.push({
					data: i,
					name: n.sName,
					searchable: n.bSearchable,
					orderable: n.bSortable,
					search: { value: l.sSearch, regex: l.bRegex },
				}),
				t('mDataProp_' + g, i),
				d.bFilter && (t('sSearch_' + g, l.sSearch), t('bRegex_' + g, l.bRegex), t('bSearchable_' + g, n.bSearchable)),
				d.bSort && t('bSortable_' + g, n.bSortable)
		d.bFilter && (t('sSearch', e.sSearch), t('bRegex', e.bRegex))
		d.bSort &&
			(h.each(k, function (a, b) {
				pa.order.push({ column: b.col, dir: b.dir })
				t('iSortCol_' + a, b.col)
				t('sSortDir_' + a, b.dir)
			}),
			t('iSortingCols', k.length))
		b = m.ext.legacy.ajax
		return null === b ? (a.sAjaxSource ? j : pa) : b ? j : pa
	}
	function ub(a, b) {
		var c = ta(a, b),
			d = b.sEcho !== k ? b.sEcho : b.draw,
			e = b.iTotalRecords !== k ? b.iTotalRecords : b.recordsTotal,
			f = b.iTotalDisplayRecords !== k ? b.iTotalDisplayRecords : b.recordsFiltered
		if (d) {
			if (1 * d < a.iDraw) return
			a.iDraw = 1 * d
		}
		na(a)
		a._iRecordsTotal = parseInt(e, 10)
		a._iRecordsDisplay = parseInt(f, 10)
		d = 0
		for (e = c.length; d < e; d++) M(a, c[d])
		a.aiDisplay = a.aiDisplayMaster.slice()
		a.bAjaxDataGet = !1
		N(a)
		a._bInitComplete || ua(a, b)
		a.bAjaxDataGet = !0
		C(a, !1)
	}
	function ta(a, b) {
		var c = h.isPlainObject(a.ajax) && a.ajax.dataSrc !== k ? a.ajax.dataSrc : a.sAjaxDataProp
		return 'data' === c ? b.aaData || b[c] : '' !== c ? Q(c)(b) : b
	}
	function ob(a) {
		var b = a.oClasses,
			c = a.sTableId,
			d = a.oLanguage,
			e = a.oPreviousSearch,
			f = a.aanFeatures,
			g = '<input type="search" class="' + b.sFilterInput + '"/>',
			j = d.sSearch,
			j = j.match(/_INPUT_/) ? j.replace('_INPUT_', g) : j + g,
			b = h('<div/>', { id: !f.f ? c + '_filter' : null, class: b.sFilter }).append(h('<label/>').append(j)),
			f = function () {
				var b = !this.value ? '' : this.value
				b != e.sSearch &&
					(fa(a, { sSearch: b, bRegex: e.bRegex, bSmart: e.bSmart, bCaseInsensitive: e.bCaseInsensitive }),
					(a._iDisplayStart = 0),
					N(a))
			},
			g = null !== a.searchDelay ? a.searchDelay : 'ssp' === y(a) ? 400 : 0,
			i = h('input', b)
				.val(e.sSearch)
				.attr('placeholder', d.sSearchPlaceholder)
				.on('keyup.DT search.DT input.DT paste.DT cut.DT', g ? Na(f, g) : f)
				.on('keypress.DT', function (a) {
					if (13 == a.keyCode) return !1
				})
				.attr('aria-controls', c)
		h(a.nTable).on('search.dt.DT', function (b, c) {
			if (a === c)
				try {
					i[0] !== G.activeElement && i.val(e.sSearch)
				} catch (d) {}
		})
		return b[0]
	}
	function fa(a, b, c) {
		var d = a.oPreviousSearch,
			e = a.aoPreSearchCols,
			f = function (a) {
				d.sSearch = a.sSearch
				d.bRegex = a.bRegex
				d.bSmart = a.bSmart
				d.bCaseInsensitive = a.bCaseInsensitive
			}
		Fa(a)
		if ('ssp' != y(a)) {
			vb(a, b.sSearch, c, b.bEscapeRegex !== k ? !b.bEscapeRegex : b.bRegex, b.bSmart, b.bCaseInsensitive)
			f(b)
			for (b = 0; b < e.length; b++)
				wb(
					a,
					e[b].sSearch,
					b,
					e[b].bEscapeRegex !== k ? !e[b].bEscapeRegex : e[b].bRegex,
					e[b].bSmart,
					e[b].bCaseInsensitive
				)
			xb(a)
		} else f(b)
		a.bFiltered = !0
		r(a, null, 'search', [a])
	}
	function xb(a) {
		for (var b = m.ext.search, c = a.aiDisplay, d, e, f = 0, g = b.length; f < g; f++) {
			for (var j = [], i = 0, n = c.length; i < n; i++)
				(e = c[i]), (d = a.aoData[e]), b[f](a, d._aFilterData, e, d._aData, i) && j.push(e)
			c.length = 0
			h.merge(c, j)
		}
	}
	function wb(a, b, c, d, e, f) {
		if ('' !== b) {
			for (var g = [], j = a.aiDisplay, d = Oa(b, d, e, f), e = 0; e < j.length; e++)
				(b = a.aoData[j[e]]._aFilterData[c]), d.test(b) && g.push(j[e])
			a.aiDisplay = g
		}
	}
	function vb(a, b, c, d, e, f) {
		var d = Oa(b, d, e, f),
			f = a.oPreviousSearch.sSearch,
			g = a.aiDisplayMaster,
			j,
			e = []
		0 !== m.ext.search.length && (c = !0)
		j = yb(a)
		if (0 >= b.length) a.aiDisplay = g.slice()
		else {
			if (j || c || f.length > b.length || 0 !== b.indexOf(f) || a.bSorted) a.aiDisplay = g.slice()
			b = a.aiDisplay
			for (c = 0; c < b.length; c++) d.test(a.aoData[b[c]]._sFilterRow) && e.push(b[c])
			a.aiDisplay = e
		}
	}
	function Oa(a, b, c, d) {
		a = b ? a : Pa(a)
		c &&
			(a =
				'^(?=.*?' +
				h
					.map(a.match(/"[^"]+"|[^ ]+/g) || [''], function (a) {
						if ('"' === a.charAt(0))
							var b = a.match(/^"(.*)"$/),
								a = b ? b[1] : a
						return a.replace('"', '')
					})
					.join(')(?=.*?') +
				').*$')
		return RegExp(a, d ? 'i' : '')
	}
	function yb(a) {
		var b = a.aoColumns,
			c,
			d,
			e,
			f,
			g,
			j,
			i,
			h,
			l = m.ext.type.search
		c = !1
		d = 0
		for (f = a.aoData.length; d < f; d++)
			if (((h = a.aoData[d]), !h._aFilterData)) {
				j = []
				e = 0
				for (g = b.length; e < g; e++)
					(c = b[e]),
						c.bSearchable
							? ((i = B(a, d, e, 'filter')),
								l[c.sType] && (i = l[c.sType](i)),
								null === i && (i = ''),
								'string' !== typeof i && i.toString && (i = i.toString()))
							: (i = ''),
						i.indexOf && -1 !== i.indexOf('&') && ((va.innerHTML = i), (i = Wb ? va.textContent : va.innerText)),
						i.replace && (i = i.replace(/[\r\n]/g, '')),
						j.push(i)
				h._aFilterData = j
				h._sFilterRow = j.join('  ')
				c = !0
			}
		return c
	}
	function zb(a) {
		return { search: a.sSearch, smart: a.bSmart, regex: a.bRegex, caseInsensitive: a.bCaseInsensitive }
	}
	function Ab(a) {
		return { sSearch: a.search, bSmart: a.smart, bRegex: a.regex, bCaseInsensitive: a.caseInsensitive }
	}
	function rb(a) {
		var b = a.sTableId,
			c = a.aanFeatures.i,
			d = h('<div/>', { class: a.oClasses.sInfo, id: !c ? b + '_info' : null })
		c ||
			(a.aoDrawCallback.push({ fn: Bb, sName: 'information' }),
			d.attr('role', 'status').attr('aria-live', 'polite'),
			h(a.nTable).attr('aria-describedby', b + '_info'))
		return d[0]
	}
	function Bb(a) {
		var b = a.aanFeatures.i
		if (0 !== b.length) {
			var c = a.oLanguage,
				d = a._iDisplayStart + 1,
				e = a.fnDisplayEnd(),
				f = a.fnRecordsTotal(),
				g = a.fnRecordsDisplay(),
				j = g ? c.sInfo : c.sInfoEmpty
			g !== f && (j += ' ' + c.sInfoFiltered)
			j += c.sInfoPostFix
			j = Cb(a, j)
			c = c.fnInfoCallback
			null !== c && (j = c.call(a.oInstance, a, d, e, f, g, j))
			h(b).html(j)
		}
	}
	function Cb(a, b) {
		var c = a.fnFormatNumber,
			d = a._iDisplayStart + 1,
			e = a._iDisplayLength,
			f = a.fnRecordsDisplay(),
			g = -1 === e
		return b
			.replace(/_START_/g, c.call(a, d))
			.replace(/_END_/g, c.call(a, a.fnDisplayEnd()))
			.replace(/_MAX_/g, c.call(a, a.fnRecordsTotal()))
			.replace(/_TOTAL_/g, c.call(a, f))
			.replace(/_PAGE_/g, c.call(a, g ? 1 : Math.ceil(d / e)))
			.replace(/_PAGES_/g, c.call(a, g ? 1 : Math.ceil(f / e)))
	}
	function ga(a) {
		var b,
			c,
			d = a.iInitDisplayStart,
			e = a.aoColumns,
			f
		c = a.oFeatures
		var g = a.bDeferLoading
		if (a.bInitialised) {
			mb(a)
			jb(a)
			ea(a, a.aoHeader)
			ea(a, a.aoFooter)
			C(a, !0)
			c.bAutoWidth && Ea(a)
			b = 0
			for (c = e.length; b < c; b++) (f = e[b]), f.sWidth && (f.nTh.style.width = v(f.sWidth))
			r(a, null, 'preInit', [a])
			S(a)
			e = y(a)
			if ('ssp' != e || g)
				'ajax' == e
					? sa(
							a,
							[],
							function (c) {
								var f = ta(a, c)
								for (b = 0; b < f.length; b++) M(a, f[b])
								a.iInitDisplayStart = d
								S(a)
								C(a, !1)
								ua(a, c)
							},
							a
						)
					: (C(a, !1), ua(a))
		} else
			setTimeout(function () {
				ga(a)
			}, 200)
	}
	function ua(a, b) {
		a._bInitComplete = !0
		;(b || a.oInit.aaData) && Y(a)
		r(a, null, 'plugin-init', [a, b])
		r(a, 'aoInitComplete', 'init', [a, b])
	}
	function Qa(a, b) {
		var c = parseInt(b, 10)
		a._iDisplayLength = c
		Ra(a)
		r(a, null, 'length', [a, c])
	}
	function nb(a) {
		for (
			var b = a.oClasses,
				c = a.sTableId,
				d = a.aLengthMenu,
				e = h.isArray(d[0]),
				f = e ? d[0] : d,
				d = e ? d[1] : d,
				e = h('<select/>', { name: c + '_length', 'aria-controls': c, class: b.sLengthSelect }),
				g = 0,
				j = f.length;
			g < j;
			g++
		)
			e[0][g] = new Option('number' === typeof d[g] ? a.fnFormatNumber(d[g]) : d[g], f[g])
		var i = h('<div><label/></div>').addClass(b.sLength)
		a.aanFeatures.l || (i[0].id = c + '_length')
		i.children().append(a.oLanguage.sLengthMenu.replace('_MENU_', e[0].outerHTML))
		h('select', i)
			.val(a._iDisplayLength)
			.on('change.DT', function () {
				Qa(a, h(this).val())
				N(a)
			})
		h(a.nTable).on('length.dt.DT', function (b, c, d) {
			a === c && h('select', i).val(d)
		})
		return i[0]
	}
	function sb(a) {
		var b = a.sPaginationType,
			c = m.ext.pager[b],
			d = 'function' === typeof c,
			e = function (a) {
				N(a)
			},
			b = h('<div/>').addClass(a.oClasses.sPaging + b)[0],
			f = a.aanFeatures
		d || c.fnInit(a, b, e)
		f.p ||
			((b.id = a.sTableId + '_paginate'),
			a.aoDrawCallback.push({
				fn: function (a) {
					if (d) {
						var b = a._iDisplayStart,
							i = a._iDisplayLength,
							h = a.fnRecordsDisplay(),
							l = -1 === i,
							b = l ? 0 : Math.ceil(b / i),
							i = l ? 1 : Math.ceil(h / i),
							h = c(b, i),
							k,
							l = 0
						for (k = f.p.length; l < k; l++) Ma(a, 'pageButton')(a, f.p[l], l, h, b, i)
					} else c.fnUpdate(a, e)
				},
				sName: 'pagination',
			}))
		return b
	}
	function Sa(a, b, c) {
		var d = a._iDisplayStart,
			e = a._iDisplayLength,
			f = a.fnRecordsDisplay()
		0 === f || -1 === e
			? (d = 0)
			: 'number' === typeof b
				? ((d = b * e), d > f && (d = 0))
				: 'first' == b
					? (d = 0)
					: 'previous' == b
						? ((d = 0 <= e ? d - e : 0), 0 > d && (d = 0))
						: 'next' == b
							? d + e < f && (d += e)
							: 'last' == b
								? (d = Math.floor((f - 1) / e) * e)
								: J(a, 0, 'Unknown paging action: ' + b, 5)
		b = a._iDisplayStart !== d
		a._iDisplayStart = d
		b && (r(a, null, 'page', [a]), c && N(a))
		return b
	}
	function pb(a) {
		return h('<div/>', { id: !a.aanFeatures.r ? a.sTableId + '_processing' : null, class: a.oClasses.sProcessing })
			.html(a.oLanguage.sProcessing)
			.insertBefore(a.nTable)[0]
	}
	function C(a, b) {
		a.oFeatures.bProcessing && h(a.aanFeatures.r).css('display', b ? 'block' : 'none')
		r(a, null, 'processing', [a, b])
	}
	function qb(a) {
		var b = h(a.nTable)
		b.attr('role', 'grid')
		var c = a.oScroll
		if ('' === c.sX && '' === c.sY) return a.nTable
		var d = c.sX,
			e = c.sY,
			f = a.oClasses,
			g = b.children('caption'),
			j = g.length ? g[0]._captionSide : null,
			i = h(b[0].cloneNode(!1)),
			n = h(b[0].cloneNode(!1)),
			l = b.children('tfoot')
		l.length || (l = null)
		i = h('<div/>', { class: f.sScrollWrapper })
			.append(
				h('<div/>', { class: f.sScrollHead })
					.css({ overflow: 'hidden', position: 'relative', border: 0, width: d ? (!d ? null : v(d)) : '100%' })
					.append(
						h('<div/>', { class: f.sScrollHeadInner })
							.css({ 'box-sizing': 'content-box', width: c.sXInner || '100%' })
							.append(
								i
									.removeAttr('id')
									.css('margin-left', 0)
									.append('top' === j ? g : null)
									.append(b.children('thead'))
							)
					)
			)
			.append(
				h('<div/>', { class: f.sScrollBody })
					.css({ position: 'relative', overflow: 'auto', width: !d ? null : v(d) })
					.append(b)
			)
		l &&
			i.append(
				h('<div/>', { class: f.sScrollFoot })
					.css({ overflow: 'hidden', border: 0, width: d ? (!d ? null : v(d)) : '100%' })
					.append(
						h('<div/>', { class: f.sScrollFootInner }).append(
							n
								.removeAttr('id')
								.css('margin-left', 0)
								.append('bottom' === j ? g : null)
								.append(b.children('tfoot'))
						)
					)
			)
		var b = i.children(),
			k = b[0],
			f = b[1],
			t = l ? b[2] : null
		if (d)
			h(f).on('scroll.DT', function () {
				var a = this.scrollLeft
				k.scrollLeft = a
				l && (t.scrollLeft = a)
			})
		h(f).css(e && c.bCollapse ? 'max-height' : 'height', e)
		a.nScrollHead = k
		a.nScrollBody = f
		a.nScrollFoot = t
		a.aoDrawCallback.push({ fn: ka, sName: 'scrolling' })
		return i[0]
	}
	function ka(a) {
		var b = a.oScroll,
			c = b.sX,
			d = b.sXInner,
			e = b.sY,
			b = b.iBarWidth,
			f = h(a.nScrollHead),
			g = f[0].style,
			j = f.children('div'),
			i = j[0].style,
			n = j.children('table'),
			j = a.nScrollBody,
			l = h(j),
			q = j.style,
			t = h(a.nScrollFoot).children('div'),
			m = t.children('table'),
			o = h(a.nTHead),
			p = h(a.nTable),
			s = p[0],
			r = s.style,
			u = a.nTFoot ? h(a.nTFoot) : null,
			x = a.oBrowser,
			T = x.bScrollOversize,
			Xb = D(a.aoColumns, 'nTh'),
			O,
			K,
			P,
			w,
			Ta = [],
			y = [],
			z = [],
			A = [],
			B,
			C = function (a) {
				a = a.style
				a.paddingTop = '0'
				a.paddingBottom = '0'
				a.borderTopWidth = '0'
				a.borderBottomWidth = '0'
				a.height = 0
			}
		K = j.scrollHeight > j.clientHeight
		if (a.scrollBarVis !== K && a.scrollBarVis !== k) (a.scrollBarVis = K), Y(a)
		else {
			a.scrollBarVis = K
			p.children('thead, tfoot').remove()
			u && ((P = u.clone().prependTo(p)), (O = u.find('tr')), (P = P.find('tr')))
			w = o.clone().prependTo(p)
			o = o.find('tr')
			K = w.find('tr')
			w.find('th, td').removeAttr('tabindex')
			c || ((q.width = '100%'), (f[0].style.width = '100%'))
			h.each(ra(a, w), function (b, c) {
				B = Z(a, b)
				c.style.width = a.aoColumns[B].sWidth
			})
			u &&
				H(function (a) {
					a.style.width = ''
				}, P)
			f = p.outerWidth()
			if ('' === c) {
				r.width = '100%'
				if (T && (p.find('tbody').height() > j.offsetHeight || 'scroll' == l.css('overflow-y')))
					r.width = v(p.outerWidth() - b)
				f = p.outerWidth()
			} else '' !== d && ((r.width = v(d)), (f = p.outerWidth()))
			H(C, K)
			H(function (a) {
				z.push(a.innerHTML)
				Ta.push(v(h(a).css('width')))
			}, K)
			H(function (a, b) {
				if (h.inArray(a, Xb) !== -1) a.style.width = Ta[b]
			}, o)
			h(K).height(0)
			u &&
				(H(C, P),
				H(function (a) {
					A.push(a.innerHTML)
					y.push(v(h(a).css('width')))
				}, P),
				H(function (a, b) {
					a.style.width = y[b]
				}, O),
				h(P).height(0))
			H(function (a, b) {
				a.innerHTML = '<div class="dataTables_sizing" style="height:0;overflow:hidden;">' + z[b] + '</div>'
				a.style.width = Ta[b]
			}, K)
			u &&
				H(function (a, b) {
					a.innerHTML = '<div class="dataTables_sizing" style="height:0;overflow:hidden;">' + A[b] + '</div>'
					a.style.width = y[b]
				}, P)
			if (p.outerWidth() < f) {
				O = j.scrollHeight > j.offsetHeight || 'scroll' == l.css('overflow-y') ? f + b : f
				if (T && (j.scrollHeight > j.offsetHeight || 'scroll' == l.css('overflow-y'))) r.width = v(O - b)
				;('' === c || '' !== d) && J(a, 1, 'Possible column misalignment', 6)
			} else O = '100%'
			q.width = v(O)
			g.width = v(O)
			u && (a.nScrollFoot.style.width = v(O))
			!e && T && (q.height = v(s.offsetHeight + b))
			c = p.outerWidth()
			n[0].style.width = v(c)
			i.width = v(c)
			d = p.height() > j.clientHeight || 'scroll' == l.css('overflow-y')
			e = 'padding' + (x.bScrollbarLeft ? 'Left' : 'Right')
			i[e] = d ? b + 'px' : '0px'
			u && ((m[0].style.width = v(c)), (t[0].style.width = v(c)), (t[0].style[e] = d ? b + 'px' : '0px'))
			p.children('colgroup').insertBefore(p.children('thead'))
			l.scroll()
			if ((a.bSorted || a.bFiltered) && !a._drawHold) j.scrollTop = 0
		}
	}
	function H(a, b, c) {
		for (var d = 0, e = 0, f = b.length, g, j; e < f; ) {
			g = b[e].firstChild
			for (j = c ? c[e].firstChild : null; g; )
				1 === g.nodeType && (c ? a(g, j, d) : a(g, d), d++), (g = g.nextSibling), (j = c ? j.nextSibling : null)
			e++
		}
	}
	function Ea(a) {
		var b = a.nTable,
			c = a.aoColumns,
			d = a.oScroll,
			e = d.sY,
			f = d.sX,
			g = d.sXInner,
			j = c.length,
			i = la(a, 'bVisible'),
			n = h('th', a.nTHead),
			l = b.getAttribute('width'),
			k = b.parentNode,
			t = !1,
			m,
			o,
			p = a.oBrowser,
			d = p.bScrollOversize
		;(m = b.style.width) && -1 !== m.indexOf('%') && (l = m)
		for (m = 0; m < i.length; m++) (o = c[i[m]]), null !== o.sWidth && ((o.sWidth = Db(o.sWidthOrig, k)), (t = !0))
		if (d || (!t && !f && !e && j == aa(a) && j == n.length))
			for (m = 0; m < j; m++) (i = Z(a, m)), null !== i && (c[i].sWidth = v(n.eq(m).width()))
		else {
			j = h(b).clone().css('visibility', 'hidden').removeAttr('id')
			j.find('tbody tr').remove()
			var s = h('<tr/>').appendTo(j.find('tbody'))
			j.find('thead, tfoot').remove()
			j.append(h(a.nTHead).clone()).append(h(a.nTFoot).clone())
			j.find('tfoot th, tfoot td').css('width', '')
			n = ra(a, j.find('thead')[0])
			for (m = 0; m < i.length; m++)
				(o = c[i[m]]),
					(n[m].style.width = null !== o.sWidthOrig && '' !== o.sWidthOrig ? v(o.sWidthOrig) : ''),
					o.sWidthOrig &&
						f &&
						h(n[m]).append(h('<div/>').css({ width: o.sWidthOrig, margin: 0, padding: 0, border: 0, height: 1 }))
			if (a.aoData.length)
				for (m = 0; m < i.length; m++)
					(t = i[m]), (o = c[t]), h(Eb(a, t)).clone(!1).append(o.sContentPadding).appendTo(s)
			h('[name]', j).removeAttr('name')
			o = h('<div/>')
				.css(f || e ? { position: 'absolute', top: 0, left: 0, height: 1, right: 0, overflow: 'hidden' } : {})
				.append(j)
				.appendTo(k)
			f && g
				? j.width(g)
				: f
					? (j.css('width', 'auto'), j.removeAttr('width'), j.width() < k.clientWidth && l && j.width(k.clientWidth))
					: e
						? j.width(k.clientWidth)
						: l && j.width(l)
			for (m = e = 0; m < i.length; m++)
				(k = h(n[m])),
					(g = k.outerWidth() - k.width()),
					(k = p.bBounding ? Math.ceil(n[m].getBoundingClientRect().width) : k.outerWidth()),
					(e += k),
					(c[i[m]].sWidth = v(k - g))
			b.style.width = v(e)
			o.remove()
		}
		l && (b.style.width = v(l))
		if ((l || f) && !a._reszEvt)
			(b = function () {
				h(E).on(
					'resize.DT-' + a.sInstance,
					Na(function () {
						Y(a)
					})
				)
			}),
				d ? setTimeout(b, 1e3) : b(),
				(a._reszEvt = !0)
	}
	function Db(a, b) {
		if (!a) return 0
		var c = h('<div/>')
				.css('width', v(a))
				.appendTo(b || G.body),
			d = c[0].offsetWidth
		c.remove()
		return d
	}
	function Eb(a, b) {
		var c = Fb(a, b)
		if (0 > c) return null
		var d = a.aoData[c]
		return !d.nTr ? h('<td/>').html(B(a, c, b, 'display'))[0] : d.anCells[b]
	}
	function Fb(a, b) {
		for (var c, d = -1, e = -1, f = 0, g = a.aoData.length; f < g; f++)
			(c = B(a, f, b, 'display') + ''),
				(c = c.replace(Yb, '')),
				(c = c.replace(/&nbsp;/g, ' ')),
				c.length > d && ((d = c.length), (e = f))
		return e
	}
	function v(a) {
		return null === a ? '0px' : 'number' == typeof a ? (0 > a ? '0px' : a + 'px') : a.match(/\d$/) ? a + 'px' : a
	}
	function V(a) {
		var b,
			c,
			d = [],
			e = a.aoColumns,
			f,
			g,
			j,
			i
		b = a.aaSortingFixed
		c = h.isPlainObject(b)
		var n = []
		f = function (a) {
			a.length && !h.isArray(a[0]) ? n.push(a) : h.merge(n, a)
		}
		h.isArray(b) && f(b)
		c && b.pre && f(b.pre)
		f(a.aaSorting)
		c && b.post && f(b.post)
		for (a = 0; a < n.length; a++) {
			i = n[a][0]
			f = e[i].aDataSort
			b = 0
			for (c = f.length; b < c; b++)
				(g = f[b]),
					(j = e[g].sType || 'string'),
					n[a]._idx === k && (n[a]._idx = h.inArray(n[a][1], e[g].asSorting)),
					d.push({ src: i, col: g, dir: n[a][1], index: n[a]._idx, type: j, formatter: m.ext.type.order[j + '-pre'] })
		}
		return d
	}
	function lb(a) {
		var b,
			c,
			d = [],
			e = m.ext.type.order,
			f = a.aoData,
			g = 0,
			j,
			i = a.aiDisplayMaster,
			h
		Fa(a)
		h = V(a)
		b = 0
		for (c = h.length; b < c; b++) (j = h[b]), j.formatter && g++, Gb(a, j.col)
		if ('ssp' != y(a) && 0 !== h.length) {
			b = 0
			for (c = i.length; b < c; b++) d[i[b]] = b
			g === h.length
				? i.sort(function (a, b) {
						var c,
							e,
							g,
							j,
							i = h.length,
							k = f[a]._aSortData,
							m = f[b]._aSortData
						for (g = 0; g < i; g++)
							if (((j = h[g]), (c = k[j.col]), (e = m[j.col]), (c = c < e ? -1 : c > e ? 1 : 0), 0 !== c))
								return 'asc' === j.dir ? c : -c
						c = d[a]
						e = d[b]
						return c < e ? -1 : c > e ? 1 : 0
					})
				: i.sort(function (a, b) {
						var c,
							g,
							j,
							i,
							k = h.length,
							m = f[a]._aSortData,
							o = f[b]._aSortData
						for (j = 0; j < k; j++)
							if (
								((i = h[j]),
								(c = m[i.col]),
								(g = o[i.col]),
								(i = e[i.type + '-' + i.dir] || e['string-' + i.dir]),
								(c = i(c, g)),
								0 !== c)
							)
								return c
						c = d[a]
						g = d[b]
						return c < g ? -1 : c > g ? 1 : 0
					})
		}
		a.bSorted = !0
	}
	function Hb(a) {
		for (var b, c, d = a.aoColumns, e = V(a), a = a.oLanguage.oAria, f = 0, g = d.length; f < g; f++) {
			c = d[f]
			var j = c.asSorting
			b = c.sTitle.replace(/<.*?>/g, '')
			var i = c.nTh
			i.removeAttribute('aria-sort')
			c.bSortable &&
				(0 < e.length && e[0].col == f
					? (i.setAttribute('aria-sort', 'asc' == e[0].dir ? 'ascending' : 'descending'),
						(c = j[e[0].index + 1] || j[0]))
					: (c = j[0]),
				(b += 'asc' === c ? a.sSortAscending : a.sSortDescending))
			i.setAttribute('aria-label', b)
		}
	}
	function Ua(a, b, c, d) {
		var e = a.aaSorting,
			f = a.aoColumns[b].asSorting,
			g = function (a, b) {
				var c = a._idx
				c === k && (c = h.inArray(a[1], f))
				return c + 1 < f.length ? c + 1 : b ? null : 0
			}
		'number' === typeof e[0] && (e = a.aaSorting = [e])
		c && a.oFeatures.bSortMulti
			? ((c = h.inArray(b, D(e, '0'))),
				-1 !== c
					? ((b = g(e[c], !0)),
						null === b && 1 === e.length && (b = 0),
						null === b ? e.splice(c, 1) : ((e[c][1] = f[b]), (e[c]._idx = b)))
					: (e.push([b, f[0], 0]), (e[e.length - 1]._idx = 0)))
			: e.length && e[0][0] == b
				? ((b = g(e[0])), (e.length = 1), (e[0][1] = f[b]), (e[0]._idx = b))
				: ((e.length = 0), e.push([b, f[0]]), (e[0]._idx = 0))
		S(a)
		'function' == typeof d && d(a)
	}
	function La(a, b, c, d) {
		var e = a.aoColumns[c]
		Va(b, {}, function (b) {
			!1 !== e.bSortable &&
				(a.oFeatures.bProcessing
					? (C(a, !0),
						setTimeout(function () {
							Ua(a, c, b.shiftKey, d)
							'ssp' !== y(a) && C(a, !1)
						}, 0))
					: Ua(a, c, b.shiftKey, d))
		})
	}
	function wa(a) {
		var b = a.aLastSort,
			c = a.oClasses.sSortColumn,
			d = V(a),
			e = a.oFeatures,
			f,
			g
		if (e.bSort && e.bSortClasses) {
			e = 0
			for (f = b.length; e < f; e++) (g = b[e].src), h(D(a.aoData, 'anCells', g)).removeClass(c + (2 > e ? e + 1 : 3))
			e = 0
			for (f = d.length; e < f; e++) (g = d[e].src), h(D(a.aoData, 'anCells', g)).addClass(c + (2 > e ? e + 1 : 3))
		}
		a.aLastSort = d
	}
	function Gb(a, b) {
		var c = a.aoColumns[b],
			d = m.ext.order[c.sSortDataType],
			e
		d && (e = d.call(a.oInstance, a, b, $(a, b)))
		for (var f, g = m.ext.type.order[c.sType + '-pre'], j = 0, i = a.aoData.length; j < i; j++)
			if (((c = a.aoData[j]), c._aSortData || (c._aSortData = []), !c._aSortData[b] || d))
				(f = d ? e[j] : B(a, j, b, 'sort')), (c._aSortData[b] = g ? g(f) : f)
	}
	function xa(a) {
		if (a.oFeatures.bStateSave && !a.bDestroying) {
			var b = {
				time: +new Date(),
				start: a._iDisplayStart,
				length: a._iDisplayLength,
				order: h.extend(!0, [], a.aaSorting),
				search: zb(a.oPreviousSearch),
				columns: h.map(a.aoColumns, function (b, d) {
					return { visible: b.bVisible, search: zb(a.aoPreSearchCols[d]) }
				}),
			}
			r(a, 'aoStateSaveParams', 'stateSaveParams', [a, b])
			a.oSavedState = b
			a.fnStateSaveCallback.call(a.oInstance, a, b)
		}
	}
	function Ib(a, b, c) {
		var d,
			e,
			f = a.aoColumns,
			b = function (b) {
				if (b && b.time) {
					var g = r(a, 'aoStateLoadParams', 'stateLoadParams', [a, b])
					if (
						-1 === h.inArray(!1, g) &&
						((g = a.iStateDuration),
						!(0 < g && b.time < +new Date() - 1e3 * g) && !(b.columns && f.length !== b.columns.length))
					) {
						a.oLoadedState = h.extend(!0, {}, b)
						b.start !== k && ((a._iDisplayStart = b.start), (a.iInitDisplayStart = b.start))
						b.length !== k && (a._iDisplayLength = b.length)
						b.order !== k &&
							((a.aaSorting = []),
							h.each(b.order, function (b, c) {
								a.aaSorting.push(c[0] >= f.length ? [0, c[1]] : c)
							}))
						b.search !== k && h.extend(a.oPreviousSearch, Ab(b.search))
						if (b.columns) {
							d = 0
							for (e = b.columns.length; d < e; d++)
								(g = b.columns[d]),
									g.visible !== k && (f[d].bVisible = g.visible),
									g.search !== k && h.extend(a.aoPreSearchCols[d], Ab(g.search))
						}
						r(a, 'aoStateLoaded', 'stateLoaded', [a, b])
					}
				}
				c()
			}
		if (a.oFeatures.bStateSave) {
			var g = a.fnStateLoadCallback.call(a.oInstance, a, b)
			g !== k && b(g)
		} else c()
	}
	function ya(a) {
		var b = m.settings,
			a = h.inArray(a, D(b, 'nTable'))
		return -1 !== a ? b[a] : null
	}
	function J(a, b, c, d) {
		c = 'DataTables warning: ' + (a ? 'table id=' + a.sTableId + ' - ' : '') + c
		d && (c += '. For more information about this error, please see http://datatables.net/tn/' + d)
		if (b) E.console && console.log && console.log(c)
		else if (((b = m.ext), (b = b.sErrMode || b.errMode), a && r(a, null, 'error', [a, d, c]), 'alert' == b)) alert(c)
		else {
			if ('throw' == b) throw Error(c)
			'function' == typeof b && b(a, d, c)
		}
	}
	function F(a, b, c, d) {
		h.isArray(c)
			? h.each(c, function (c, d) {
					h.isArray(d) ? F(a, b, d[0], d[1]) : F(a, b, d)
				})
			: (d === k && (d = c), b[c] !== k && (a[d] = b[c]))
	}
	function Jb(a, b, c) {
		var d, e
		for (e in b)
			b.hasOwnProperty(e) &&
				((d = b[e]),
				h.isPlainObject(d)
					? (h.isPlainObject(a[e]) || (a[e] = {}), h.extend(!0, a[e], d))
					: (a[e] = c && 'data' !== e && 'aaData' !== e && h.isArray(d) ? d.slice() : d))
		return a
	}
	function Va(a, b, c) {
		h(a)
			.on('click.DT', b, function (b) {
				a.blur()
				c(b)
			})
			.on('keypress.DT', b, function (a) {
				13 === a.which && (a.preventDefault(), c(a))
			})
			.on('selectstart.DT', function () {
				return !1
			})
	}
	function z(a, b, c, d) {
		c && a[b].push({ fn: c, sName: d })
	}
	function r(a, b, c, d) {
		var e = []
		b &&
			(e = h.map(a[b].slice().reverse(), function (b) {
				return b.fn.apply(a.oInstance, d)
			}))
		null !== c && ((b = h.Event(c + '.dt')), h(a.nTable).trigger(b, d), e.push(b.result))
		return e
	}
	function Ra(a) {
		var b = a._iDisplayStart,
			c = a.fnDisplayEnd(),
			d = a._iDisplayLength
		b >= c && (b = c - d)
		b -= b % d
		if (-1 === d || 0 > b) b = 0
		a._iDisplayStart = b
	}
	function Ma(a, b) {
		var c = a.renderer,
			d = m.ext.renderer[b]
		return h.isPlainObject(c) && c[b] ? d[c[b]] || d._ : 'string' === typeof c ? d[c] || d._ : d._
	}
	function y(a) {
		return a.oFeatures.bServerSide ? 'ssp' : a.ajax || a.sAjaxSource ? 'ajax' : 'dom'
	}
	function ha(a, b) {
		var c = [],
			c = Kb.numbers_length,
			d = Math.floor(c / 2)
		b <= c
			? (c = W(0, b))
			: a <= d
				? ((c = W(0, c - 2)), c.push('ellipsis'), c.push(b - 1))
				: (a >= b - 1 - d
						? (c = W(b - (c - 2), b))
						: ((c = W(a - d + 2, a + d - 1)), c.push('ellipsis'), c.push(b - 1)),
					c.splice(0, 0, 'ellipsis'),
					c.splice(0, 0, 0))
		c.DT_el = 'span'
		return c
	}
	function cb(a) {
		h.each(
			{
				num: function (b) {
					return za(b, a)
				},
				'num-fmt': function (b) {
					return za(b, a, Wa)
				},
				'html-num': function (b) {
					return za(b, a, Aa)
				},
				'html-num-fmt': function (b) {
					return za(b, a, Aa, Wa)
				},
			},
			function (b, c) {
				x.type.order[b + a + '-pre'] = c
				b.match(/^html\-/) && (x.type.search[b + a] = x.type.search.html)
			}
		)
	}
	function Lb(a) {
		return function () {
			var b = [ya(this[m.ext.iApiIndex])].concat(Array.prototype.slice.call(arguments))
			return m.ext.internal[a].apply(this, b)
		}
	}
	var m = function (a) {
			this.$ = function (a, b) {
				return this.api(!0).$(a, b)
			}
			this._ = function (a, b) {
				return this.api(!0).rows(a, b).data()
			}
			this.api = function (a) {
				return a ? new s(ya(this[x.iApiIndex])) : new s(this)
			}
			this.fnAddData = function (a, b) {
				var c = this.api(!0),
					d = h.isArray(a) && (h.isArray(a[0]) || h.isPlainObject(a[0])) ? c.rows.add(a) : c.row.add(a)
				;(b === k || b) && c.draw()
				return d.flatten().toArray()
			}
			this.fnAdjustColumnSizing = function (a) {
				var b = this.api(!0).columns.adjust(),
					c = b.settings()[0],
					d = c.oScroll
				a === k || a ? b.draw(!1) : ('' !== d.sX || '' !== d.sY) && ka(c)
			}
			this.fnClearTable = function (a) {
				var b = this.api(!0).clear()
				;(a === k || a) && b.draw()
			}
			this.fnClose = function (a) {
				this.api(!0).row(a).child.hide()
			}
			this.fnDeleteRow = function (a, b, c) {
				var d = this.api(!0),
					a = d.rows(a),
					e = a.settings()[0],
					h = e.aoData[a[0][0]]
				a.remove()
				b && b.call(this, e, h)
				;(c === k || c) && d.draw()
				return h
			}
			this.fnDestroy = function (a) {
				this.api(!0).destroy(a)
			}
			this.fnDraw = function (a) {
				this.api(!0).draw(a)
			}
			this.fnFilter = function (a, b, c, d, e, h) {
				e = this.api(!0)
				null === b || b === k ? e.search(a, c, d, h) : e.column(b).search(a, c, d, h)
				e.draw()
			}
			this.fnGetData = function (a, b) {
				var c = this.api(!0)
				if (a !== k) {
					var d = a.nodeName ? a.nodeName.toLowerCase() : ''
					return b !== k || 'td' == d || 'th' == d ? c.cell(a, b).data() : c.row(a).data() || null
				}
				return c.data().toArray()
			}
			this.fnGetNodes = function (a) {
				var b = this.api(!0)
				return a !== k ? b.row(a).node() : b.rows().nodes().flatten().toArray()
			}
			this.fnGetPosition = function (a) {
				var b = this.api(!0),
					c = a.nodeName.toUpperCase()
				return 'TR' == c
					? b.row(a).index()
					: 'TD' == c || 'TH' == c
						? ((a = b.cell(a).index()), [a.row, a.columnVisible, a.column])
						: null
			}
			this.fnIsOpen = function (a) {
				return this.api(!0).row(a).child.isShown()
			}
			this.fnOpen = function (a, b, c) {
				return this.api(!0).row(a).child(b, c).show().child()[0]
			}
			this.fnPageChange = function (a, b) {
				var c = this.api(!0).page(a)
				;(b === k || b) && c.draw(!1)
			}
			this.fnSetColumnVis = function (a, b, c) {
				a = this.api(!0).column(a).visible(b)
				;(c === k || c) && a.columns.adjust().draw()
			}
			this.fnSettings = function () {
				return ya(this[x.iApiIndex])
			}
			this.fnSort = function (a) {
				this.api(!0).order(a).draw()
			}
			this.fnSortListener = function (a, b, c) {
				this.api(!0).order.listener(a, b, c)
			}
			this.fnUpdate = function (a, b, c, d, e) {
				var h = this.api(!0)
				c === k || null === c ? h.row(b).data(a) : h.cell(b, c).data(a)
				;(e === k || e) && h.columns.adjust()
				;(d === k || d) && h.draw()
				return 0
			}
			this.fnVersionCheck = x.fnVersionCheck
			var b = this,
				c = a === k,
				d = this.length
			c && (a = {})
			this.oApi = this.internal = x.internal
			for (var e in m.ext.internal) e && (this[e] = Lb(e))
			this.each(function () {
				var e = {},
					g = 1 < d ? Jb(e, a, !0) : a,
					j = 0,
					i,
					e = this.getAttribute('id'),
					n = !1,
					l = m.defaults,
					q = h(this)
				if ('table' != this.nodeName.toLowerCase())
					J(null, 0, 'Non-table node initialisation (' + this.nodeName + ')', 2)
				else {
					db(l)
					eb(l.column)
					I(l, l, !0)
					I(l.column, l.column, !0)
					I(l, h.extend(g, q.data()))
					var t = m.settings,
						j = 0
					for (i = t.length; j < i; j++) {
						var o = t[j]
						if (o.nTable == this || o.nTHead.parentNode == this || (o.nTFoot && o.nTFoot.parentNode == this)) {
							var s = g.bRetrieve !== k ? g.bRetrieve : l.bRetrieve
							if (c || s) return o.oInstance
							if (g.bDestroy !== k ? g.bDestroy : l.bDestroy) {
								o.oInstance.fnDestroy()
								break
							} else {
								J(o, 0, 'Cannot reinitialise DataTable', 3)
								return
							}
						}
						if (o.sTableId == this.id) {
							t.splice(j, 1)
							break
						}
					}
					if (null === e || '' === e) this.id = e = 'DataTables_Table_' + m.ext._unique++
					var p = h.extend(!0, {}, m.models.oSettings, { sDestroyWidth: q[0].style.width, sInstance: e, sTableId: e })
					p.nTable = this
					p.oApi = b.internal
					p.oInit = g
					t.push(p)
					p.oInstance = 1 === b.length ? b : q.dataTable()
					db(g)
					g.oLanguage && Ca(g.oLanguage)
					g.aLengthMenu &&
						!g.iDisplayLength &&
						(g.iDisplayLength = h.isArray(g.aLengthMenu[0]) ? g.aLengthMenu[0][0] : g.aLengthMenu[0])
					g = Jb(h.extend(!0, {}, l), g)
					F(
						p.oFeatures,
						g,
						'bPaginate bLengthChange bFilter bSort bSortMulti bInfo bProcessing bAutoWidth bSortClasses bServerSide bDeferRender'.split(
							' '
						)
					)
					F(p, g, [
						'asStripeClasses',
						'ajax',
						'fnServerData',
						'fnFormatNumber',
						'sServerMethod',
						'aaSorting',
						'aaSortingFixed',
						'aLengthMenu',
						'sPaginationType',
						'sAjaxSource',
						'sAjaxDataProp',
						'iStateDuration',
						'sDom',
						'bSortCellsTop',
						'iTabIndex',
						'fnStateLoadCallback',
						'fnStateSaveCallback',
						'renderer',
						'searchDelay',
						'rowId',
						['iCookieDuration', 'iStateDuration'],
						['oSearch', 'oPreviousSearch'],
						['aoSearchCols', 'aoPreSearchCols'],
						['iDisplayLength', '_iDisplayLength'],
					])
					F(p.oScroll, g, [
						['sScrollX', 'sX'],
						['sScrollXInner', 'sXInner'],
						['sScrollY', 'sY'],
						['bScrollCollapse', 'bCollapse'],
					])
					F(p.oLanguage, g, 'fnInfoCallback')
					z(p, 'aoDrawCallback', g.fnDrawCallback, 'user')
					z(p, 'aoServerParams', g.fnServerParams, 'user')
					z(p, 'aoStateSaveParams', g.fnStateSaveParams, 'user')
					z(p, 'aoStateLoadParams', g.fnStateLoadParams, 'user')
					z(p, 'aoStateLoaded', g.fnStateLoaded, 'user')
					z(p, 'aoRowCallback', g.fnRowCallback, 'user')
					z(p, 'aoRowCreatedCallback', g.fnCreatedRow, 'user')
					z(p, 'aoHeaderCallback', g.fnHeaderCallback, 'user')
					z(p, 'aoFooterCallback', g.fnFooterCallback, 'user')
					z(p, 'aoInitComplete', g.fnInitComplete, 'user')
					z(p, 'aoPreDrawCallback', g.fnPreDrawCallback, 'user')
					p.rowIdFn = Q(g.rowId)
					fb(p)
					var u = p.oClasses
					h.extend(u, m.ext.classes, g.oClasses)
					q.addClass(u.sTable)
					p.iInitDisplayStart === k && ((p.iInitDisplayStart = g.iDisplayStart), (p._iDisplayStart = g.iDisplayStart))
					null !== g.iDeferLoading &&
						((p.bDeferLoading = !0),
						(e = h.isArray(g.iDeferLoading)),
						(p._iRecordsDisplay = e ? g.iDeferLoading[0] : g.iDeferLoading),
						(p._iRecordsTotal = e ? g.iDeferLoading[1] : g.iDeferLoading))
					var v = p.oLanguage
					h.extend(!0, v, g.oLanguage)
					v.sUrl &&
						(h.ajax({
							dataType: 'json',
							url: v.sUrl,
							success: function (a) {
								Ca(a)
								I(l.oLanguage, a)
								h.extend(true, v, a)
								ga(p)
							},
							error: function () {
								ga(p)
							},
						}),
						(n = !0))
					null === g.asStripeClasses && (p.asStripeClasses = [u.sStripeOdd, u.sStripeEven])
					var e = p.asStripeClasses,
						x = q.children('tbody').find('tr').eq(0)
					;-1 !==
						h.inArray(
							!0,
							h.map(e, function (a) {
								return x.hasClass(a)
							})
						) && (h('tbody tr', this).removeClass(e.join(' ')), (p.asDestroyStripes = e.slice()))
					e = []
					t = this.getElementsByTagName('thead')
					0 !== t.length && (da(p.aoHeader, t[0]), (e = ra(p)))
					if (null === g.aoColumns) {
						t = []
						j = 0
						for (i = e.length; j < i; j++) t.push(null)
					} else t = g.aoColumns
					j = 0
					for (i = t.length; j < i; j++) Da(p, e ? e[j] : null)
					hb(p, g.aoColumnDefs, t, function (a, b) {
						ja(p, a, b)
					})
					if (x.length) {
						var w = function (a, b) {
							return a.getAttribute('data-' + b) !== null ? b : null
						}
						h(x[0])
							.children('th, td')
							.each(function (a, b) {
								var c = p.aoColumns[a]
								if (c.mData === a) {
									var d = w(b, 'sort') || w(b, 'order'),
										e = w(b, 'filter') || w(b, 'search')
									if (d !== null || e !== null) {
										c.mData = {
											_: a + '.display',
											sort: d !== null ? a + '.@data-' + d : k,
											type: d !== null ? a + '.@data-' + d : k,
											filter: e !== null ? a + '.@data-' + e : k,
										}
										ja(p, a)
									}
								}
							})
					}
					var T = p.oFeatures,
						e = function () {
							if (g.aaSorting === k) {
								var a = p.aaSorting
								j = 0
								for (i = a.length; j < i; j++) a[j][1] = p.aoColumns[j].asSorting[0]
							}
							wa(p)
							T.bSort &&
								z(p, 'aoDrawCallback', function () {
									if (p.bSorted) {
										var a = V(p),
											b = {}
										h.each(a, function (a, c) {
											b[c.src] = c.dir
										})
										r(p, null, 'order', [p, a, b])
										Hb(p)
									}
								})
							z(
								p,
								'aoDrawCallback',
								function () {
									;(p.bSorted || y(p) === 'ssp' || T.bDeferRender) && wa(p)
								},
								'sc'
							)
							var a = q.children('caption').each(function () {
									this._captionSide = h(this).css('caption-side')
								}),
								b = q.children('thead')
							b.length === 0 && (b = h('<thead/>').appendTo(q))
							p.nTHead = b[0]
							b = q.children('tbody')
							b.length === 0 && (b = h('<tbody/>').appendTo(q))
							p.nTBody = b[0]
							b = q.children('tfoot')
							if (b.length === 0 && a.length > 0 && (p.oScroll.sX !== '' || p.oScroll.sY !== ''))
								b = h('<tfoot/>').appendTo(q)
							if (b.length === 0 || b.children().length === 0) q.addClass(u.sNoFooter)
							else if (b.length > 0) {
								p.nTFoot = b[0]
								da(p.aoFooter, p.nTFoot)
							}
							if (g.aaData) for (j = 0; j < g.aaData.length; j++) M(p, g.aaData[j])
							else (p.bDeferLoading || y(p) == 'dom') && ma(p, h(p.nTBody).children('tr'))
							p.aiDisplay = p.aiDisplayMaster.slice()
							p.bInitialised = true
							n === false && ga(p)
						}
					g.bStateSave ? ((T.bStateSave = !0), z(p, 'aoDrawCallback', xa, 'state_save'), Ib(p, g, e)) : e()
				}
			})
			b = null
			return this
		},
		x,
		s,
		o,
		u,
		Xa = {},
		Mb = /[\r\n]/g,
		Aa = /<.*?>/g,
		Zb = /^\d{2,4}[\.\/\-]\d{1,2}[\.\/\-]\d{1,2}([T ]{1}\d{1,2}[:\.]\d{2}([\.:]\d{2})?)?$/,
		$b = RegExp('(\\/|\\.|\\*|\\+|\\?|\\||\\(|\\)|\\[|\\]|\\{|\\}|\\\\|\\$|\\^|\\-)', 'g'),
		Wa = /[',$£€¥%\u2009\u202F\u20BD\u20a9\u20BArfk]/gi,
		L = function (a) {
			return !a || !0 === a || '-' === a ? !0 : !1
		},
		Nb = function (a) {
			var b = parseInt(a, 10)
			return !isNaN(b) && isFinite(a) ? b : null
		},
		Ob = function (a, b) {
			Xa[b] || (Xa[b] = RegExp(Pa(b), 'g'))
			return 'string' === typeof a && '.' !== b ? a.replace(/\./g, '').replace(Xa[b], '.') : a
		},
		Ya = function (a, b, c) {
			var d = 'string' === typeof a
			if (L(a)) return !0
			b && d && (a = Ob(a, b))
			c && d && (a = a.replace(Wa, ''))
			return !isNaN(parseFloat(a)) && isFinite(a)
		},
		Pb = function (a, b, c) {
			return L(a) ? !0 : !(L(a) || 'string' === typeof a) ? null : Ya(a.replace(Aa, ''), b, c) ? !0 : null
		},
		D = function (a, b, c) {
			var d = [],
				e = 0,
				f = a.length
			if (c !== k) for (; e < f; e++) a[e] && a[e][b] && d.push(a[e][b][c])
			else for (; e < f; e++) a[e] && d.push(a[e][b])
			return d
		},
		ia = function (a, b, c, d) {
			var e = [],
				f = 0,
				g = b.length
			if (d !== k) for (; f < g; f++) a[b[f]][c] && e.push(a[b[f]][c][d])
			else for (; f < g; f++) e.push(a[b[f]][c])
			return e
		},
		W = function (a, b) {
			var c = [],
				d
			b === k ? ((b = 0), (d = a)) : ((d = b), (b = a))
			for (var e = b; e < d; e++) c.push(e)
			return c
		},
		Qb = function (a) {
			for (var b = [], c = 0, d = a.length; c < d; c++) a[c] && b.push(a[c])
			return b
		},
		qa = function (a) {
			var b
			a: {
				if (!(2 > a.length)) {
					b = a.slice().sort()
					for (var c = b[0], d = 1, e = b.length; d < e; d++) {
						if (b[d] === c) {
							b = !1
							break a
						}
						c = b[d]
					}
				}
				b = !0
			}
			if (b) return a.slice()
			b = []
			var e = a.length,
				f,
				g = 0,
				d = 0
			a: for (; d < e; d++) {
				c = a[d]
				for (f = 0; f < g; f++) if (b[f] === c) continue a
				b.push(c)
				g++
			}
			return b
		}
	m.util = {
		throttle: function (a, b) {
			var c = b !== k ? b : 200,
				d,
				e
			return function () {
				var b = this,
					g = +new Date(),
					j = arguments
				d && g < d + c
					? (clearTimeout(e),
						(e = setTimeout(function () {
							d = k
							a.apply(b, j)
						}, c)))
					: ((d = g), a.apply(b, j))
			}
		},
		escapeRegex: function (a) {
			return a.replace($b, '\\$1')
		},
	}
	var A = function (a, b, c) {
			a[b] !== k && (a[c] = a[b])
		},
		ba = /\[.*?\]$/,
		U = /\(\)$/,
		Pa = m.util.escapeRegex,
		va = h('<div>')[0],
		Wb = va.textContent !== k,
		Yb = /<.*?>/g,
		Na = m.util.throttle,
		Rb = [],
		w = Array.prototype,
		ac = function (a) {
			var b,
				c,
				d = m.settings,
				e = h.map(d, function (a) {
					return a.nTable
				})
			if (a) {
				if (a.nTable && a.oApi) return [a]
				if (a.nodeName && 'table' === a.nodeName.toLowerCase()) return (b = h.inArray(a, e)), -1 !== b ? [d[b]] : null
				if (a && 'function' === typeof a.settings) return a.settings().toArray()
				'string' === typeof a ? (c = h(a)) : a instanceof h && (c = a)
			} else return []
			if (c)
				return c
					.map(function () {
						b = h.inArray(this, e)
						return -1 !== b ? d[b] : null
					})
					.toArray()
		}
	s = function (a, b) {
		if (!(this instanceof s)) return new s(a, b)
		var c = [],
			d = function (a) {
				;(a = ac(a)) && (c = c.concat(a))
			}
		if (h.isArray(a)) for (var e = 0, f = a.length; e < f; e++) d(a[e])
		else d(a)
		this.context = qa(c)
		b && h.merge(this, b)
		this.selector = { rows: null, cols: null, opts: null }
		s.extend(this, this, Rb)
	}
	m.Api = s
	h.extend(s.prototype, {
		any: function () {
			return 0 !== this.count()
		},
		concat: w.concat,
		context: [],
		count: function () {
			return this.flatten().length
		},
		each: function (a) {
			for (var b = 0, c = this.length; b < c; b++) a.call(this, this[b], b, this)
			return this
		},
		eq: function (a) {
			var b = this.context
			return b.length > a ? new s(b[a], this[a]) : null
		},
		filter: function (a) {
			var b = []
			if (w.filter) b = w.filter.call(this, a, this)
			else for (var c = 0, d = this.length; c < d; c++) a.call(this, this[c], c, this) && b.push(this[c])
			return new s(this.context, b)
		},
		flatten: function () {
			var a = []
			return new s(this.context, a.concat.apply(a, this.toArray()))
		},
		join: w.join,
		indexOf:
			w.indexOf ||
			function (a, b) {
				for (var c = b || 0, d = this.length; c < d; c++) if (this[c] === a) return c
				return -1
			},
		iterator: function (a, b, c, d) {
			var e = [],
				f,
				g,
				j,
				h,
				n,
				l = this.context,
				m,
				o,
				u = this.selector
			'string' === typeof a && ((d = c), (c = b), (b = a), (a = !1))
			g = 0
			for (j = l.length; g < j; g++) {
				var r = new s(l[g])
				if ('table' === b) (f = c.call(r, l[g], g)), f !== k && e.push(f)
				else if ('columns' === b || 'rows' === b) (f = c.call(r, l[g], this[g], g)), f !== k && e.push(f)
				else if ('column' === b || 'column-rows' === b || 'row' === b || 'cell' === b) {
					o = this[g]
					'column-rows' === b && (m = Ba(l[g], u.opts))
					h = 0
					for (n = o.length; h < n; h++)
						(f = o[h]),
							(f = 'cell' === b ? c.call(r, l[g], f.row, f.column, g, h) : c.call(r, l[g], f, g, h, m)),
							f !== k && e.push(f)
				}
			}
			return e.length || d
				? ((a = new s(l, a ? e.concat.apply([], e) : e)),
					(b = a.selector),
					(b.rows = u.rows),
					(b.cols = u.cols),
					(b.opts = u.opts),
					a)
				: this
		},
		lastIndexOf:
			w.lastIndexOf ||
			function (a, b) {
				return this.indexOf.apply(this.toArray.reverse(), arguments)
			},
		length: 0,
		map: function (a) {
			var b = []
			if (w.map) b = w.map.call(this, a, this)
			else for (var c = 0, d = this.length; c < d; c++) b.push(a.call(this, this[c], c))
			return new s(this.context, b)
		},
		pluck: function (a) {
			return this.map(function (b) {
				return b[a]
			})
		},
		pop: w.pop,
		push: w.push,
		reduce:
			w.reduce ||
			function (a, b) {
				return gb(this, a, b, 0, this.length, 1)
			},
		reduceRight:
			w.reduceRight ||
			function (a, b) {
				return gb(this, a, b, this.length - 1, -1, -1)
			},
		reverse: w.reverse,
		selector: null,
		shift: w.shift,
		slice: function () {
			return new s(this.context, this)
		},
		sort: w.sort,
		splice: w.splice,
		toArray: function () {
			return w.slice.call(this)
		},
		to$: function () {
			return h(this)
		},
		toJQuery: function () {
			return h(this)
		},
		unique: function () {
			return new s(this.context, qa(this))
		},
		unshift: w.unshift,
	})
	s.extend = function (a, b, c) {
		if (c.length && b && (b instanceof s || b.__dt_wrapper)) {
			var d,
				e,
				f,
				g = function (a, b, c) {
					return function () {
						var d = b.apply(a, arguments)
						s.extend(d, d, c.methodExt)
						return d
					}
				}
			d = 0
			for (e = c.length; d < e; d++)
				(f = c[d]),
					(b[f.name] = 'function' === typeof f.val ? g(a, f.val, f) : h.isPlainObject(f.val) ? {} : f.val),
					(b[f.name].__dt_wrapper = !0),
					s.extend(a, b[f.name], f.propExt)
		}
	}
	s.register = o = function (a, b) {
		if (h.isArray(a)) for (var c = 0, d = a.length; c < d; c++) s.register(a[c], b)
		else
			for (var e = a.split('.'), f = Rb, g, j, c = 0, d = e.length; c < d; c++) {
				g = (j = -1 !== e[c].indexOf('()')) ? e[c].replace('()', '') : e[c]
				var i
				a: {
					i = 0
					for (var n = f.length; i < n; i++)
						if (f[i].name === g) {
							i = f[i]
							break a
						}
					i = null
				}
				i || ((i = { name: g, val: {}, methodExt: [], propExt: [] }), f.push(i))
				c === d - 1 ? (i.val = b) : (f = j ? i.methodExt : i.propExt)
			}
	}
	s.registerPlural = u = function (a, b, c) {
		s.register(a, c)
		s.register(b, function () {
			var a = c.apply(this, arguments)
			return a === this ? this : a instanceof s ? (a.length ? (h.isArray(a[0]) ? new s(a.context, a[0]) : a[0]) : k) : a
		})
	}
	o('tables()', function (a) {
		var b
		if (a) {
			b = s
			var c = this.context
			if ('number' === typeof a) a = [c[a]]
			else
				var d = h.map(c, function (a) {
						return a.nTable
					}),
					a = h(d)
						.filter(a)
						.map(function () {
							var a = h.inArray(this, d)
							return c[a]
						})
						.toArray()
			b = new b(a)
		} else b = this
		return b
	})
	o('table()', function (a) {
		var a = this.tables(a),
			b = a.context
		return b.length ? new s(b[0]) : a
	})
	u('tables().nodes()', 'table().node()', function () {
		return this.iterator(
			'table',
			function (a) {
				return a.nTable
			},
			1
		)
	})
	u('tables().body()', 'table().body()', function () {
		return this.iterator(
			'table',
			function (a) {
				return a.nTBody
			},
			1
		)
	})
	u('tables().header()', 'table().header()', function () {
		return this.iterator(
			'table',
			function (a) {
				return a.nTHead
			},
			1
		)
	})
	u('tables().footer()', 'table().footer()', function () {
		return this.iterator(
			'table',
			function (a) {
				return a.nTFoot
			},
			1
		)
	})
	u('tables().containers()', 'table().container()', function () {
		return this.iterator(
			'table',
			function (a) {
				return a.nTableWrapper
			},
			1
		)
	})
	o('draw()', function (a) {
		return this.iterator('table', function (b) {
			'page' === a ? N(b) : ('string' === typeof a && (a = 'full-hold' === a ? !1 : !0), S(b, !1 === a))
		})
	})
	o('page()', function (a) {
		return a === k
			? this.page.info().page
			: this.iterator('table', function (b) {
					Sa(b, a)
				})
	})
	o('page.info()', function () {
		if (0 === this.context.length) return k
		var a = this.context[0],
			b = a._iDisplayStart,
			c = a.oFeatures.bPaginate ? a._iDisplayLength : -1,
			d = a.fnRecordsDisplay(),
			e = -1 === c
		return {
			page: e ? 0 : Math.floor(b / c),
			pages: e ? 1 : Math.ceil(d / c),
			start: b,
			end: a.fnDisplayEnd(),
			length: c,
			recordsTotal: a.fnRecordsTotal(),
			recordsDisplay: d,
			serverSide: 'ssp' === y(a),
		}
	})
	o('page.len()', function (a) {
		return a === k
			? 0 !== this.context.length
				? this.context[0]._iDisplayLength
				: k
			: this.iterator('table', function (b) {
					Qa(b, a)
				})
	})
	var Sb = function (a, b, c) {
		if (c) {
			var d = new s(a)
			d.one('draw', function () {
				c(d.ajax.json())
			})
		}
		if ('ssp' == y(a)) S(a, b)
		else {
			C(a, !0)
			var e = a.jqXHR
			e && 4 !== e.readyState && e.abort()
			sa(a, [], function (c) {
				na(a)
				for (var c = ta(a, c), d = 0, e = c.length; d < e; d++) M(a, c[d])
				S(a, b)
				C(a, !1)
			})
		}
	}
	o('ajax.json()', function () {
		var a = this.context
		if (0 < a.length) return a[0].json
	})
	o('ajax.params()', function () {
		var a = this.context
		if (0 < a.length) return a[0].oAjaxData
	})
	o('ajax.reload()', function (a, b) {
		return this.iterator('table', function (c) {
			Sb(c, !1 === b, a)
		})
	})
	o('ajax.url()', function (a) {
		var b = this.context
		if (a === k) {
			if (0 === b.length) return k
			b = b[0]
			return b.ajax ? (h.isPlainObject(b.ajax) ? b.ajax.url : b.ajax) : b.sAjaxSource
		}
		return this.iterator('table', function (b) {
			h.isPlainObject(b.ajax) ? (b.ajax.url = a) : (b.ajax = a)
		})
	})
	o('ajax.url().load()', function (a, b) {
		return this.iterator('table', function (c) {
			Sb(c, !1 === b, a)
		})
	})
	var Za = function (a, b, c, d, e) {
			var f = [],
				g,
				j,
				i,
				n,
				l,
				m
			i = typeof b
			if (!b || 'string' === i || 'function' === i || b.length === k) b = [b]
			i = 0
			for (n = b.length; i < n; i++) {
				j = b[i] && b[i].split && !b[i].match(/[\[\(:]/) ? b[i].split(',') : [b[i]]
				l = 0
				for (m = j.length; l < m; l++)
					(g = c('string' === typeof j[l] ? h.trim(j[l]) : j[l])) && g.length && (f = f.concat(g))
			}
			a = x.selector[a]
			if (a.length) {
				i = 0
				for (n = a.length; i < n; i++) f = a[i](d, e, f)
			}
			return qa(f)
		},
		$a = function (a) {
			a || (a = {})
			a.filter && a.search === k && (a.search = a.filter)
			return h.extend({ search: 'none', order: 'current', page: 'all' }, a)
		},
		ab = function (a) {
			for (var b = 0, c = a.length; b < c; b++)
				if (0 < a[b].length) return (a[0] = a[b]), (a[0].length = 1), (a.length = 1), (a.context = [a.context[b]]), a
			a.length = 0
			return a
		},
		Ba = function (a, b) {
			var c,
				d,
				e,
				f = [],
				g = a.aiDisplay
			c = a.aiDisplayMaster
			var j = b.search
			d = b.order
			e = b.page
			if ('ssp' == y(a)) return 'removed' === j ? [] : W(0, c.length)
			if ('current' == e) {
				c = a._iDisplayStart
				for (d = a.fnDisplayEnd(); c < d; c++) f.push(g[c])
			} else if ('current' == d || 'applied' == d)
				f =
					'none' == j
						? c.slice()
						: 'applied' == j
							? g.slice()
							: h.map(c, function (a) {
									return -1 === h.inArray(a, g) ? a : null
								})
			else if ('index' == d || 'original' == d) {
				c = 0
				for (d = a.aoData.length; c < d; c++)
					'none' == j
						? f.push(c)
						: ((e = h.inArray(c, g)), ((-1 === e && 'removed' == j) || (0 <= e && 'applied' == j)) && f.push(c))
			}
			return f
		}
	o('rows()', function (a, b) {
		a === k ? (a = '') : h.isPlainObject(a) && ((b = a), (a = ''))
		var b = $a(b),
			c = this.iterator(
				'table',
				function (c) {
					var e = b,
						f
					return Za(
						'row',
						a,
						function (a) {
							var b = Nb(a)
							if (b !== null && !e) return [b]
							f || (f = Ba(c, e))
							if (b !== null && h.inArray(b, f) !== -1) return [b]
							if (a === null || a === k || a === '') return f
							if (typeof a === 'function')
								return h.map(f, function (b) {
									var e = c.aoData[b]
									return a(b, e._aData, e.nTr) ? b : null
								})
							b = Qb(ia(c.aoData, f, 'nTr'))
							if (a.nodeName) {
								if (a._DT_RowIndex !== k) return [a._DT_RowIndex]
								if (a._DT_CellIndex) return [a._DT_CellIndex.row]
								b = h(a).closest('*[data-dt-row]')
								return b.length ? [b.data('dt-row')] : []
							}
							if (typeof a === 'string' && a.charAt(0) === '#') {
								var i = c.aIds[a.replace(/^#/, '')]
								if (i !== k) return [i.idx]
							}
							return h(b)
								.filter(a)
								.map(function () {
									return this._DT_RowIndex
								})
								.toArray()
						},
						c,
						e
					)
				},
				1
			)
		c.selector.rows = a
		c.selector.opts = b
		return c
	})
	o('rows().nodes()', function () {
		return this.iterator(
			'row',
			function (a, b) {
				return a.aoData[b].nTr || k
			},
			1
		)
	})
	o('rows().data()', function () {
		return this.iterator(
			!0,
			'rows',
			function (a, b) {
				return ia(a.aoData, b, '_aData')
			},
			1
		)
	})
	u('rows().cache()', 'row().cache()', function (a) {
		return this.iterator(
			'row',
			function (b, c) {
				var d = b.aoData[c]
				return 'search' === a ? d._aFilterData : d._aSortData
			},
			1
		)
	})
	u('rows().invalidate()', 'row().invalidate()', function (a) {
		return this.iterator('row', function (b, c) {
			ca(b, c, a)
		})
	})
	u('rows().indexes()', 'row().index()', function () {
		return this.iterator(
			'row',
			function (a, b) {
				return b
			},
			1
		)
	})
	u('rows().ids()', 'row().id()', function (a) {
		for (var b = [], c = this.context, d = 0, e = c.length; d < e; d++)
			for (var f = 0, g = this[d].length; f < g; f++) {
				var h = c[d].rowIdFn(c[d].aoData[this[d][f]]._aData)
				b.push((!0 === a ? '#' : '') + h)
			}
		return new s(c, b)
	})
	u('rows().remove()', 'row().remove()', function () {
		var a = this
		this.iterator('row', function (b, c, d) {
			var e = b.aoData,
				f = e[c],
				g,
				h,
				i,
				n,
				l
			e.splice(c, 1)
			g = 0
			for (h = e.length; g < h; g++)
				if (((i = e[g]), (l = i.anCells), null !== i.nTr && (i.nTr._DT_RowIndex = g), null !== l)) {
					i = 0
					for (n = l.length; i < n; i++) l[i]._DT_CellIndex.row = g
				}
			oa(b.aiDisplayMaster, c)
			oa(b.aiDisplay, c)
			oa(a[d], c, !1)
			0 < b._iRecordsDisplay && b._iRecordsDisplay--
			Ra(b)
			c = b.rowIdFn(f._aData)
			c !== k && delete b.aIds[c]
		})
		this.iterator('table', function (a) {
			for (var c = 0, d = a.aoData.length; c < d; c++) a.aoData[c].idx = c
		})
		return this
	})
	o('rows.add()', function (a) {
		var b = this.iterator(
				'table',
				function (b) {
					var c,
						f,
						g,
						h = []
					f = 0
					for (g = a.length; f < g; f++)
						(c = a[f]), c.nodeName && 'TR' === c.nodeName.toUpperCase() ? h.push(ma(b, c)[0]) : h.push(M(b, c))
					return h
				},
				1
			),
			c = this.rows(-1)
		c.pop()
		h.merge(c, b)
		return c
	})
	o('row()', function (a, b) {
		return ab(this.rows(a, b))
	})
	o('row().data()', function (a) {
		var b = this.context
		if (a === k) return b.length && this.length ? b[0].aoData[this[0]]._aData : k
		b[0].aoData[this[0]]._aData = a
		ca(b[0], this[0], 'data')
		return this
	})
	o('row().node()', function () {
		var a = this.context
		return a.length && this.length ? a[0].aoData[this[0]].nTr || null : null
	})
	o('row.add()', function (a) {
		a instanceof h && a.length && (a = a[0])
		var b = this.iterator('table', function (b) {
			return a.nodeName && 'TR' === a.nodeName.toUpperCase() ? ma(b, a)[0] : M(b, a)
		})
		return this.row(b[0])
	})
	var bb = function (a, b) {
			var c = a.context
			if (c.length && (c = c[0].aoData[b !== k ? b : a[0]]) && c._details)
				c._details.remove(), (c._detailsShow = k), (c._details = k)
		},
		Tb = function (a, b) {
			var c = a.context
			if (c.length && a.length) {
				var d = c[0].aoData[a[0]]
				if (d._details) {
					;(d._detailsShow = b) ? d._details.insertAfter(d.nTr) : d._details.detach()
					var e = c[0],
						f = new s(e),
						g = e.aoData
					f.off('draw.dt.DT_details column-visibility.dt.DT_details destroy.dt.DT_details')
					0 < D(g, '_details').length &&
						(f.on('draw.dt.DT_details', function (a, b) {
							e === b &&
								f
									.rows({ page: 'current' })
									.eq(0)
									.each(function (a) {
										a = g[a]
										a._detailsShow && a._details.insertAfter(a.nTr)
									})
						}),
						f.on('column-visibility.dt.DT_details', function (a, b) {
							if (e === b)
								for (var c, d = aa(b), f = 0, h = g.length; f < h; f++)
									(c = g[f]), c._details && c._details.children('td[colspan]').attr('colspan', d)
						}),
						f.on('destroy.dt.DT_details', function (a, b) {
							if (e === b) for (var c = 0, d = g.length; c < d; c++) g[c]._details && bb(f, c)
						}))
				}
			}
		}
	o('row().child()', function (a, b) {
		var c = this.context
		if (a === k) return c.length && this.length ? c[0].aoData[this[0]]._details : k
		if (!0 === a) this.child.show()
		else if (!1 === a) bb(this)
		else if (c.length && this.length) {
			var d = c[0],
				c = c[0].aoData[this[0]],
				e = [],
				f = function (a, b) {
					if (h.isArray(a) || a instanceof h) for (var c = 0, k = a.length; c < k; c++) f(a[c], b)
					else
						a.nodeName && 'tr' === a.nodeName.toLowerCase()
							? e.push(a)
							: ((c = h('<tr><td/></tr>').addClass(b)),
								(h('td', c).addClass(b).html(a)[0].colSpan = aa(d)),
								e.push(c[0]))
				}
			f(a, b)
			c._details && c._details.detach()
			c._details = h(e)
			c._detailsShow && c._details.insertAfter(c.nTr)
		}
		return this
	})
	o(['row().child.show()', 'row().child().show()'], function () {
		Tb(this, !0)
		return this
	})
	o(['row().child.hide()', 'row().child().hide()'], function () {
		Tb(this, !1)
		return this
	})
	o(['row().child.remove()', 'row().child().remove()'], function () {
		bb(this)
		return this
	})
	o('row().child.isShown()', function () {
		var a = this.context
		return a.length && this.length ? a[0].aoData[this[0]]._detailsShow || !1 : !1
	})
	var bc = /^([^:]+):(name|visIdx|visible)$/,
		Ub = function (a, b, c, d, e) {
			for (var c = [], d = 0, f = e.length; d < f; d++) c.push(B(a, e[d], b))
			return c
		}
	o('columns()', function (a, b) {
		a === k ? (a = '') : h.isPlainObject(a) && ((b = a), (a = ''))
		var b = $a(b),
			c = this.iterator(
				'table',
				function (c) {
					var e = a,
						f = b,
						g = c.aoColumns,
						j = D(g, 'sName'),
						i = D(g, 'nTh')
					return Za(
						'column',
						e,
						function (a) {
							var b = Nb(a)
							if (a === '') return W(g.length)
							if (b !== null) return [b >= 0 ? b : g.length + b]
							if (typeof a === 'function') {
								var e = Ba(c, f)
								return h.map(g, function (b, f) {
									return a(f, Ub(c, f, 0, 0, e), i[f]) ? f : null
								})
							}
							var k = typeof a === 'string' ? a.match(bc) : ''
							if (k)
								switch (k[2]) {
									case 'visIdx':
									case 'visible':
										b = parseInt(k[1], 10)
										if (b < 0) {
											var m = h.map(g, function (a, b) {
												return a.bVisible ? b : null
											})
											return [m[m.length + b]]
										}
										return [Z(c, b)]
									case 'name':
										return h.map(j, function (a, b) {
											return a === k[1] ? b : null
										})
									default:
										return []
								}
							if (a.nodeName && a._DT_CellIndex) return [a._DT_CellIndex.column]
							b = h(i)
								.filter(a)
								.map(function () {
									return h.inArray(this, i)
								})
								.toArray()
							if (b.length || !a.nodeName) return b
							b = h(a).closest('*[data-dt-column]')
							return b.length ? [b.data('dt-column')] : []
						},
						c,
						f
					)
				},
				1
			)
		c.selector.cols = a
		c.selector.opts = b
		return c
	})
	u('columns().header()', 'column().header()', function () {
		return this.iterator(
			'column',
			function (a, b) {
				return a.aoColumns[b].nTh
			},
			1
		)
	})
	u('columns().footer()', 'column().footer()', function () {
		return this.iterator(
			'column',
			function (a, b) {
				return a.aoColumns[b].nTf
			},
			1
		)
	})
	u('columns().data()', 'column().data()', function () {
		return this.iterator('column-rows', Ub, 1)
	})
	u('columns().dataSrc()', 'column().dataSrc()', function () {
		return this.iterator(
			'column',
			function (a, b) {
				return a.aoColumns[b].mData
			},
			1
		)
	})
	u('columns().cache()', 'column().cache()', function (a) {
		return this.iterator(
			'column-rows',
			function (b, c, d, e, f) {
				return ia(b.aoData, f, 'search' === a ? '_aFilterData' : '_aSortData', c)
			},
			1
		)
	})
	u('columns().nodes()', 'column().nodes()', function () {
		return this.iterator(
			'column-rows',
			function (a, b, c, d, e) {
				return ia(a.aoData, e, 'anCells', b)
			},
			1
		)
	})
	u('columns().visible()', 'column().visible()', function (a, b) {
		var c = this.iterator('column', function (b, c) {
			if (a === k) return b.aoColumns[c].bVisible
			var f = b.aoColumns,
				g = f[c],
				j = b.aoData,
				i,
				n,
				l
			if (a !== k && g.bVisible !== a) {
				if (a) {
					var m = h.inArray(!0, D(f, 'bVisible'), c + 1)
					i = 0
					for (n = j.length; i < n; i++) (l = j[i].nTr), (f = j[i].anCells), l && l.insertBefore(f[c], f[m] || null)
				} else h(D(b.aoData, 'anCells', c)).detach()
				g.bVisible = a
				ea(b, b.aoHeader)
				ea(b, b.aoFooter)
				xa(b)
			}
		})
		a !== k &&
			(this.iterator('column', function (c, e) {
				r(c, null, 'column-visibility', [c, e, a, b])
			}),
			(b === k || b) && this.columns.adjust())
		return c
	})
	u('columns().indexes()', 'column().index()', function (a) {
		return this.iterator(
			'column',
			function (b, c) {
				return 'visible' === a ? $(b, c) : c
			},
			1
		)
	})
	o('columns.adjust()', function () {
		return this.iterator(
			'table',
			function (a) {
				Y(a)
			},
			1
		)
	})
	o('column.index()', function (a, b) {
		if (0 !== this.context.length) {
			var c = this.context[0]
			if ('fromVisible' === a || 'toData' === a) return Z(c, b)
			if ('fromData' === a || 'toVisible' === a) return $(c, b)
		}
	})
	o('column()', function (a, b) {
		return ab(this.columns(a, b))
	})
	o('cells()', function (a, b, c) {
		h.isPlainObject(a) && (a.row === k ? ((c = a), (a = null)) : ((c = b), (b = null)))
		h.isPlainObject(b) && ((c = b), (b = null))
		if (null === b || b === k)
			return this.iterator('table', function (b) {
				var d = a,
					e = $a(c),
					f = b.aoData,
					g = Ba(b, e),
					j = Qb(ia(f, g, 'anCells')),
					i = h([].concat.apply([], j)),
					l,
					n = b.aoColumns.length,
					m,
					o,
					u,
					s,
					r,
					v
				return Za(
					'cell',
					d,
					function (a) {
						var c = typeof a === 'function'
						if (a === null || a === k || c) {
							m = []
							o = 0
							for (u = g.length; o < u; o++) {
								l = g[o]
								for (s = 0; s < n; s++) {
									r = { row: l, column: s }
									if (c) {
										v = f[l]
										a(r, B(b, l, s), v.anCells ? v.anCells[s] : null) && m.push(r)
									} else m.push(r)
								}
							}
							return m
						}
						if (h.isPlainObject(a)) return [a]
						c = i
							.filter(a)
							.map(function (a, b) {
								return { row: b._DT_CellIndex.row, column: b._DT_CellIndex.column }
							})
							.toArray()
						if (c.length || !a.nodeName) return c
						v = h(a).closest('*[data-dt-row]')
						return v.length ? [{ row: v.data('dt-row'), column: v.data('dt-column') }] : []
					},
					b,
					e
				)
			})
		var d = this.columns(b, c),
			e = this.rows(a, c),
			f,
			g,
			j,
			i,
			n,
			l = this.iterator(
				'table',
				function (a, b) {
					f = []
					g = 0
					for (j = e[b].length; g < j; g++) {
						i = 0
						for (n = d[b].length; i < n; i++) f.push({ row: e[b][g], column: d[b][i] })
					}
					return f
				},
				1
			)
		h.extend(l.selector, { cols: b, rows: a, opts: c })
		return l
	})
	u('cells().nodes()', 'cell().node()', function () {
		return this.iterator(
			'cell',
			function (a, b, c) {
				return (a = a.aoData[b]) && a.anCells ? a.anCells[c] : k
			},
			1
		)
	})
	o('cells().data()', function () {
		return this.iterator(
			'cell',
			function (a, b, c) {
				return B(a, b, c)
			},
			1
		)
	})
	u('cells().cache()', 'cell().cache()', function (a) {
		a = 'search' === a ? '_aFilterData' : '_aSortData'
		return this.iterator(
			'cell',
			function (b, c, d) {
				return b.aoData[c][a][d]
			},
			1
		)
	})
	u('cells().render()', 'cell().render()', function (a) {
		return this.iterator(
			'cell',
			function (b, c, d) {
				return B(b, c, d, a)
			},
			1
		)
	})
	u('cells().indexes()', 'cell().index()', function () {
		return this.iterator(
			'cell',
			function (a, b, c) {
				return { row: b, column: c, columnVisible: $(a, c) }
			},
			1
		)
	})
	u('cells().invalidate()', 'cell().invalidate()', function (a) {
		return this.iterator('cell', function (b, c, d) {
			ca(b, c, a, d)
		})
	})
	o('cell()', function (a, b, c) {
		return ab(this.cells(a, b, c))
	})
	o('cell().data()', function (a) {
		var b = this.context,
			c = this[0]
		if (a === k) return b.length && c.length ? B(b[0], c[0].row, c[0].column) : k
		ib(b[0], c[0].row, c[0].column, a)
		ca(b[0], c[0].row, 'data', c[0].column)
		return this
	})
	o('order()', function (a, b) {
		var c = this.context
		if (a === k) return 0 !== c.length ? c[0].aaSorting : k
		'number' === typeof a ? (a = [[a, b]]) : a.length && !h.isArray(a[0]) && (a = Array.prototype.slice.call(arguments))
		return this.iterator('table', function (b) {
			b.aaSorting = a.slice()
		})
	})
	o('order.listener()', function (a, b, c) {
		return this.iterator('table', function (d) {
			La(d, a, b, c)
		})
	})
	o('order.fixed()', function (a) {
		if (!a) {
			var b = this.context,
				b = b.length ? b[0].aaSortingFixed : k
			return h.isArray(b) ? { pre: b } : b
		}
		return this.iterator('table', function (b) {
			b.aaSortingFixed = h.extend(!0, {}, a)
		})
	})
	o(['columns().order()', 'column().order()'], function (a) {
		var b = this
		return this.iterator('table', function (c, d) {
			var e = []
			h.each(b[d], function (b, c) {
				e.push([c, a])
			})
			c.aaSorting = e
		})
	})
	o('search()', function (a, b, c, d) {
		var e = this.context
		return a === k
			? 0 !== e.length
				? e[0].oPreviousSearch.sSearch
				: k
			: this.iterator('table', function (e) {
					e.oFeatures.bFilter &&
						fa(
							e,
							h.extend({}, e.oPreviousSearch, {
								sSearch: a + '',
								bRegex: null === b ? !1 : b,
								bSmart: null === c ? !0 : c,
								bCaseInsensitive: null === d ? !0 : d,
							}),
							1
						)
				})
	})
	u('columns().search()', 'column().search()', function (a, b, c, d) {
		return this.iterator('column', function (e, f) {
			var g = e.aoPreSearchCols
			if (a === k) return g[f].sSearch
			e.oFeatures.bFilter &&
				(h.extend(g[f], {
					sSearch: a + '',
					bRegex: null === b ? !1 : b,
					bSmart: null === c ? !0 : c,
					bCaseInsensitive: null === d ? !0 : d,
				}),
				fa(e, e.oPreviousSearch, 1))
		})
	})
	o('state()', function () {
		return this.context.length ? this.context[0].oSavedState : null
	})
	o('state.clear()', function () {
		return this.iterator('table', function (a) {
			a.fnStateSaveCallback.call(a.oInstance, a, {})
		})
	})
	o('state.loaded()', function () {
		return this.context.length ? this.context[0].oLoadedState : null
	})
	o('state.save()', function () {
		return this.iterator('table', function (a) {
			xa(a)
		})
	})
	m.versionCheck = m.fnVersionCheck = function (a) {
		for (var b = m.version.split('.'), a = a.split('.'), c, d, e = 0, f = a.length; e < f; e++)
			if (((c = parseInt(b[e], 10) || 0), (d = parseInt(a[e], 10) || 0), c !== d)) return c > d
		return !0
	}
	m.isDataTable = m.fnIsDataTable = function (a) {
		var b = h(a).get(0),
			c = !1
		if (a instanceof m.Api) return !0
		h.each(m.settings, function (a, e) {
			var f = e.nScrollHead ? h('table', e.nScrollHead)[0] : null,
				g = e.nScrollFoot ? h('table', e.nScrollFoot)[0] : null
			if (e.nTable === b || f === b || g === b) c = !0
		})
		return c
	}
	m.tables = m.fnTables = function (a) {
		var b = !1
		h.isPlainObject(a) && ((b = a.api), (a = a.visible))
		var c = h.map(m.settings, function (b) {
			if (!a || (a && h(b.nTable).is(':visible'))) return b.nTable
		})
		return b ? new s(c) : c
	}
	m.camelToHungarian = I
	o('$()', function (a, b) {
		var c = this.rows(b).nodes(),
			c = h(c)
		return h([].concat(c.filter(a).toArray(), c.find(a).toArray()))
	})
	h.each(['on', 'one', 'off'], function (a, b) {
		o(b + '()', function () {
			var a = Array.prototype.slice.call(arguments)
			a[0] = h
				.map(a[0].split(/\s/), function (a) {
					return !a.match(/\.dt\b/) ? a + '.dt' : a
				})
				.join(' ')
			var d = h(this.tables().nodes())
			d[b].apply(d, a)
			return this
		})
	})
	o('clear()', function () {
		return this.iterator('table', function (a) {
			na(a)
		})
	})
	o('settings()', function () {
		return new s(this.context, this.context)
	})
	o('init()', function () {
		var a = this.context
		return a.length ? a[0].oInit : null
	})
	o('data()', function () {
		return this.iterator('table', function (a) {
			return D(a.aoData, '_aData')
		}).flatten()
	})
	o('destroy()', function (a) {
		a = a || !1
		return this.iterator('table', function (b) {
			var c = b.nTableWrapper.parentNode,
				d = b.oClasses,
				e = b.nTable,
				f = b.nTBody,
				g = b.nTHead,
				j = b.nTFoot,
				i = h(e),
				f = h(f),
				k = h(b.nTableWrapper),
				l = h.map(b.aoData, function (a) {
					return a.nTr
				}),
				o
			b.bDestroying = !0
			r(b, 'aoDestroyCallback', 'destroy', [b])
			a || new s(b).columns().visible(!0)
			k.off('.DT').find(':not(tbody *)').off('.DT')
			h(E).off('.DT-' + b.sInstance)
			e != g.parentNode && (i.children('thead').detach(), i.append(g))
			j && e != j.parentNode && (i.children('tfoot').detach(), i.append(j))
			b.aaSorting = []
			b.aaSortingFixed = []
			wa(b)
			h(l).removeClass(b.asStripeClasses.join(' '))
			h('th, td', g).removeClass(d.sSortable + ' ' + d.sSortableAsc + ' ' + d.sSortableDesc + ' ' + d.sSortableNone)
			f.children().detach()
			f.append(l)
			g = a ? 'remove' : 'detach'
			i[g]()
			k[g]()
			!a &&
				c &&
				(c.insertBefore(e, b.nTableReinsertBefore),
				i.css('width', b.sDestroyWidth).removeClass(d.sTable),
				(o = b.asDestroyStripes.length) &&
					f.children().each(function (a) {
						h(this).addClass(b.asDestroyStripes[a % o])
					}))
			c = h.inArray(b, m.settings)
			;-1 !== c && m.settings.splice(c, 1)
		})
	})
	h.each(['column', 'row', 'cell'], function (a, b) {
		o(b + 's().every()', function (a) {
			var d = this.selector.opts,
				e = this
			return this.iterator(b, function (f, g, h, i, n) {
				a.call(e[b](g, 'cell' === b ? h : d, 'cell' === b ? d : k), g, h, i, n)
			})
		})
	})
	o('i18n()', function (a, b, c) {
		var d = this.context[0],
			a = Q(a)(d.oLanguage)
		a === k && (a = b)
		c !== k && h.isPlainObject(a) && (a = a[c] !== k ? a[c] : a._)
		return a.replace('%d', c)
	})
	m.version = '1.10.16'
	m.settings = []
	m.models = {}
	m.models.oSearch = { bCaseInsensitive: !0, sSearch: '', bRegex: !1, bSmart: !0 }
	m.models.oRow = {
		nTr: null,
		anCells: null,
		_aData: [],
		_aSortData: null,
		_aFilterData: null,
		_sFilterRow: null,
		_sRowStripe: '',
		src: null,
		idx: -1,
	}
	m.models.oColumn = {
		idx: null,
		aDataSort: null,
		asSorting: null,
		bSearchable: null,
		bSortable: null,
		bVisible: null,
		_sManualType: null,
		_bAttrSrc: !1,
		fnCreatedCell: null,
		fnGetData: null,
		fnSetData: null,
		mData: null,
		mRender: null,
		nTh: null,
		nTf: null,
		sClass: null,
		sContentPadding: null,
		sDefaultContent: null,
		sName: null,
		sSortDataType: 'std',
		sSortingClass: null,
		sSortingClassJUI: null,
		sTitle: null,
		sType: null,
		sWidth: null,
		sWidthOrig: null,
	}
	m.defaults = {
		aaData: null,
		aaSorting: [[0, 'asc']],
		aaSortingFixed: [],
		ajax: null,
		aLengthMenu: [10, 25, 50, 100],
		aoColumns: null,
		aoColumnDefs: null,
		aoSearchCols: [],
		asStripeClasses: null,
		bAutoWidth: !0,
		bDeferRender: !1,
		bDestroy: !1,
		bFilter: !0,
		bInfo: !0,
		bLengthChange: !0,
		bPaginate: !0,
		bProcessing: !1,
		bRetrieve: !1,
		bScrollCollapse: !1,
		bServerSide: !1,
		bSort: !0,
		bSortMulti: !0,
		bSortCellsTop: !1,
		bSortClasses: !0,
		bStateSave: !1,
		fnCreatedRow: null,
		fnDrawCallback: null,
		fnFooterCallback: null,
		fnFormatNumber: function (a) {
			return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, this.oLanguage.sThousands)
		},
		fnHeaderCallback: null,
		fnInfoCallback: null,
		fnInitComplete: null,
		fnPreDrawCallback: null,
		fnRowCallback: null,
		fnServerData: null,
		fnServerParams: null,
		fnStateLoadCallback: function (a) {
			try {
				return JSON.parse(
					(-1 === a.iStateDuration ? sessionStorage : localStorage).getItem(
						'DataTables_' + a.sInstance + '_' + location.pathname
					)
				)
			} catch (b) {}
		},
		fnStateLoadParams: null,
		fnStateLoaded: null,
		fnStateSaveCallback: function (a, b) {
			try {
				;(-1 === a.iStateDuration ? sessionStorage : localStorage).setItem(
					'DataTables_' + a.sInstance + '_' + location.pathname,
					JSON.stringify(b)
				)
			} catch (c) {}
		},
		fnStateSaveParams: null,
		iStateDuration: 7200,
		iDeferLoading: null,
		iDisplayLength: 10,
		iDisplayStart: 0,
		iTabIndex: 0,
		oClasses: {},
		oLanguage: {
			oAria: {
				sSortAscending: ': activate to sort column ascending',
				sSortDescending: ': activate to sort column descending',
			},
			oPaginate: { sFirst: 'First', sLast: 'Last', sNext: 'Next', sPrevious: 'Previous' },
			sEmptyTable: 'No data available in table',
			sInfo: 'Showing _START_ to _END_ of _TOTAL_ entries',
			sInfoEmpty: 'Showing 0 to 0 of 0 entries',
			sInfoFiltered: '(filtered from _MAX_ total entries)',
			sInfoPostFix: '',
			sDecimal: '',
			sThousands: ',',
			sLengthMenu: 'Show _MENU_ entries',
			sLoadingRecords: 'Loading...',
			sProcessing: 'Processing...',
			sSearch: 'Search:',
			sSearchPlaceholder: '',
			sUrl: '',
			sZeroRecords: 'No matching records found',
		},
		oSearch: h.extend({}, m.models.oSearch),
		sAjaxDataProp: 'data',
		sAjaxSource: null,
		sDom: 'lfrtip',
		searchDelay: null,
		sPaginationType: 'simple_numbers',
		sScrollX: '',
		sScrollXInner: '',
		sScrollY: '',
		sServerMethod: 'GET',
		renderer: null,
		rowId: 'DT_RowId',
	}
	X(m.defaults)
	m.defaults.column = {
		aDataSort: null,
		iDataSort: -1,
		asSorting: ['asc', 'desc'],
		bSearchable: !0,
		bSortable: !0,
		bVisible: !0,
		fnCreatedCell: null,
		mData: null,
		mRender: null,
		sCellType: 'td',
		sClass: '',
		sContentPadding: '',
		sDefaultContent: null,
		sName: '',
		sSortDataType: 'std',
		sTitle: null,
		sType: null,
		sWidth: null,
	}
	X(m.defaults.column)
	m.models.oSettings = {
		oFeatures: {
			bAutoWidth: null,
			bDeferRender: null,
			bFilter: null,
			bInfo: null,
			bLengthChange: null,
			bPaginate: null,
			bProcessing: null,
			bServerSide: null,
			bSort: null,
			bSortMulti: null,
			bSortClasses: null,
			bStateSave: null,
		},
		oScroll: { bCollapse: null, iBarWidth: 0, sX: null, sXInner: null, sY: null },
		oLanguage: { fnInfoCallback: null },
		oBrowser: { bScrollOversize: !1, bScrollbarLeft: !1, bBounding: !1, barWidth: 0 },
		ajax: null,
		aanFeatures: [],
		aoData: [],
		aiDisplay: [],
		aiDisplayMaster: [],
		aIds: {},
		aoColumns: [],
		aoHeader: [],
		aoFooter: [],
		oPreviousSearch: {},
		aoPreSearchCols: [],
		aaSorting: null,
		aaSortingFixed: [],
		asStripeClasses: null,
		asDestroyStripes: [],
		sDestroyWidth: 0,
		aoRowCallback: [],
		aoHeaderCallback: [],
		aoFooterCallback: [],
		aoDrawCallback: [],
		aoRowCreatedCallback: [],
		aoPreDrawCallback: [],
		aoInitComplete: [],
		aoStateSaveParams: [],
		aoStateLoadParams: [],
		aoStateLoaded: [],
		sTableId: '',
		nTable: null,
		nTHead: null,
		nTFoot: null,
		nTBody: null,
		nTableWrapper: null,
		bDeferLoading: !1,
		bInitialised: !1,
		aoOpenRows: [],
		sDom: null,
		searchDelay: null,
		sPaginationType: 'two_button',
		iStateDuration: 0,
		aoStateSave: [],
		aoStateLoad: [],
		oSavedState: null,
		oLoadedState: null,
		sAjaxSource: null,
		sAjaxDataProp: null,
		bAjaxDataGet: !0,
		jqXHR: null,
		json: k,
		oAjaxData: k,
		fnServerData: null,
		aoServerParams: [],
		sServerMethod: null,
		fnFormatNumber: null,
		aLengthMenu: null,
		iDraw: 0,
		bDrawing: !1,
		iDrawError: -1,
		_iDisplayLength: 10,
		_iDisplayStart: 0,
		_iRecordsTotal: 0,
		_iRecordsDisplay: 0,
		oClasses: {},
		bFiltered: !1,
		bSorted: !1,
		bSortCellsTop: null,
		oInit: null,
		aoDestroyCallback: [],
		fnRecordsTotal: function () {
			return 'ssp' == y(this) ? 1 * this._iRecordsTotal : this.aiDisplayMaster.length
		},
		fnRecordsDisplay: function () {
			return 'ssp' == y(this) ? 1 * this._iRecordsDisplay : this.aiDisplay.length
		},
		fnDisplayEnd: function () {
			var a = this._iDisplayLength,
				b = this._iDisplayStart,
				c = b + a,
				d = this.aiDisplay.length,
				e = this.oFeatures,
				f = e.bPaginate
			return e.bServerSide
				? !1 === f || -1 === a
					? b + d
					: Math.min(b + a, this._iRecordsDisplay)
				: !f || c > d || -1 === a
					? d
					: c
		},
		oInstance: null,
		sInstance: null,
		iTabIndex: 0,
		nScrollHead: null,
		nScrollFoot: null,
		aLastSort: [],
		oPlugins: {},
		rowIdFn: null,
		rowId: null,
	}
	m.ext = x = {
		buttons: {},
		classes: {},
		build: 'bs4/jq-3.2.1/dt-1.10.16',
		errMode: 'alert',
		feature: [],
		search: [],
		selector: { cell: [], column: [], row: [] },
		internal: {},
		legacy: { ajax: null },
		pager: {},
		renderer: { pageButton: {}, header: {} },
		order: {},
		type: { detect: [], search: {}, order: {} },
		_unique: 0,
		fnVersionCheck: m.fnVersionCheck,
		iApiIndex: 0,
		oJUIClasses: {},
		sVersion: m.version,
	}
	h.extend(x, {
		afnFiltering: x.search,
		aTypes: x.type.detect,
		ofnSearch: x.type.search,
		oSort: x.type.order,
		afnSortData: x.order,
		aoFeatures: x.feature,
		oApi: x.internal,
		oStdClasses: x.classes,
		oPagination: x.pager,
	})
	h.extend(m.ext.classes, {
		sTable: 'dataTable',
		sNoFooter: 'no-footer',
		sPageButton: 'paginate_button',
		sPageButtonActive: 'current',
		sPageButtonDisabled: 'disabled',
		sStripeOdd: 'odd',
		sStripeEven: 'even',
		sRowEmpty: 'dataTables_empty',
		sWrapper: 'dataTables_wrapper',
		sFilter: 'dataTables_filter',
		sInfo: 'dataTables_info',
		sPaging: 'dataTables_paginate paging_',
		sLength: 'dataTables_length',
		sProcessing: 'dataTables_processing',
		sSortAsc: 'sorting_asc',
		sSortDesc: 'sorting_desc',
		sSortable: 'sorting',
		sSortableAsc: 'sorting_asc_disabled',
		sSortableDesc: 'sorting_desc_disabled',
		sSortableNone: 'sorting_disabled',
		sSortColumn: 'sorting_',
		sFilterInput: '',
		sLengthSelect: '',
		sScrollWrapper: 'dataTables_scroll',
		sScrollHead: 'dataTables_scrollHead',
		sScrollHeadInner: 'dataTables_scrollHeadInner',
		sScrollBody: 'dataTables_scrollBody',
		sScrollFoot: 'dataTables_scrollFoot',
		sScrollFootInner: 'dataTables_scrollFootInner',
		sHeaderTH: '',
		sFooterTH: '',
		sSortJUIAsc: '',
		sSortJUIDesc: '',
		sSortJUI: '',
		sSortJUIAscAllowed: '',
		sSortJUIDescAllowed: '',
		sSortJUIWrapper: '',
		sSortIcon: '',
		sJUIHeader: '',
		sJUIFooter: '',
	})
	var Kb = m.ext.pager
	h.extend(Kb, {
		simple: function () {
			return ['previous', 'next']
		},
		full: function () {
			return ['first', 'previous', 'next', 'last']
		},
		numbers: function (a, b) {
			return [ha(a, b)]
		},
		simple_numbers: function (a, b) {
			return ['previous', ha(a, b), 'next']
		},
		full_numbers: function (a, b) {
			return ['first', 'previous', ha(a, b), 'next', 'last']
		},
		first_last_numbers: function (a, b) {
			return ['first', ha(a, b), 'last']
		},
		_numbers: ha,
		numbers_length: 7,
	})
	h.extend(!0, m.ext.renderer, {
		pageButton: {
			_: function (a, b, c, d, e, f) {
				var g = a.oClasses,
					j = a.oLanguage.oPaginate,
					i = a.oLanguage.oAria.paginate || {},
					n,
					l,
					m = 0,
					o = function (b, d) {
						var k,
							s,
							u,
							r,
							v = function (b) {
								Sa(a, b.data.action, true)
							}
						k = 0
						for (s = d.length; k < s; k++) {
							r = d[k]
							if (h.isArray(r)) {
								u = h('<' + (r.DT_el || 'div') + '/>').appendTo(b)
								o(u, r)
							} else {
								n = null
								l = ''
								switch (r) {
									case 'ellipsis':
										b.append('<span class="ellipsis">&#x2026;</span>')
										break
									case 'first':
										n = j.sFirst
										l = r + (e > 0 ? '' : ' ' + g.sPageButtonDisabled)
										break
									case 'previous':
										n = j.sPrevious
										l = r + (e > 0 ? '' : ' ' + g.sPageButtonDisabled)
										break
									case 'next':
										n = j.sNext
										l = r + (e < f - 1 ? '' : ' ' + g.sPageButtonDisabled)
										break
									case 'last':
										n = j.sLast
										l = r + (e < f - 1 ? '' : ' ' + g.sPageButtonDisabled)
										break
									default:
										n = r + 1
										l = e === r ? g.sPageButtonActive : ''
								}
								if (n !== null) {
									u = h('<a>', {
										class: g.sPageButton + ' ' + l,
										'aria-controls': a.sTableId,
										'aria-label': i[r],
										'data-dt-idx': m,
										tabindex: a.iTabIndex,
										id: c === 0 && typeof r === 'string' ? a.sTableId + '_' + r : null,
									})
										.html(n)
										.appendTo(b)
									Va(u, { action: r }, v)
									m++
								}
							}
						}
					},
					s
				try {
					s = h(b).find(G.activeElement).data('dt-idx')
				} catch (u) {}
				o(h(b).empty(), d)
				s !== k &&
					h(b)
						.find('[data-dt-idx=' + s + ']')
						.focus()
			},
		},
	})
	h.extend(m.ext.type.detect, [
		function (a, b) {
			var c = b.oLanguage.sDecimal
			return Ya(a, c) ? 'num' + c : null
		},
		function (a) {
			if (a && !(a instanceof Date) && !Zb.test(a)) return null
			var b = Date.parse(a)
			return (null !== b && !isNaN(b)) || L(a) ? 'date' : null
		},
		function (a, b) {
			var c = b.oLanguage.sDecimal
			return Ya(a, c, !0) ? 'num-fmt' + c : null
		},
		function (a, b) {
			var c = b.oLanguage.sDecimal
			return Pb(a, c) ? 'html-num' + c : null
		},
		function (a, b) {
			var c = b.oLanguage.sDecimal
			return Pb(a, c, !0) ? 'html-num-fmt' + c : null
		},
		function (a) {
			return L(a) || ('string' === typeof a && -1 !== a.indexOf('<')) ? 'html' : null
		},
	])
	h.extend(m.ext.type.search, {
		html: function (a) {
			return L(a) ? a : 'string' === typeof a ? a.replace(Mb, ' ').replace(Aa, '') : ''
		},
		string: function (a) {
			return L(a) ? a : 'string' === typeof a ? a.replace(Mb, ' ') : a
		},
	})
	var za = function (a, b, c, d) {
		if (0 !== a && (!a || '-' === a)) return -Infinity
		b && (a = Ob(a, b))
		a.replace && (c && (a = a.replace(c, '')), d && (a = a.replace(d, '')))
		return 1 * a
	}
	h.extend(x.type.order, {
		'date-pre': function (a) {
			return Date.parse(a) || -Infinity
		},
		'html-pre': function (a) {
			return L(a) ? '' : a.replace ? a.replace(/<.*?>/g, '').toLowerCase() : a + ''
		},
		'string-pre': function (a) {
			return L(a) ? '' : 'string' === typeof a ? a.toLowerCase() : !a.toString ? '' : a.toString()
		},
		'string-asc': function (a, b) {
			return a < b ? -1 : a > b ? 1 : 0
		},
		'string-desc': function (a, b) {
			return a < b ? 1 : a > b ? -1 : 0
		},
	})
	cb('')
	h.extend(!0, m.ext.renderer, {
		header: {
			_: function (a, b, c, d) {
				h(a.nTable).on('order.dt.DT', function (e, f, g, h) {
					if (a === f) {
						e = c.idx
						b.removeClass(c.sSortingClass + ' ' + d.sSortAsc + ' ' + d.sSortDesc).addClass(
							h[e] == 'asc' ? d.sSortAsc : h[e] == 'desc' ? d.sSortDesc : c.sSortingClass
						)
					}
				})
			},
			jqueryui: function (a, b, c, d) {
				h('<div/>')
					.addClass(d.sSortJUIWrapper)
					.append(b.contents())
					.append(h('<span/>').addClass(d.sSortIcon + ' ' + c.sSortingClassJUI))
					.appendTo(b)
				h(a.nTable).on('order.dt.DT', function (e, f, g, h) {
					if (a === f) {
						e = c.idx
						b.removeClass(d.sSortAsc + ' ' + d.sSortDesc).addClass(
							h[e] == 'asc' ? d.sSortAsc : h[e] == 'desc' ? d.sSortDesc : c.sSortingClass
						)
						b.find('span.' + d.sSortIcon)
							.removeClass(
								d.sSortJUIAsc +
									' ' +
									d.sSortJUIDesc +
									' ' +
									d.sSortJUI +
									' ' +
									d.sSortJUIAscAllowed +
									' ' +
									d.sSortJUIDescAllowed
							)
							.addClass(h[e] == 'asc' ? d.sSortJUIAsc : h[e] == 'desc' ? d.sSortJUIDesc : c.sSortingClassJUI)
					}
				})
			},
		},
	})
	var Vb = function (a) {
		return 'string' === typeof a ? a.replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;') : a
	}
	m.render = {
		number: function (a, b, c, d, e) {
			return {
				display: function (f) {
					if ('number' !== typeof f && 'string' !== typeof f) return f
					var g = 0 > f ? '-' : '',
						h = parseFloat(f)
					if (isNaN(h)) return Vb(f)
					h = h.toFixed(c)
					f = Math.abs(h)
					h = parseInt(f, 10)
					f = c ? b + (f - h).toFixed(c).substring(2) : ''
					return g + (d || '') + h.toString().replace(/\B(?=(\d{3})+(?!\d))/g, a) + f + (e || '')
				},
			}
		},
		text: function () {
			return { display: Vb }
		},
	}
	h.extend(m.ext.internal, {
		_fnExternApiFunc: Lb,
		_fnBuildAjax: sa,
		_fnAjaxUpdate: kb,
		_fnAjaxParameters: tb,
		_fnAjaxUpdateDraw: ub,
		_fnAjaxDataSrc: ta,
		_fnAddColumn: Da,
		_fnColumnOptions: ja,
		_fnAdjustColumnSizing: Y,
		_fnVisibleToColumnIndex: Z,
		_fnColumnIndexToVisible: $,
		_fnVisbleColumns: aa,
		_fnGetColumns: la,
		_fnColumnTypes: Fa,
		_fnApplyColumnDefs: hb,
		_fnHungarianMap: X,
		_fnCamelToHungarian: I,
		_fnLanguageCompat: Ca,
		_fnBrowserDetect: fb,
		_fnAddData: M,
		_fnAddTr: ma,
		_fnNodeToDataIndex: function (a, b) {
			return b._DT_RowIndex !== k ? b._DT_RowIndex : null
		},
		_fnNodeToColumnIndex: function (a, b, c) {
			return h.inArray(c, a.aoData[b].anCells)
		},
		_fnGetCellData: B,
		_fnSetCellData: ib,
		_fnSplitObjNotation: Ia,
		_fnGetObjectDataFn: Q,
		_fnSetObjectDataFn: R,
		_fnGetDataMaster: Ja,
		_fnClearTable: na,
		_fnDeleteIndex: oa,
		_fnInvalidate: ca,
		_fnGetRowElements: Ha,
		_fnCreateTr: Ga,
		_fnBuildHead: jb,
		_fnDrawHead: ea,
		_fnDraw: N,
		_fnReDraw: S,
		_fnAddOptionsHtml: mb,
		_fnDetectHeader: da,
		_fnGetUniqueThs: ra,
		_fnFeatureHtmlFilter: ob,
		_fnFilterComplete: fa,
		_fnFilterCustom: xb,
		_fnFilterColumn: wb,
		_fnFilter: vb,
		_fnFilterCreateSearch: Oa,
		_fnEscapeRegex: Pa,
		_fnFilterData: yb,
		_fnFeatureHtmlInfo: rb,
		_fnUpdateInfo: Bb,
		_fnInfoMacros: Cb,
		_fnInitialise: ga,
		_fnInitComplete: ua,
		_fnLengthChange: Qa,
		_fnFeatureHtmlLength: nb,
		_fnFeatureHtmlPaginate: sb,
		_fnPageChange: Sa,
		_fnFeatureHtmlProcessing: pb,
		_fnProcessingDisplay: C,
		_fnFeatureHtmlTable: qb,
		_fnScrollDraw: ka,
		_fnApplyToChildren: H,
		_fnCalculateColumnWidths: Ea,
		_fnThrottle: Na,
		_fnConvertToWidth: Db,
		_fnGetWidestNode: Eb,
		_fnGetMaxLenString: Fb,
		_fnStringToCss: v,
		_fnSortFlatten: V,
		_fnSort: lb,
		_fnSortAria: Hb,
		_fnSortListener: Ua,
		_fnSortAttachListener: La,
		_fnSortingClasses: wa,
		_fnSortData: Gb,
		_fnSaveState: xa,
		_fnLoadState: Ib,
		_fnSettingsFromNode: ya,
		_fnLog: J,
		_fnMap: F,
		_fnBindAction: Va,
		_fnCallbackReg: z,
		_fnCallbackFire: r,
		_fnLengthOverflow: Ra,
		_fnRenderer: Ma,
		_fnDataSource: y,
		_fnRowAttributes: Ka,
		_fnCalculateEnd: function () {},
	})
	h.fn.dataTable = m
	m.$ = h
	h.fn.dataTableSettings = m.settings
	h.fn.dataTableExt = m.ext
	h.fn.DataTable = function (a) {
		return h(this).dataTable(a).api()
	}
	h.each(m, function (a, b) {
		h.fn.DataTable[a] = b
	})
	return h.fn.dataTable
})
