<template>
	<p
		:class="[
			'!m-0 !p-0 flex items-center gap-x-1',
			isSlider && 'justify-center',
			isSlider && isFullCover && '!text-white',
		]"
	>
		<template v-if="range">
			<market-currency
				class="currency text-[13px]/[16px] font-medium"
				:amount="range.min"
				display-free-if-zero
				display-without-zeros
			/>
			-
			<market-currency
				class="currency text-[13px]/[16px] font-medium"
				:amount="range.max"
				display-free-if-zero
				display-without-zeros
			/>
		</template>
		<template v-else-if="!isLoyalty">
			<market-currency
				class="currency text-[13px]/[16px] font-medium"
				:amount="price"
				display-free-if-zero
				display-without-zeros
			/>
		</template>
		<market-currency
			v-if="original && !range"
			class="currency text-line-through text-neutral-500 text-[11px]/[16px] font-normal"
			:amount="original"
			display-without-zeros
		/>
		<span v-if="savings && !isLoyalty && !range" class="currency text-[11px]/[16px] text-orange-800 font-normal">
			<template v-if="savings.isPaletteCredit">
				<market-currency class="currency" :amount="savings.amount" display-without-zeros /> {{ savings.discountLabel }}
			</template>
			<template v-else-if="savings.isUserSelected">
				{{ savings.discountLabel }}
			</template>
			<template v-else-if="savings.isPercent"> {{ savings.amount }}% Off </template>
			<template v-else>
				Save <market-currency class="currency" :amount="savings.amount" display-without-zeros />
			</template>
		</span>
		<span v-if="isLoyalty" class="currency text-[11px]/[16px] !text-orange-800 font-normal">Reward</span>
	</p>
</template>
<script setup>
import { computed } from 'vue'

const props = defineProps({
	item: {
		type: Object,
		required: true,
	},
	minPrice: {
		type: Number,
		default: null,
	},
	maxPrice: {
		type: Number,
		default: null,
	},
	saleInformation: {
		type: Array,
	},
	isCartItem: {
		type: Boolean,
		default: false,
	},
	isSlider: {
		type: Boolean,
		default: false,
	},
	isFullCover: {
		type: Boolean,
		default: false,
	},
	isLoyalty: {
		type: Boolean,
		default: false,
	},
})

const sale = computed(() => {
	const saleArr = props.saleInformation ?? []
	return saleArr.find((s) => s.qualifying_skus.includes(props.item.itemCode) && s.can_purchase)
})
const range = computed(() => {
	if (props.minPrice && props.maxPrice && props.minPrice !== props.maxPrice) {
		return { min: props.minPrice, max: props.maxPrice }
	}
	return null
})
const price = computed(() => {
	const { priceRaw } = props.item

	if (sale.value) {
		const { credit, type } = sale.value

		let discountAmount
		if (type === 'percent') {
			discountAmount = (priceRaw * credit) / 100
		} else {
			discountAmount = credit
		}

		return Math.max(0, priceRaw - discountAmount)
	}

	if (props.isCartItem && props.item.discounted) {
		const { discountType, discountValue } = props.item

		let amount
		if (discountType === 'percent') {
			amount = (priceRaw * (100 - discountValue)) / 100
		} else {
			amount = priceRaw - discountValue
		}

		return Math.max(0, amount)
	}

	return priceRaw
})
const original = computed(() => {
	const { isBundle, bundleValue, priceRaw } = props.item

	if (props.item?.isAutoAdd) return null

	if ((props.isCartItem && props.item.discounted) || sale.value) {
		// safegaurd 0 discount case
		if (parseInt(priceRaw) === price) {
			return null
		}
		return priceRaw
	}
	if (isBundle && parseFloat(bundleValue) > 0) {
		return bundleValue
	}
	return null
})

const savings = computed(() => {
	const { isBundle, bundleValue, priceRaw } = props.item

	const isPromotional = !!(props.item?.isPromotionalItem || sale.value?.is_promotion)
	const isPaletteCredit = !!(props.item?.discountId === 'palette_credit' && props.isCartItem)
	const discountLabel = isPaletteCredit ? props.item?.discountTitle : props.item?.discountText

	if (sale.value) {
		const { credit, type } = sale.value

		let discountAmount
		if (type === 'percent') {
			discountAmount = (priceRaw * credit) / 100
		} else {
			discountAmount = credit
		}

		// safegaurd 0 discount case
		if (discountAmount === 0) return null

		return {
			isPercent: isPromotional && type === 'percent',
			isAmount: !isPromotional || type !== 'percent',
			isBundle: false,
			amount: !isPromotional ? discountAmount : Math.floor(credit),
			isPaletteCredit,
			discountLabel,
		}
	}

	if (props.isCartItem && props.item.discounted) {
		const { discountType, discountValue, isUserSelected } = props.item

		let discountAmount
		if (discountType === 'percent') {
			discountAmount = (priceRaw * discountValue) / 100
		} else {
			discountAmount = discountValue
		}

		return {
			isPercent: isPromotional && discountType === 'percent',
			isAmount: !isPromotional || discountType !== 'percent',
			isBundle: false,
			amount: !isPromotional ? discountAmount : Math.floor(discountValue),
			isPaletteCredit,
			isUserSelected,
			discountLabel,
		}
	}

	if (isBundle && parseFloat(bundleValue) > 0) {
		return { isBundle, amount: parseFloat(bundleValue) - priceRaw }
	}
	return null
})
</script>
