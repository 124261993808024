import { defineStore } from 'pinia'

import orderClient from '@/js/api/orders.js'
import { useSessionStore } from './session'

const INITIAL_STATE = {
	orderTax: 0,
	shippingTotal: 0,
	subtotal: 0,
	subtotalBeforeOrderDiscounts: 0,
	taxTotal: 0,
	total: 0,
	weightTotal: 0,
	hasCalculation: false,
}

export const useOrderStore = defineStore('order', {
	state: () => ({
		...INITIAL_STATE,
	}),

	getters: {},

	actions: {
		async calculate(data) {
			const { discountCode, shipMethodId, shippingAddressId, donationAmount } = data
			const session = useSessionStore()
			const payload = {
				discountCode,
				shipMethodId,
				shippingAddressId,
				donationAmount,
				country: session.country_code,
				warehouse: session.billing_warehouse_id,
				currencyCode: session.currency_code,
				priceType: session.price_type_id,
			}
			try {
				const calculation = await orderClient.calculate(payload)
				const { data } = calculation
				const {
					orderTax,
					shippingTotal,
					subtotal,
					subtotalBeforeOrderDiscounts,
					taxTotal,
					total,
					weightTotal,
					shippingOptions,
				} = data
				this.orderTax = orderTax
				this.shippingTotal = shippingTotal
				this.subtotal = subtotal
				this.subtotalBeforeOrderDiscounts = subtotalBeforeOrderDiscounts
				this.taxTotal = taxTotal
				this.total = total
				this.weightTotal = weightTotal
				this.hasCalculation = true
				return { shippingOptions }
			} catch (error) {
				throw error
			}
		},
		clearCalculation() {
			this.calculation = INITIAL_STATE
			this.hasCalculation = false
		},
	},
})
