import numeral from 'numeral'

export default class CurrencyFormatter {
	/**
	 *
	 * @param price
	 * @param currencyCode
	 */
	constructor(price, currencyCode) {
		this.price = price
		this.code = currencyCode
	}

	toString() {
		return this.getFormat()
	}

	getFormat() {
		let number
		switch (this.code.toLowerCase()) {
			case 'usd':
				number = numeral(this.price).format('$0,0.00')
				break
			case 'mxp':
				number = `${numeral(this.price).format('$0,0.00')} MXN`
				break
			case 'cad':
				number = `C$${numeral(this.price).format('0,0.00')}`
				break
			default:
				number = numeral(this.price).format('0,0.00')
				break
		}

		return number
	}
}
